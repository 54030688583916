import { Record, List, fromJS } from 'immutable';

export class User extends Record({
  id: null,
  accountId: null,
  name: '',
  email: '',
  accountFeatures: [],
  isAdmin: false,
  adminUserId: null,
  siteAccess: null,
  allSiteAccess: false,
  featureAccess: null,
  allFeatureAccess: false,
  accountAccess: null,
  accountName: '',
  masqed: false,
  inviteSent: null,
  allDiagnoseSiteAccess : false,
  diagnoseAccess: null,
  isSpAccountManager: false,
  isAccountOwner: false,
  isTransactionReceipt: false,
}) {
  constructor(user) {
    const updatedUser = Object.assign({}, user);
    if (user.userId) {
      updatedUser.id = user.userId;
    }
    if (!user.allSiteAccess && user.siteIds) {
      updatedUser.siteAccess = user.siteIds;
    }
    if (!user.allFeatureAccess && user.entityAccessPermissions) {
      updatedUser.featureAccess = user.entityAccessPermissions.map(p => p.name);
    }

    if (user.entityAccessPermissions.map(p => p.name)?.includes('Transaction Receipts')) {
      updatedUser.isTransactionReceipt = true
    }

    if (!user.allAccountAccess && user.accountIds) {
      updatedUser.accountAccess = user.accountIds;
    }
    if (user.allSiteAccess) updatedUser.allSiteAccess = true;
    if (user.account_id) {
      updatedUser.accountId = user.account_id;
    }
    if (user.account_name) {
      updatedUser.accountName = user.account_name;
    }

    if (user.features) {
      updatedUser.accountFeatures = user.features;
    }
    if (!user.allDiagnoseSiteAccess && user.diagnoseSiteIds) {
      updatedUser.diagnoseAccess = user.diagnoseSiteIds;
    }
    if (user.allDiagnoseSiteAccess) updatedUser.allDiagnoseSiteAccess = true;
    if (typeof user.features === 'string') {
      updatedUser.features = new List(user.features.slice(1, - 1).split(','));
    }
    updatedUser.allFeatureAccess = user.allFeatureAccess;
    super(fromJS(updatedUser));
  }
}

export class Account extends Record({
  accountId: null,
  accountFeatures: [],
  accountName: '',
  zendeskOrganization: ''
}) {
  constructor(account) {
    const updatedAccount = Object.assign({}, account);
    if (account.id) {
      updatedAccount.accountId = account.id;
    }
    if (account.name) {
      updatedAccount.accountName = account.name;
    }

    if (account.features) {
      updatedAccount.accountFeatures = account.features;
    }
    if (account.zendesk_organization) {
      updatedAccount.zendeskOrganization = account.zendesk_organization;
    }
    super(fromJS(updatedAccount));
  }
}

export class Domain extends Record({
  name: '',
  id: '',
}) {
  constructor(domain) {
    const updatedDomain = Object.assign({}, domain);
    updatedDomain.name = domain.domain;
    super(updatedDomain);
  }
}

export const UserCreadentials = new Record({
  password: '',
  apiKey: '',
  email: '',
});

export const ApiKey = new Record({
  id: '',
  name: '',
  key: '',
});
