import { Modal, Table } from 'antd';
import React from 'react';

function VendorDomainModal({ modalOpenType, data, onClose }) {
  const columns = [
    {
      title: 'Domain',
      dataIndex: 'vendor_domain',
      key: 'vendor_domain',
      width: 200,
    },
    {
      title: 'Prevalence',
      dataIndex: 'prevalence',
      key: 'prevalence',
      width: 200,
      render: (value) => {
        if (value === null) return '0.00%';

        return Number.isInteger(value) ? `${value}%` : `${value.toFixed(2)}%`;
      },
    },
  ];
  return (
    <Modal
      title="Vendor Domain"
      open={modalOpenType}
      destroyOnClose={true}
      onCancel={onClose}
      okButtonProps={{ style: { display: 'none' } }}
      width={800}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '200'],
        }}
      />
    </Modal>
  );
}
export default VendorDomainModal;
