import { Space } from "antd";
import React from "react";

export const preferences_steps = ["name", "properties", "manage_preferences"];

export const evaluateRequriedFieldsFromStep = (
  current,
  intermediateUSPRegulation,
  regulationListNames
) => {
  let isStepcomplete = true;
  switch (current) {
    case "name":
      if (
        intermediateUSPRegulation.name.trim().length == 0 ||
        regulationListNames.includes(intermediateUSPRegulation.name.trim())
      ) {
        isStepcomplete = false; 
      }
      break;
    case "properties":
      if (
        intermediateUSPRegulation.propertyIds.length == 0
      ) {
        isStepcomplete = false;
      }
      break;
    case "name_properties":
      if (
        intermediateUSPRegulation.name.trim().length == 0 ||
        regulationListNames.includes(intermediateUSPRegulation.name.trim()) ||
        intermediateUSPRegulation.propertyIds.length == 0
      ) {
        isStepcomplete = false;
      }
      break;
    case "manage_preferences":
    case "manage_transactions":
      if (intermediateUSPRegulation.categories.filter(cat => !Boolean(cat.dateDeleted)).length == 0 ) {
        isStepcomplete = false;
      }
      break;
    case "language":
      // const categories = intermediateUSPRegulation.categories.filter(cat => cat.type === "CUSTOM");
      // let emptyTranslations = categories.length > 0 ? categories.filter(cat => {
      //   let translations = cat.isNegative ?  cat?.negativeTranslations : cat?.translations;
      //   return translations === null || translations?.names["en"] === "No Translations found" || translations?.names["en"] === ""
      // }): [];
      // if (emptyTranslations.length > 0) {
      //   isStepcomplete = false;
      // }
      break;
    case "category_mapping":
      if(!intermediateUSPRegulation.categories.some((cat) => !cat.dateDeleted && cat.vendorConnection.length)){
        isStepcomplete = false;
      }
      break;
    case "summary":
      break;
  }
  return isStepcomplete;
};

export const channelOptions = [
  { label: 'Email', value: 0 },
  { label: 'SMS', value: 1 },
  { label: 'Whatsapp', value: 2 },
  { label: 'Phone', value: 3 }
]

export const valideAllStepsBeforeSave = (steps, intermediateUSPRegulation, regulationsList) => {
  let stepsWithError = [];
  steps.forEach(step => {
    if(!evaluateRequriedFieldsFromStep(step, intermediateUSPRegulation, regulationsList)){
      stepsWithError.push(step)
    }
  })
  return stepsWithError;
}

export const nameFieldValidator = (value, namesList, entityName, duplicateValidationMessage) => {
  const isSpecialCharsOnly = value && /^[^a-zA-Z0-9]+$/.test(value);
  if (isSpecialCharsOnly) {
    return Promise.reject(
      new Error(`${entityName} cannot contain only special characters`)
    );
  }

  if (value?.trim() && namesList?.find(docName => docName?.trim() === value?.trim())) {
    return Promise.reject(
      new Error(duplicateValidationMessage ??`${entityName} already exists`) 
    );
  }
  return Promise.resolve();
}

export const categorySubTypesOptions = [
  { value: 'AI-POLICY', label: 'AI Policy' },
  { value: 'TERMS-AND-CONDITIONS', label: 'Terms and Conditions' },
  { value: 'PRIVACY-POLICY', label: 'Privacy Policy' },
  { value: 'LEGAL-POLICY', label: 'Legal Policy' },
  { value: 'TERMS-OF-SALE', label: 'Terms of Sale' }
];

export const defaultUserStateOptions = [
  // { value: 'DISCLOSURE', label: 'Disclosure Only' },
  { value: 'OPT-IN', label: 'Opted in' },
  { value: 'OPT-OUT', label: 'Opted out' }
]

export const catTypeToLabelMap = {
  'MARKETING' : 'Marketing Preference',
  'LEGAL-TRANSACTION' : 'Legal Preference'
}

export const defaultStateTooltip = (
  <Space direction="vertical">
    <div>The default user state shows the initial status of the message</div>
    <ul>
    <li><b>Opted In</b>: If the message has a toggle, the toggle will be “on” by default, meaning the user is opted in. To opt out, they would need to turn the toggle “off”</li>
    <li><b>Opted Out</b>: If the message has a toggle, the toggle will be “off” by default, meaning the user is opted out. To opt in, they would need to turn the toggle “on”</li>
    </ul>
  </Space>
)