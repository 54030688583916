import moment from 'moment';

// eslint-disable-next-line camelcase
export function isFiltersProvided({ properties, region, dateFrom, dateTo }) {
  return !!properties && !!region && !!dateFrom && !!dateTo;
}

export function mapSelectedFilters(selectedFilters) {
  return {
    dateFrom: moment(selectedFilters?.start)?.format('YYYY-MM-DD'),
    dateTo: moment(selectedFilters?.end)?.format('YYYY-MM-DD'),
    benchmarks: selectedFilters?.benchmark_websites?.join(';'),
    properties: selectedFilters?.websites?.join(';'),
    region: selectedFilters?.regions?.join(';'),
  };
}

export function mapSelectedFiltersApp(selectedFilters) {
  return {
    dateFrom: moment(selectedFilters?.start)?.format('YYYY-MM-DD'),
    dateTo: moment(selectedFilters?.end)?.format('YYYY-MM-DD'),
    properties: selectedFilters?.websites?.join(';'),
    region: selectedFilters?.regions?.join(';'),          
  };
}

export function getUniqueVendorIdsList(detailMetricData) {
  return [...new Set(detailMetricData.map((item) => item.vendor_id))]?.filter(v => isValidUUid(v));
}

export function getUniqueVendorIdsListApp(detailMetricData) {
  return [...new Set(detailMetricData.map((item) => item.diagnose_vendor_id))]?.filter(v => isValidUUid(v));
}

export async function requestDataDependOnTableFilters(
  { filters, isEnabledVendorStatusManagementFeature },
  detailDataRequest,
  vendorManagementRequest,
) {
  function prepareVendorIds(list) {
    return [...new Set(list.map((item) => item.vendor))];
  }

  if (!isEnabledVendorStatusManagementFeature) {
    detailDataRequest(filters);
  } else if (filters?.vendor_status?.length) {
    const tag = filters?.vendor_status?.filter((filter) => ['Change Detected', 'New'].includes(filter));
    const status = filters?.vendor_status?.filter((filter) => !['Change Detected', 'New'].includes(filter));
    const vendorManagementData = await vendorManagementRequest({ status, tag, vendors: [] });
    if (vendorManagementData.length) {
      const vendorIds = prepareVendorIds(vendorManagementData);
      detailDataRequest(filters, vendorIds);
    }
  } else {
    const detailsData = await detailDataRequest(filters);
    if (detailsData?.results?.length) {
      vendorManagementRequest({ ...filters, vendors: getUniqueVendorIdsList(detailsData?.results) });
    }
  }
}



export const isValidUUid = (uuid) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}
