import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllVendorConnections } from "../../../../actions/preferences_actions";
import { Button, Layout, Menu, Table,  Tag, Select, Typography } from "antd";
import { List, Map } from "immutable";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Loading from "../../../common/Loading.js";
import { ConfirmationWrapper, renderInfoTip } from "../../helper.jsx";
import classNames from "classnames";
import { RenderLimitedChips } from "../../../../styleguide/index.js";
import { withConfigProvider } from "../../../../hooks.js";
import { Category } from "../../../../records/preferences_records.js";

const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;

const IntegrationCategoryMapping = (props) => {
  const categoriesWithoutDateDeleted = props.configuration?.categories?.filter((cat) => !cat.get("dateDeleted") && cat.type === props.catType);

  const [selectedCategory, setSelectedCategory] = useState(categoriesWithoutDateDeleted.get(0) ?? new Category({}))
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    props.getAllVendorConnections();
  }, []);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
    setSelectedCategory(categoriesWithoutDateDeleted.find(cat => cat.get("categoryId") === selectedCategory.get("categoryId")))
  };

  const deleteRecord = (recordId) => {
    const updatedSelectedCategory = selectedCategory.update("vendorConnection", (vendorConnection) => vendorConnection.filter(venCon => venCon.get('id') !== recordId));
    setSelectedCategory(updatedSelectedCategory);
    save(updatedSelectedCategory);
  }

  const save = (updatedCategory = selectedCategory) => {
    const updatedConfiguration = props.configuration.update("categories", (categories) => {
      return categories.map((category) => {
        if(category.get("categoryId") === updatedCategory.get("categoryId")){
          return updatedCategory;
        }
        return category;
      })
    });
    props.updateConfiguration(updatedConfiguration)
    setEditingKey('');
  };

  const handleConnectionCategorySelect = (record, value) => {
    
    const updatedSelectedCategory = selectedCategory.update("vendorConnection", (vendorConnection) =>
      vendorConnection.map(venCon => {
        if (venCon.get('id') === record.id) {
          // Create a new copy of venCon with updated vendorConnectionCategories
          return venCon.set(
            'vendorConnectionCategories',
            List(props.vendorConnectionsIndexMap.get(record.id)?.categories?.filter(cat => value.includes(cat.id)))
          );
        }
        return venCon;
      })
    );
    
    setSelectedCategory(updatedSelectedCategory);
  }

  const addConnection = () => {
    if(avialableVenConList.size){
      const updatedSelectedCategory = selectedCategory.update("vendorConnection", (vendorConnection) => {
        const newConnection = new Map({id: avialableVenConList.get(0)?.connectionId, name: avialableVenConList.get(0)?.name, vendorConnectionCategories: avialableVenConList.get(0)?.categories})
        return vendorConnection.unshift(newConnection);
      })
      setSelectedCategory(updatedSelectedCategory);
      save();
      setEditingKey(avialableVenConList.get(0)?.connectionId);
    } else {
      setEditingKey('');
    }
  }

  const onConnectionChange = (value) =>{
    let updatedSelectedCategory = selectedCategory.update("vendorConnection", (vendorConnection) => {
      const indexOfCurrentConnection = vendorConnection.findIndex((venCon) => venCon.get('id') == editingKey);
      const newConnection = Map({id: value, name: props.vendorConnectionsIndexMap?.get(value)?.name, vendorConnectionCategories: props.vendorConnectionsIndexMap?.get(value)?.categories})
      return vendorConnection.set( indexOfCurrentConnection, newConnection)
    })
    setSelectedCategory(updatedSelectedCategory);
    setEditingKey(value);
  };

  
  const onCategorySelect = ( obj ) => {
    const newSelection = categoriesWithoutDateDeleted.find(cat => cat.get("categoryId") == obj.key);
    if(newSelection) {
      setSelectedCategory(newSelection)
      setEditingKey('');
    }
  }


  const selectedVenConnIds = selectedCategory.get('vendorConnection')?.map((venCon) => venCon.get('id'))?.toJS();
  const avialableVenConList = props.vendorConnections?.filter((venCon) => !selectedVenConnIds?.includes(venCon.connectionId))

  const connectionOptions = props.vendorConnections.map((venConn) => ({label: venConn.name, value: venConn.connectionId}))?.sort((a,b) => a.label.localeCompare(b.label))?.toJS()

  const dataSource = selectedCategory?.get('vendorConnection')?.toJS() ?? [];

  const isMinConnCatError = dataSource?.some((record) => {
    const connectionCategoryOptions = props.vendorConnectionsIndexMap?.get(record.id)?.categories ?? List([]);
    return connectionCategoryOptions.size == 0 ? false : record.vendorConnectionCategories?.length === 0
  })

  const columns = [
    {
      title: "Integration",
      dataIndex: ["id"],
      width: '40%',
      render: (id, record) => {
        const editable = isEditing(record);
        const optons = connectionOptions.filter(option => option.value == id || !selectedVenConnIds?.includes(option.value));
        const venderConnectionRecord  = props.vendorConnectionsIndexMap.get(record.id);
        let errorIcon = null;
        if(!venderConnectionRecord){
          errorIcon = renderInfoTip("The Integration has been deleted from this account", "error-icon");
        }
        return editable ? (
          <Select
            value={id}
            onChange={onConnectionChange}
            options={optons}
            style={{width: "100%"}}
            disabled={errorIcon}
          />
        ) : (
          <div>
            {/* <Avatar src={<img src={props.vendorsHash?.get(props.vendorConnectionsIndexMap.get(id)?.vendorId)?.iconUrl} alt="vendor icon" />} /> */}
            {record?.name} {errorIcon}
          </div>
        );
      },
    },
    {
      title: "Integration category",
      dataIndex: "vendorConnectionCategories",
      width: '40%',
      render:  (vendorConnectionCategories, record) => {
        const editable = isEditing(record);
        const connectionCategoryOptions = props.vendorConnectionsIndexMap.get(record.id)?.categories ?? List([]);
        return editable ? (
          <>
          <Select
            optionFilterProp="label"
            mode="multiple"
            onChange={(value) => handleConnectionCategorySelect(record, value)}
            options={connectionCategoryOptions.map(cat => ({value: cat.id, label: cat.name}))?.toJS()}
            value={vendorConnectionCategories.map(cat => cat.id)}
            maxTagCount={'responsive'}
            style={{
              width: '100%',
            }}
            disabled={connectionCategoryOptions.size == 0}
            placeholder={connectionCategoryOptions.size == 0 ? "No Categories available" : "Select Category"}
            status={connectionCategoryOptions.size == 0 ? null : vendorConnectionCategories.length == 0 ? 'error' : null}
          />
          {connectionCategoryOptions.size == 0 ? null : vendorConnectionCategories.length == 0 ? <Text type="danger">{"Please select at least one category"}</Text> : null}
          </>
        ) : (
          <RenderLimitedChips chips={vendorConnectionCategories.map(cat => <Tag>{cat.id} {" "} {cat.name}</Tag>)} cutOff={2}/>
        )
      },
    },
    {
      title: <Button disabled={avialableVenConList?.size === 0 || props.readOnly || !selectedCategory?.categoryId || isMinConnCatError} type="primary" onClick={addConnection}>+ Add Integration</Button>,
      dataIndex: "action",
      width: "20%",
      align: "center",
      render: (_, record, index ) => {
        if(props.readOnly){
          return null;
        }
        const editable = isEditing(record);
        const connectionCategoryOptions = props.vendorConnectionsIndexMap.get(record.id)?.categories  ?? List([]);
        return editable ? (
          <>
            <Button icon={<CheckOutlined/>} type="link" onClick={() => save()} disabled={connectionCategoryOptions.size == 0 ? false : record.vendorConnectionCategories.length == 0 }/>
            <Button icon={<CloseOutlined/>} type="link" onClick={cancel} />
          </>
        ) : (
          <>
            <Button icon={<EditOutlined/>} type="link" disabled={editingKey !== '' || !props.vendorConnectionsIndexMap.get(record.id)} onClick={() => edit(record)} />
            <ConfirmationWrapper
              actionDescription="delete this Integration"
              deleteHandler={() => deleteRecord(record.id)}
              deleteBtn={<Button icon={<DeleteOutlined/>} type="link" disabled={editingKey !== ''}/>}
            />
          </>
        );
      },
    },
  ];


  let categoriesList = categoriesWithoutDateDeleted?.toJS()?.map((cat) => {
    const noOfVendorConnections = cat.vendorConnection.length ? (
      <div>({cat.vendorConnection.length})</div>
    ) : null;
    return {
      key: cat.categoryId,
      label: cat.name,
      icon: noOfVendorConnections,
    };
  });
  categoriesList = categoriesList.reduce((acc, curr, index) => {
    if (index === categoriesList.length - 1) {
      return [...acc, curr];
    }
    return [...acc, curr, { type: "divider" }];
  }, []);

  const CustomTable = withConfigProvider(Table);

  return !props.pendingRequestsMap.get('vendorConnections') ? (
    <>
       {props.readOnly || props.onEditScreen ? null :(
        <div className="step-header">
          <Typography.Title level={4}>Integration Category Mapping</Typography.Title>
        </div>
      )}
      <div className="step-breif">
        Ensure that categories are mapped correctly to your integrations by selecting the integration and category, or field, name that matches
      </div>
      {props.showError ? <Text type="danger" strong>{"Please add at least one integration category"}</Text> : null}
      <div className={classNames(`step-section`, {"validation-error": props.showError})}>
        <Layout className={'integration-category-mapping-layout'}>
          <Sider theme="light">
            <Layout>
              <Header theme="light"><Text strong>Marketing Preferences</Text></Header>
              <Content>
                <Menu
                  onClick={onCategorySelect}
                  selectedKeys={[selectedCategory.get('categoryId')]}
                  mode="vertical"
                  items={categoriesList}
                />
              </Content>
            </Layout>
          </Sider>
          <Content>
            <CustomTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              bordered={false}
              locale={{ emptyText: "No Integrations" }}
              scroll={{y: props.onEditScreen ? 'calc(60vh - 67px)' : 'calc(70vh - 67px)'}}
              rowClassName={() => "no-bottom-border"}
              componentTokens={{
                headerBorderRadius: 0
              }}
            />
          </Content>
        </Layout>
      </div>
    </>
  ) : <Loading/>;
}
const mapStateToProps = function (store) {
  return {
    vendorConnections: store.preferencesState.getIn(["vendorConnections","value"]),
    vendorConnectionsIndexMap: store.preferencesState.getIn(["vendorConnectionsIndexMap", "value"]),
    vendorsHash: store.preferencesState.getIn(["vendorsHash", "value"]),
    pendingRequestsMap: Map({
      vendorConnections: store.preferencesState.getIn(["vendorConnections","pending"]),
    }),
  };
};

export default connect(mapStateToProps, {
  getAllVendorConnections,
})(IntegrationCategoryMapping);
