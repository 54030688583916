import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faChartArea,
  faTimes,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { MenuTitle, MenuItem, MenuDropdown, Divider } from './Components/NavigationComponents';
import SVG from 'react-inlinesvg';

import privacyLensIcon from '../../assets/icons/privacy_lens.svg';
import dialogueIcon from '../../assets/icons/dialogue.svg';
import reportsIcon from '../../assets/icons/reporting.svg';
import vendorsIcon from '../../assets/icons/vendors.svg';
import propertiesIcon from '../../assets/icons/properties.svg';
import campaignsIcon from '../../assets/icons/campaigns.svg';
import standardsIcon from '../../assets/icons/standards.svg';
import propertySetsIcon from '../../assets/icons/property_sets.svg';
import adminIcon from '../../assets/icons/admin.svg';
import messagesIcon from '../../assets/icons/messages.svg';
import BellIcon from '../../assets/icons/bell_icon.svg';
import UserDropdownMenu from './Components/UserDropdownMenu'
import { accountHasPrivacyLens } from '../../util';
import { BuildOutlined, ContainerOutlined } from '@ant-design/icons';


export default class Navigation extends React.Component {
  state = {
    site_id: null,
    site_group_id: null,
  }
  isActive(link) {
    if (link === '/') {
      if (window.location.pathname === '/') {
        return true;
      }
      return false;
    }
    if (link === 'consent' && window.location.pathname === '/consent_performance') {
      return false;
    } else if (link === 'consent' && window.location.pathname.includes('/consent_v2')) {
      return false;
    } else if (window.location.pathname.includes('diagnose_dashboard') && link !== '/diagnose_dashboard') {
      const result = ((window.location.pathname.split('diagnose_dashboard'))[1]).localeCompare(link);
      return result === 0;
    } else if (link === '/diagnose_dashboard') {
      if (window.location.pathname === '/diagnose_dashboard') {
        return true;
      }
      return false;
    }

    return (
      window.location.pathname.includes(link)
      && !window.location.pathname.includes('messages')
      && !window.location.pathname.includes('datarequest')
      && !window.location.pathname.includes('us_privacy_report')
    );
  }
  hasFeature = (feature) => {
    // if (this.props.currentUser.accountId === 22 && feature !== 'recovery') return true;
    return this.props.currentUser.accountFeatures.includes(feature);
  }

  hasFeatureAndUserAccess = (feature, userFeature) => {
    const currentUser = this.props.currentUser;
    return this.hasFeature(feature) && ((currentUser.featureAccess && currentUser.featureAccess.includes(userFeature)) || currentUser.allFeatureAccess);
  }

  render() {
    
    const { currentUser, hidden, open } = this.props;
    const siteIds = this.props.generateIds();
    const hasUSNATAccessMenu = this.hasFeature('usnat');
    const hasIntegrationHubAccess = this.hasFeature('marketing_preferences') && ((currentUser.featureAccess && currentUser.featureAccess.includes('Integration Hub')) || currentUser.allFeatureAccess);
    const hasPreferencesAccess = (this.hasFeature('legal_preferences') || this.hasFeature('marketing_preferences')) && ((currentUser.featureAccess && currentUser.featureAccess.includes('Preferences Configuration')) || currentUser.allFeatureAccess);
    const hasLegalDocAccess = this.hasFeature('legal_preferences') && ((currentUser.featureAccess && currentUser.featureAccess.includes('Legal Doc Management')) || currentUser.allFeatureAccess)
    const hasFeatureAccess = this.hasFeature('tcf_v2') || this.hasFeature('non_iab_vl') || this.hasFeature('ccpa') || this.hasFeature('adblock') || this.hasFeature('adblock_v2') || hasUSNATAccessMenu || hasPreferencesAccess;
    const hasAppleMessagingAccess = this.hasFeature('apple_messaging')
    const hasGDPRAccessMenu =  this.hasFeature('tcf_v2') || this.hasFeature('non_iab_vl') || this.hasFeature('privacy_manager_ott') || this.hasFeature('native_messaging') || this.hasFeature('native_ott')
    const hasCCPAccessMenu =  this.hasFeature('ccpa') || this.hasFeature('ccpa_ott') || this.hasFeature('native_ott_ccpa') || this.hasFeature('native_messaging')
    const hasCampaignEntitiesAccess = hasFeatureAccess && ((currentUser.featureAccess && currentUser.featureAccess.includes('Campaign Entities')) || currentUser.allFeatureAccess)
    const hasCustomMessages = this.hasFeature('custom_messages')
    const hasScheduledReportingMenu = currentUser?.allSiteAccess && currentUser?.allFeatureAccess || currentUser?.featureAccess?.includes("Reporting CCPA") || currentUser?.featureAccess?.includes("Reporting TCFV2")
    const showDialogue = hasFeatureAccess;
    const hasPropertyGroups = !this.hasFeature('hide_property_groups');
    const hasTransactionReceipt = this.hasFeature('transaction_receipt') && (currentUser?.isTransactionReceipt)

    return (
      <div
        className={`spt-menu ${open ? 'open' : ''} ${hidden ? 'closed' : ''}`}
      >
        {hidden ? (
          <div className="toggle">
            <div className="open" onClick={this.props.toggleHidden}><FontAwesomeIcon icon={faBars} /></div>
          </div>
        ) : (
          <div className="toggle">
            {open && <div className="close" onClick={this.props.toggleHidden}><FontAwesomeIcon icon={faTimes} /></div>}
            <div className="minimize" onClick={this.props.toggleOpen}><FontAwesomeIcon icon={faChevronLeft} /></div>
            {!open && <div className="maximize" onClick={this.props.toggleOpen}><FontAwesomeIcon icon={faChevronRight} /></div>}
          </div>
        )}


        <div className="spt-items">

          { /* DIALOGUE */}
          {showDialogue ? (
            <React.Fragment>
              <MenuTitle name="Dialogue" icon={<SVG src={dialogueIcon} />} />
              <MenuDropdown name="Reporting" icon={<SVG src={reportsIcon} />}>
                {hasScheduledReportingMenu ? <MenuItem name="Scheduled Reporting" to="/manage_reports" /> : null}
                {this.hasFeature("tcf_v2") && <MenuItem name="GDPR Campaign Dashboard" to="/gdprtcfv2_report" />}
                {this.hasFeature("ccpa") && <MenuItem name="U.S. Privacy Campaign Dashboard" to="/us_privacy_report" />}
                {/* { hasAppleMessagingAccess && <MenuItem name="iOS Tracking Campaigns Dashboard" to="/gdprtcfv2_report" /> } */}
                {/* { !this.hasFeature("request_report_v2") && <MenuItem name="Reports Export" to="/custom_report" /> } */}
                {this.hasFeature('adblock') && <MenuItem name="AdBlock Report" to="/adblock" />}
                {this.hasFeature('adblock') && <MenuItem name="AdBlock Dashboard" to="/adblock_dashboard" />}
              </MenuDropdown>
              {hasTransactionReceipt && (<MenuItem name="Transaction Receipts" icon={<ContainerOutlined />} to="/transaction_receipt"></MenuItem>)}
              { hasCampaignEntitiesAccess &&
                hasPropertyGroups ? (
                  <MenuDropdown name="Properties" icon={<SVG src={propertiesIcon} />}>
                    <MenuItem name="Properties" to="/dialogue/sites?tab=properties" />
                    <MenuItem name="Property Groups" to="/dialogue/sites?tab=groups" />
                  </MenuDropdown>
                ) : (
                  <MenuItem name="Properties" to="/dialogue/sites?tab=properties" icon={<SVG src={propertiesIcon} />} />
                )
              }
              <MenuDropdown name="Vendor Management" icon={<SVG src={vendorsIcon} />}>
                { this.hasFeature('tcf_v2') && <MenuItem name="GDPR TCF" to="/consent_v2/vendor_lists" /> }
                { this.hasFeature('non_iab_vl') && <MenuItem name="GDPR Standard" to="/non-iab/vendor_lists" /> }
                { this.hasFeature('ccpa') && <MenuItem name="U.S. Privacy (Legacy)" to="/us_privacy/vendor_lists" /> }
                { hasUSNATAccessMenu && <MenuItem name="U.S. Multi-State Privacy" to="/us_privacy/regulations" /> }
              </MenuDropdown>
              {hasPreferencesAccess &&
                <MenuDropdown name="Preferences" icon={<BuildOutlined />}>
                  <MenuItem name="Configurations" to="/preferences" />
                  {hasLegalDocAccess ? <MenuItem name="Legal Document Library" to="/preferences/legal" /> : null}
                  {hasIntegrationHubAccess ? <MenuItem name="Integration Hub" to="/preferences/connections" /> : null}
                </MenuDropdown>}
              {hasCampaignEntitiesAccess &&
                <MenuDropdown name="Campaigns" icon={<SVG src={campaignsIcon} />}>
                  <MenuItem name="Campaigns" to={`/dialogue/campaigns_v2${siteIds}`} />
                  <MenuItem name="Partition Sets" to={`/dialogue/partitions${siteIds}`} />
                  <MenuItem name="Scenarios" to={`/dialogue/scenariosv3${siteIds}`} />
                </MenuDropdown>}
              {hasCampaignEntitiesAccess && <MenuDropdown name="Messages" icon={<SVG src={messagesIcon} />} placement="left">
                {hasGDPRAccessMenu && (
                  <MenuDropdown name="GDPR Messages">
                    {this.hasFeature('tcf_v2') && <MenuItem name="Web / Webview (TCF)" to={`/dialogue/messages/tcfv2${siteIds}`} />}
                    {this.hasFeature('non_iab_vl') && <MenuItem name="Web / Webview (Standard)" to={`/dialogue/messages/non-tcf${siteIds}`} />}
                    {this.hasFeature('native_ott') && <MenuItem name="OTT" to={`/dialogue/native-ott${siteIds}`} />}
                    {this.hasFeature('privacy_manager_ott') && <MenuItem name="Legacy OTT" to={`/dialogue/privacy-manager/ott${siteIds}`} />}
                    {this.hasFeature('native_messaging') && <MenuItem name="Native App" to={`/dialogue/native-message-gdpr${siteIds}`} />}
                  </MenuDropdown>
                )}
                {hasCCPAccessMenu && (
                  <MenuDropdown name="U.S. Privacy Messages">
                    <MenuItem name="Web / Webview" to={`/dialogue/messages/us_privacy${siteIds}`} />
                    {this.hasFeature('native_ott_ccpa') && <MenuItem name="OTT" to={`/dialogue/native-ott-us-privacy${siteIds}`} />}
                    {this.hasFeature('ccpa_ott') && <MenuItem name="Legacy OTT" to={`/dialogue/us-privacy-ott${siteIds}`} />}
                    {this.hasFeature('native_messaging') && <MenuItem name="Native Message" to={`/dialogue/native-message-us-privacy${siteIds}`} />}
                    {this.hasFeature('sar') && <MenuItem name="DSAR Retrieval Form" to={`/dialogue/dsar-retrieval${siteIds}`} />}
                    {this.hasFeature('sar') && <MenuItem name="Subject Access Request Form" to={`/dialogue/sar${siteIds}`} />}
                    {this.hasFeature('ccpa') && <MenuItem name="Legacy Privacy Manager" to={'/us_privacy/privacy_manager'} />}
                  </MenuDropdown>
                )}
                {hasUSNATAccessMenu && (
                  <MenuDropdown name="U.S. Multi-State Privacy Messages">
                    <MenuItem name="Web / Webview" to={'/dialogue/messages/usnat_fl' + siteIds} />
                    { this.hasFeature("sar") && <MenuItem name="DSAR Retrieval Form" to={'/dialogue/dsar-retrieval' + siteIds} /> }
                    { this.hasFeature("sar") && <MenuItem name="Subject Access Request Form" to={'/dialogue/sar' + siteIds} /> }
                  </MenuDropdown>
                )}
                {hasPreferencesAccess && (
                  <MenuItem name="Preferences" to={'/dialogue/messages/preferences' + siteIds} />
                )}
                {hasAppleMessagingAccess && <MenuItem name="iOS Tracking Messages" to={`/dialogue/messages-ios${siteIds}`} />}
                {hasCustomMessages && <MenuItem name="Custom Messages" to={`/dialogue/messages-custom${siteIds}`} />}
                {this.hasFeature('adblock_v2') && <MenuItem name="Adblock Messages" to={`/dialogue/adblock${siteIds}`} />}
                {this.hasFeature('adblock') && <MenuItem name="Adblock Messages (Classic)" to={`/dialogue/messages-adblock${siteIds}`} />}
              </MenuDropdown>}
              <Divider />
            </React.Fragment>

          ) : null}


          { /* DIAGNOSE */}
          {(this.hasFeatureAndUserAccess('diagnose_dashboard', 'Diagnose Dashboard') || this.hasFeature('diagnose_vendor_trace')) && (
            <React.Fragment>
              <MenuTitle name="Diagnose" icon={<SVG src="/svg_icons/side_navigation/DiagnoseIcon.svg" />} />
              {this.hasFeature('diagnose_dashboard') && (
                <MenuDropdown name="Web" icon={<SVG src="/svg_icons/side_navigation/GlobalOutlined.svg" />} placement="left">
                  <MenuDropdown name="Metrics" placement="left">
                    <MenuItem name="Summary" to="/diagnose_dashboard" active={this.isActive('/diagnose_dashboard')} />
                    {this.hasFeatureAndUserAccess('diagnose_metric_non_disclosed_vendors', 'Diagnose - Non disclosed vendors observed') && <MenuItem name="Non disclosed vendors observed" to="/diagnose_dashboard/non_disclosed_vendors_observed" active={this.isActive('/non_disclosed_vendors_observed')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_vendors_prior_to_consent', 'Diagnose - Vendors triggered prior to consent') && <MenuItem name="Vendors triggered prior to consent" to="/diagnose_dashboard/vendors_triggered_prior_to_consent" active={this.isActive('/vendors_triggered_prior_to_consent')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_disclosed_vendors', 'Diagnose - Disclosed Vendors') && <MenuItem name="Disclosed vendors" to="/diagnose_dashboard/disclosed_vendors" active={this.isActive('/disclosed_vendors')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_fingerprinting', 'Diagnose - Possible Fingerprinting') && <MenuItem name="Possible fingerprinting" to="/diagnose_dashboard/possible_fingerprinting" active={this.isActive('/possible_fingerprinting')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_data_leaving_eea', 'Diagnose - Data leaving the EEA') && <MenuItem name="Data leaving the EEA" to="/diagnose_dashboard/data_leaving_the_EEA" active={this.isActive('/data_leaving_the_EEA')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_cookies_with_long_lifespans', 'Diagnose - Cookies with long lifespans') && <MenuItem name="Cookies with long lifespans" to="/diagnose_dashboard/cookies_with_long_lifespans" active={this.isActive('/cookies_with_long_lifespans')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_all_cookies_found', 'Diagnose - All Cookies Found') && <MenuItem name="All cookies found" to="/diagnose_dashboard/all_cookies_found" active={this.isActive('/all_cookies_found')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_vendors_after_opt_out', 'Diagnose - Vendors after opt out') && <MenuItem name="Vendors after opt out" to="/diagnose_dashboard/vendors_after_opt_out" active={this.isActive('/vendors_after_opt_out')} />}
                    {this.hasFeatureAndUserAccess('diagnose_metric_tracking_tech_detected','Diagnose - Tracking Tech detected') && <MenuItem name="Tracking tech detected" to="/diagnose_dashboard/tracking_tech_detected" active={this.isActive('/tracking_tech_detected')}></MenuItem>}
                  </MenuDropdown>
                  {this.hasFeature('diagnose_vendor_trace') && <MenuItem name="Vendor trace" to="/vendor_trace" active={this.isActive('/vendor_trace')} />}
                </MenuDropdown>
              )}
              {this.hasFeature('app_dashboard') && (
                <MenuDropdown name="App" icon={<SVG src="/svg_icons/side_navigation/App.svg" />} placement="left">
                   <MenuDropdown name="Metrics" placement="left">
                    <MenuItem name="Summary" to="/app_metrics" active={this.isActive('/app_metrics')} />
                    {this.hasFeature('app_metric_vendors_prior_to_consent') && <MenuItem name="Vendors triggered prior to consent" to="/app_metrics/vendors_triggered_prior_to_consent" active={this.isActive('/vendors_triggered_prior_to_consent')} />}
                    {this.hasFeature('app_metric_vendors_after_opt_out') && <MenuItem name="Vendors after opt out" to="/app_metrics/vendors_after_opt_out" active={this.isActive('/vendors_after_opt_out')} />}
                    {this.hasFeature('app_metric_vendors_after_reject_all') && <MenuItem name="Vendors after reject all" to="/app_metrics/vendors_after_reject_all" active={this.isActive('/vendors_after_opt_out')} />}
                    {this.hasFeature('app_metric_all_vendors_found') && <MenuItem name="All vendors found" to="/app_metrics/all_vendors_found" active={this.isActive('/vendors_after_opt_out')} />}
                   </MenuDropdown>
                  <MenuItem name="Properties" to="/diagnose/app_properties" active={this.isActive('/diagnose/app_properties')} />
                </MenuDropdown>
              )}
              {/* TODO: remove 'process.env.REACT_APP_PUB_PORTAL_ENV' when it will be ready to go out into production */}
              {(this.hasFeature('diagnose_dashboard') || this.hasFeature('diagnose_vendor_trace')) && (
                <MenuItem
                  name="Alerts"
                  to="/diagnose/alerts"
                  active={this.isActive('/diagnose/alerts')}
                  icon={<SVG src={BellIcon} />}
                />
              )}
              <Divider />
            </React.Fragment>
          )}

          { /* PRIVACY LENS */}
          {accountHasPrivacyLens(this.props.currentUser.accountFeatures) ? (
            <React.Fragment>
              <MenuTitle name="Privacy Lens" icon={<SVG src={privacyLensIcon} />} />
              {currentUser.accountId === 22 && <MenuDropdown name="Admin" icon={<SVG src={adminIcon} />}>
                <MenuItem name="Accuracy" to="/privacy_lens/admin/accuracy" />
                <MenuItem name="Rule Management" to="/privacy_lens/admin/account_management" />
                <MenuItem name="Account Management" to="/privacy_lens/admin/rule_management" />
              </MenuDropdown>}
              {(this.hasFeature('privacy_lens') || this.hasFeature('privacy_lens_read_only'))
                ? (<React.Fragment>
                  <MenuItem name="Standards" to="/privacy_lens/standards" icon={<SVG src={standardsIcon} />} />
                  <MenuItem name="Property Sets" to="/privacy_lens/domain_sets" icon={<SVG src={propertySetsIcon} />} />
                </React.Fragment>)
                : null
              }
              <MenuItem name="Scoring Results" to="/privacy_lens/scoring_results" icon={<FontAwesomeIcon icon={faChartArea} />} />
            </React.Fragment>
          ) : null}

          <div className="admin-wrapper">
            <UserDropdownMenu
              className="account-group"
              isAdmin={this.props.currentUser.isAdmin}
              isSPUser={this.props.currentUser.accountId === 22}
              featureAccess={this.props.currentUser.featureAccess}
              features={this.props.currentUser.accountFeatures}
              masqed={this.props.currentUser.masqed}
              isMasqAccount={this.props.currentUser.accountId === 375 || this.props.currentUser.accountId === 75 && (!this.props.currentUser.accountAccess || this.props.currentUser.accountAccess.size > 1)}
            />
          </div>
        </div>
      </div>
    );
  }
}

