import 'whatwg-fetch';
import { fromJS, List, Map } from 'immutable';
import {
  checkStatus,
  divideByZeroErrChk,
  CSRF_TOKEN,
  reportingRmReqBody
} from '../helper.js';
import {
  VendorsConsentReportRecord,
  PropertiesReportV2Record,
  MessageTableReportV2Record,
} from '../../records/gdpr_tcfv2_performance_records_v2.js';
import { DATEPICKERS } from '../../constants';
import moment from 'moment';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;
const reportingVersion = '2.0'

export async function getAndParseGdprV2ReportMessagedData(data, rangeSelect, pvUsersData) {
  const messagesPromises = data.map((d, i) => {
    const siteIds = d.get('siteId').toString();
    let formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    formattedData['version'] = reportingVersion;
    formattedData = reportingRmReqBody(formattedData);
    return fetch(permissionsSvsBase + '/reports/tcfv2/dashboard-v2-messages/daily', { method: 'post', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formattedData) })
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) => aggregateMessageData(fromJS(resp), rangeSelect, formattedData, i, pvUsersData));
  });

  return Promise.all(messagesPromises).then((res) => List(res));
}

export async function getAndParseGdprV2ReportPVUsersData(data, rangeSelect) {
  const pvUsersPromises = data.map((d, i) => {
    const siteIds = d.get('siteId').toString();
    let formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    formattedData['version'] = reportingVersion;
    formattedData = reportingRmReqBody(formattedData);
    return fetch(permissionsSvsBase + '/reports/tcfv2/dashboard-v2-pv-users/daily', {
      method: 'post',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
      body: JSON.stringify(formattedData)
    })
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) => aggregatePropertyData(fromJS(resp), i, rangeSelect, formattedData));
  });

  return Promise.all(pvUsersPromises).then((res) => List(res));
}

export async function getAndParseGdprV2ReportSpecialFeaturesData(
  vendorData,
  rangeData
) {
  const siteIds = [vendorData.get('siteId').toString()];
  const formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  formattedData['siteId'] = siteIds;
  return fetch(permissionsSvsBase + '/reports/tcfv2/dashboard-v2-special-features/daily', { method: 'post', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formattedData) })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

function aggregateData(data) {
  let aggregateData = List();
  let key = 0;
  let groupedByVendorNamesOrderedMap;
  groupedByVendorNamesOrderedMap = data
    .get('results')
    .groupBy((dataPoint) => dataPoint.get('name'));
  groupedByVendorNamesOrderedMap.map((sp, i) => {
    const aggTotalViews = mapReduceSum(sp, 'total_views');
    const aggFullOptin = mapReduceSum(sp, 'consented');
    const agglegIntOnly = mapReduceSum(sp, 'leg_int_only');
    const aggRejectAll = mapReduceSum(sp, 'rejected');
    aggregateData = aggregateData.push(
      Map({
        key: key,
        name: i,
        totalViews: aggTotalViews,
        fullOptIn: aggFullOptin,
        legIntOnly: agglegIntOnly,
        rejectAll: aggRejectAll,
        fullOptInPer: divideByZeroErrChk(aggFullOptin, aggTotalViews).toFixed(2) + '%',
        legIntOnlyPer: divideByZeroErrChk(agglegIntOnly, aggTotalViews).toFixed(2) + '%',
        rejectAllPer: divideByZeroErrChk(aggRejectAll, aggTotalViews).toFixed(2) + '%',
      })
    );
    key++;
  });
  return aggregateData.toJS();
}

export async function getAndParseGdprV2ReportVendorData(vendorData, rangeData) {
  const siteIds = [vendorData.get('siteId').toString()];
  const formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  formattedData['siteId'] = siteIds;
  return fetch(permissionsSvsBase + '/reports/tcfv2/dashboard-v2-vendors/daily', { method: 'post', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formattedData) })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

export async function getAndParseGdprV2ReportPurposeData(
  vendorData,
  rangeData
) {
  const siteIds = [vendorData.get('siteId').toString()];
  const formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  formattedData['siteId'] = siteIds;
  return fetch(permissionsSvsBase + '/reports/tcfv2/dashboard-v2-purposes/daily', { method: 'post', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formattedData) })
    .then(checkStatus)
   .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

function aggViewStatusData(data, pvUsersWeeklyMonthlyData, index, rangeSelect){
  let groupedByYmdOrderedMap;
  let exportData = List();
  let propertyTableData = List();
  let aggPropertyGraphData = List();
  let aggTotalVolume = 0;
  let aggFullConsent = 0;
  let aggRejectAll = 0;
  let aggLegitimateIntObj = 0;
  let aggPrevFullConsent = 0;
  let aggOther = 0;
  let aggNoAction = 0;
  let aggPropertyTableData;
  let graphKey = 0;
  const datePicker = rangeSelect.get('datePicker');

  const quartData = data
    .map((dataPoint) => dataPoint.set('week', moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()))
    .groupBy((dataPoint) => dataPoint.get('week'));
  groupedByYmdOrderedMap = data.groupBy((dataPoint) => dataPoint.get('ymd'));
  const groupByOrderedMap = datePicker === DATEPICKERS.quarter ? quartData : groupedByYmdOrderedMap;
  groupByOrderedMap.map((p, i) => {
    const date = datePicker === DATEPICKERS.quarter ? p.get(0).get('ymd') : i;
    let expData = p.map((e) => {
      const pvCount = Number(e.get('pv'));
      const fullOptinCount = Number(e.get('pv_status_opt_in_all'));
      const legIntOnlyCount = Number(e.get('pv_status_li_only'));
      const rejectAllCount = Number(e.get('pv_status_opt_out_all'));
      const prevOptinCount = Number(e.get('pv_status_previous_optin_all'));
      const otherCount = Number(e.get('pv_status_other'));
      const noActionCount = Number(e.get('pv_default_consent_only'));
      return Map({
        date: datePicker === DATEPICKERS.quarter ? 'Week ' + moment(p.get(0).get('ymd'), 'YYYY-MM-DD').isoWeek(): i,
        siteid: Number(e.get('site_id')),
        totalpageviews: pvCount,
        messagedusers: Number(e.get('total_messaged_users')),
        fullconsent: fullOptinCount,
        fullconsentper: divideByZeroErrChk(fullOptinCount, pvCount).toFixed(2) + '%',
        rejectedall: rejectAllCount,
        rejectedallper: divideByZeroErrChk(rejectAllCount, pvCount).toFixed(2) + '%',
        legitimateintobj: legIntOnlyCount,
        legitimateintobjper: divideByZeroErrChk(legIntOnlyCount, pvCount).toFixed(2) + '%',
        prevfullconsent: prevOptinCount,
        prevfullconsentper: divideByZeroErrChk(prevOptinCount, pvCount).toFixed(2) + '%',
        other: otherCount,
        otherper: divideByZeroErrChk(otherCount, pvCount).toFixed(2) + '%',
        noaction: noActionCount,
        noactionper: divideByZeroErrChk(noActionCount, pvCount).toFixed(2) + '%',
      });
    });
    exportData = exportData.concat(expData);
    let stv = mapReduceSum(p, 'pv');
    let spfc = mapReduceSum(p, 'pv_status_opt_in_all');
    let spra = mapReduceSum(p, 'pv_status_opt_out_all');
    let slio = mapReduceSum(p, 'pv_status_li_only');
    let spfoi = mapReduceSum(p, 'pv_status_previous_optin_all');
    let spo = mapReduceSum(p, 'pv_status_other');
    let spna = mapReduceSum(p, 'pv_default_consent_only');
    propertyTableData = propertyTableData.push(
      Map({
        date: date,
        fancyDate:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(date, 'YYYY-MM-DD').isoWeek()
            : date,
        totalpageviews: stv,
        fullconsent: spfc,
        fullconsentper: divideByZeroErrChk(spfc, stv).toFixed(2) + '%',
        rejectedall: spra,
        rejectedallper: divideByZeroErrChk(spra, stv).toFixed(2) + '%',
        legitimateintobj: slio,
        legitimateintobjper: divideByZeroErrChk(slio, stv).toFixed(2) + '%',
        prevfullconsent: spfoi,
        prevfullconsentper: divideByZeroErrChk(spfoi, stv).toFixed(2) + '%',
        other: spo,
        otherper: divideByZeroErrChk(spo, stv).toFixed(2) + '%',
        noaction: spna,
        noactionper: divideByZeroErrChk(spna, stv).toFixed(2) + '%',
      })
    );

    graphKey++;
    aggPropertyGraphData = aggPropertyGraphData.push(
      Map({
        key: graphKey,
        fancyDate:
          datePicker == DATEPICKERS.quarter
            ? 'Week ' + i
            : moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalpageviews' + index]: stv,
        ['fullconsent' + index]: divideByZeroErrChk(spfc, stv),
        ['rejectedall' + index]: divideByZeroErrChk(spra, stv),
        ['legitimateintobj' + index]: divideByZeroErrChk(slio, stv),
        ['prevfullconsent' + index]: divideByZeroErrChk(spfoi, stv),
        ['other' + index]: divideByZeroErrChk(spo, stv),
        ['noaction' + index]: divideByZeroErrChk(spna, stv),
        ['siteIds' + index]: p
          .map((entry) => [entry.get('site_id')])
          .reduce((prev, current) => prev.concat(current)),
      })
    );
  });

  pvUsersWeeklyMonthlyData.filter(pvuData => pvuData.get('unique_users')).map((pvUsr) => {
    aggTotalVolume += Number(pvUsr.get('pv'));
    aggFullConsent += Number(pvUsr.get('pv_status_opt_in_all'));
    aggRejectAll += Number(pvUsr.get('pv_status_opt_out_all'));
    aggLegitimateIntObj += Number(pvUsr.get('pv_status_li_only'));
    aggPrevFullConsent += Number(pvUsr.get('pv_status_previous_optin_all'));
    aggOther += Number(pvUsr.get('pv_status_other'));
    aggNoAction += Number(pvUsr.get('pv_default_consent_only'));
  });

  aggPropertyTableData = Map({
    key: index,
    date: 'Criteria ' + Number(index + 1),
    totalpageviews: aggTotalVolume,
    fullconsent: aggFullConsent,
    fullconsentper: divideByZeroErrChk(aggFullConsent, aggTotalVolume),
    rejectedall: aggRejectAll,
    rejectedallper: divideByZeroErrChk(aggRejectAll, aggTotalVolume),
    legitimateintobj: aggLegitimateIntObj,
    legitimateintobjper: divideByZeroErrChk(aggLegitimateIntObj, aggTotalVolume),
    prevfullconsent: aggPrevFullConsent,
    prevfullconsentper: divideByZeroErrChk(aggPrevFullConsent, aggTotalVolume),
    other: aggOther,
    otherper: divideByZeroErrChk(aggOther, aggTotalVolume),
    noaction: aggNoAction,
    noactionper: divideByZeroErrChk(aggNoAction, aggTotalVolume),
    dataArr: propertyTableData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
    exportdata: exportData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
  });
  return { viewTableData: aggPropertyTableData, viewGraphData: aggPropertyGraphData };
}

function aggUserStatusData(data, pvUsersWeeklyMonthlyData, index, rangeSelect){
  let groupedByYmdOrderedMap;
  let exportData = List();
  let propertyTableData = List();
  let aggPropertyGraphData = List();
  let aggTotalUsers = 0;
  let aggFullConsent = 0;
  let aggRejectAll = 0;
  let aggLegitimateIntObj = 0;
  let aggPrevFullConsent = 0;
  let aggOther = 0;
  let aggNoAction = 0;
  let aggPropertyTableData;
  let graphKey = 0;
  const datePicker = rangeSelect.get('datePicker');

  const quartData = data
    .map((dataPoint) => dataPoint.set('week', moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()))
    .groupBy((dataPoint) => dataPoint.get('week'));
  groupedByYmdOrderedMap = data.groupBy((dataPoint) => dataPoint.get('ymd'));
  const groupByOrderedMap = datePicker === DATEPICKERS.quarter ? quartData : groupedByYmdOrderedMap;
  groupByOrderedMap.map((p, i) => {
    const date = datePicker === DATEPICKERS.quarter ? p.get(0).get('ymd') : i;
    let expData = p.map((e) => {
      const totalUsersCount = Number(e.get('unique_users'));
      const fullOptinCount = Number(e.get('user_status_opt_in_all'));
      const legIntOnlyCount = Number(e.get('user_status_li_only'));
      const rejectAllCount = Number(e.get('user_status_opt_out_all'));
      const prevOptinCount = Number(e.get('user_status_previous_optin_all'));
      const otherCount = Number(e.get('user_status_other'));
      const noActionCount = Number(e.get('user_default_consent_only'));
      return Map({
        date: datePicker === DATEPICKERS.quarter ? 'Week ' + moment(p.get(0).get('ymd'), 'YYYY-MM-DD').isoWeek(): i,
        siteid: Number(e.get('site_id')),
        totalusers: totalUsersCount,
        fullconsent: fullOptinCount,
        fullconsentper: divideByZeroErrChk(fullOptinCount, totalUsersCount).toFixed(2) + '%',
        rejectedall: rejectAllCount,
        rejectedallper: divideByZeroErrChk(rejectAllCount, totalUsersCount).toFixed(2) + '%',
        legitimateintobj: legIntOnlyCount,
        legitimateintobjper: divideByZeroErrChk(legIntOnlyCount, totalUsersCount).toFixed(2) + '%',
        prevfullconsent: prevOptinCount,
        prevfullconsentper: divideByZeroErrChk(prevOptinCount, totalUsersCount).toFixed(2) + '%',
        other: otherCount,
        otherper: divideByZeroErrChk(otherCount, totalUsersCount).toFixed(2) + '%',
        noaction: noActionCount,
        noactionper: divideByZeroErrChk(noActionCount, totalUsersCount).toFixed(2) + '%',
      });
    });
    exportData = exportData.concat(expData);
    let stv = mapReduceSum(p, 'unique_users');
    let spfc = mapReduceSum(p, 'user_status_opt_in_all');
    let spra = mapReduceSum(p, 'user_status_opt_out_all');
    let slio = mapReduceSum(p, 'user_status_li_only');
    let spfoi = mapReduceSum(p, 'user_status_previous_optin_all');
    let spo = mapReduceSum(p, 'user_status_other');
    let spna = mapReduceSum(p, 'user_default_consent_only');
    propertyTableData = propertyTableData.push(
      Map({
        date: date,
        fancyDate: datePicker === DATEPICKERS.quarter ? 'Week ' + moment(date, 'YYYY-MM-DD').isoWeek() : date,
        totalusers: stv,
        fullconsent: spfc,
        fullconsentper: divideByZeroErrChk(spfc, stv).toFixed(2) + '%',
        rejectedall: spra,
        rejectedallper: divideByZeroErrChk(spra, stv).toFixed(2) + '%',
        legitimateintobj: slio,
        legitimateintobjper: divideByZeroErrChk(slio, stv).toFixed(2) + '%',
        prevfullconsent: spfoi,
        prevfullconsentper: divideByZeroErrChk(spfoi, stv).toFixed(2) + '%',
        other: spo,
        otherper: divideByZeroErrChk(spo, stv).toFixed(2) + '%',
        noaction: spna,
        noactionper: divideByZeroErrChk(spna, stv).toFixed(2) + '%',
      })
    );

    graphKey++;
    aggPropertyGraphData = aggPropertyGraphData.push(
      Map({
        key: graphKey,
        fancyDate:
          datePicker == DATEPICKERS.quarter
            ? 'Week ' + i
            : moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalusers' + index]: stv,
        ['fullconsent' + index]: divideByZeroErrChk(spfc, stv),
        ['rejectedall' + index]: divideByZeroErrChk(spra, stv),
        ['legitimateintobj' + index]: divideByZeroErrChk(slio, stv),
        ['prevfullconsent' + index]: divideByZeroErrChk(spfoi, stv),
        ['other' + index]: divideByZeroErrChk(spo, stv),
        ['noaction' + index]: divideByZeroErrChk(spna, stv),
        ['siteIds' + index]: p
          .map((entry) => [entry.get('site_id')])
          .reduce((prev, current) => prev.concat(current)),
      })
    );
  });

  pvUsersWeeklyMonthlyData.filter(pvuData => pvuData.get('unique_users')).map((pvUsr) => {
    aggTotalUsers += Number(pvUsr.get('unique_users'));
    aggFullConsent += Number(pvUsr.get('user_status_opt_in_all'));
    aggRejectAll += Number(pvUsr.get('user_status_opt_out_all'));
    aggLegitimateIntObj += Number(pvUsr.get('user_status_li_only'));
    aggPrevFullConsent += Number(pvUsr.get('user_status_previous_optin_all'));
    aggOther += Number(pvUsr.get('user_status_other'));
    aggNoAction += Number(pvUsr.get('user_default_consent_only'));
  });

  aggPropertyTableData = Map({
    key: index,
    date: 'Criteria ' + Number(index + 1),
    totalusers: aggTotalUsers,
    fullconsent: aggFullConsent,
    fullconsentper: divideByZeroErrChk(aggFullConsent, aggTotalUsers),
    rejectedall: aggRejectAll,
    rejectedallper: divideByZeroErrChk(aggRejectAll, aggTotalUsers),
    legitimateintobj: aggLegitimateIntObj,
    legitimateintobjper: divideByZeroErrChk(aggLegitimateIntObj, aggTotalUsers),
    prevfullconsent: aggPrevFullConsent,
    prevfullconsentper: divideByZeroErrChk(aggPrevFullConsent, aggTotalUsers),
    other: aggOther,
    otherper: divideByZeroErrChk(aggOther, aggTotalUsers),
    noaction: aggNoAction,
    noactionper: divideByZeroErrChk(aggNoAction, aggTotalUsers),
    dataArr: propertyTableData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
    exportdata: exportData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
  });
  return { userStatusTableData: aggPropertyTableData, userStatusGraphData: aggPropertyGraphData };
}

async function mapMessageActionsToSitesDaywise(data, rangeSelect, formattedData ){
  const datePicker = rangeSelect.get('datePicker');
  const isIncompleteDate = isIncompleteDateRange(datePicker, formattedData.endDate);
  const auxUrl = datePicker === DATEPICKERS.quarter ? permissionsSvsBase + '/reports/tcfv2/dashboard-v2-pv-users/quarterly' : datePicker === DATEPICKERS.week ? permissionsSvsBase + '/reports/tcfv2/dashboard-v2-pv-users/weekly' : permissionsSvsBase + '/reports/tcfv2/dashboard-v2-pv-users/monthly';
  const auxData =
    isIncompleteDate
      ? data
      : await fetch(auxUrl, { method: 'post', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formattedData) })
          .then(checkStatus)
          .then((resp) => resp.json())
          .then((resp) => fromJS(resp.results));
  return auxData;
}

async function aggregatePropertyData(data, index, rangeSelect, formattedData) {
  const partiallyRemovedData = data.get('results').filter(pvuData => pvuData.get('unique_users'));
  const pvUsersWeeklyMonthlyData = await mapMessageActionsToSitesDaywise(partiallyRemovedData, rangeSelect, formattedData);
  const viewStatusData = aggViewStatusData(partiallyRemovedData, pvUsersWeeklyMonthlyData, index, rangeSelect);
  const userStatusData = aggUserStatusData(partiallyRemovedData, pvUsersWeeklyMonthlyData, index, rangeSelect);
  return Map({
    propertyTableData: propertyReportToRecord(viewStatusData.viewTableData, index),
    propertyGraph: viewStatusData.viewGraphData,
    userStatusTableData: propertyReportToRecord(userStatusData.userStatusTableData, index),
    userStatusGraph: userStatusData.userStatusGraphData,
    messagedActionsSitewise: pvUsersWeeklyMonthlyData,
    messgeActionsDaily: partiallyRemovedData,
  });
}

function mapReduceSum(arr, key, addToKey) {
  return (
    arr
      .map((entry) => (Number(entry.get(key)) || 0) + (Number(entry.get(addToKey)) || 0))
      .reduce((prev, current) => prev + current) || 0
  );
}

function mapReduceSumToFixed(arr, key) {
  return mapReduceSum(arr, key).toFixed(2);
}

function mapReducePercentageRate(arr, key) {
  return Number((100 - mapReducePercentage(arr, key)).toFixed(2));
}

function mapReducePercentage(arr, key) {
  return Number(
    (
      arr
        .map((entry) => Number(entry.get(key)))
        .reduce((prev, current) => prev + current) / arr.size
    ).toFixed(2)
  );
}

function propertyReportToRecord(data, index) {
  return PropertiesReportV2Record({
    key: index,
    date: data.get('date'),
    fancyDate: data.get('fancyDate'),
    siteid: data.get('siteid'),
    totalpageviews: data.get('totalpageviews'),
    totalusers: data.get('totalusers'),
    fullconsent: data.get('fullconsent'),
    fullconsentper: data.get('fullconsentper'),
    partialconsent: data.get('partialconsent'),
    partialconsentper: data.get('partialconsentper'),
    rejectedall: data.get('rejectedall'),
    rejectedallper: data.get('rejectedallper'),
    legitimateintobj: data.get('legitimateintobj'),
    legitimateintobjper: data.get('legitimateintobjper'),
    prevfullconsent: data.get('prevfullconsent'),
    prevfullconsentper: data.get('prevfullconsentper'),
    other: data.get('other'),
    otherper: data.get('otherper'),
    noaction: data.get('noaction'),
    noactionper: data.get('noactionper'),
    aggData: data.get('dataArr') ? data.get('dataArr') : List(),
    exportdata: data.get('exportdata') ? data.get('exportdata') : List(),
  });
}

function VendorReportToRecord(data) {
  return VendorsConsentReportRecord({
    key: data.key,
    name: data.name,
    totalViews: data.totalViews,
    fullOptIn: data.fullOptIn,
    legIntOnly: data.legIntOnly,
    rejectAll: data.rejectAll,
    fullOptInPer: data.fullOptInPer,
    legIntOnlyPer: data.legIntOnlyPer,
    rejectAllPer: data.rejectAllPer,
  });
}

export function isIncompleteDateRange(datepicker, date) {
  switch (datepicker) {
    case 'WEEK':
      return moment().isoWeek() === moment(date, 'YYYY-MM-DD').isoWeek();
    case 'MONTH':
      return moment().month() === moment(date, 'YYYY-MM-DD').month();
    case 'QUARTER':
      return moment().quarter() === moment(date, 'YYYY-MM-DD').quarter();
  }
}
function messageActionsCompleteAggWindow(auxData, pvUsersDataWeeklyMonthly, pvUsersDataDaily, partiallyRemovedData){
  let aggTotalUsers = 0;
  let aggTotalMsgs = 0;
  let aggConsentAll = 0;
  let aggRejectAll = 0;
  let aggLegitimateIntObj = 0;
  let aggBounces = 0;
  let aggOther = 0;
  let key = 0;
  let aggMessageTableData = List();

  const aggWeeklyMonthlyData = auxData.filter((a) => a.get('message_id') != undefined).groupBy((dataPoint) => dataPoint.get('site_id'));

  aggWeeklyMonthlyData.map((a, siteId) => {
    const uniqueMessageIds = new Set();
    const totalUsers = Number(pvUsersDataWeeklyMonthly.find((pvData) => pvData.get('site_id') == siteId).get('unique_users'));
    const totalMsgs = mapReduceSum(
      a.filter((dataPoint) => {
        const messageId = dataPoint.get('message_id');
        if (!uniqueMessageIds.has(messageId)) {
          uniqueMessageIds.add(messageId); // Mark this message_id as processed
          return true; // Include in summation
        }
        return false; // Skip duplicate message_id
      }),
      'messaged_users'
    ) || 0;
    const consentAll = mapReduceSum(a, 'message_opt_in_all') || 0;
    const rejectAll = mapReduceSum(a, 'message_opt_out_all') || 0;
    const legitimateIntObj = mapReduceSum(a, 'message_li_only') || 0;
    const bounces = totalMsgs - (mapReduceSum(a, 'adjusted_actions') || 0);
    const other = mapReduceSum(a, 'message_other') + mapReduceSum(a, 'message_previous_optin_to_all');

    aggTotalUsers += totalUsers;
    aggTotalMsgs += totalMsgs;
    aggConsentAll += consentAll;
    aggRejectAll += rejectAll;
    aggLegitimateIntObj += legitimateIntObj;
    aggBounces += bounces;
    aggOther += other;
    let expandableTableData = List();

    a.groupBy((dataPoint) => dataPoint.get('message_id')).map((msgData, mi) => {
      const stu = totalUsers;
      const uniqueMessageIds = new Set();
      const stm = mapReduceSum(msgData.filter((mi) => {
        const messageId = mi.get('message_id');
        if (!uniqueMessageIds.has(messageId)) {
          uniqueMessageIds.add(messageId);
          return true;
        }
        return false;
      }),
        'messaged_users'
      ) || 0;
        const sca = mapReduceSum(msgData, 'message_opt_in_all') || 0;
        const sra = mapReduceSum(msgData, 'message_opt_out_all') || 0;
        const slio = mapReduceSum(msgData, 'message_li_only') || 0;
        const cwzmu = msgData.filter((zmu) => Number(zmu.get('messaged_users') > 0));
      const sb = stm - (mapReduceSum(cwzmu, 'adjusted_actions') || 0);
        const so = mapReduceSum(msgData, 'message_other') + mapReduceSum(msgData, 'message_previous_optin_to_all');

      partiallyRemovedData
      .filter((m) => m.get('message_id') != undefined && m.get('message_id') == mi)
      .groupBy((dataPoint) => dataPoint.get('ymd') + '-' + dataPoint.get('message_id') )
        .map((e, j) => {
        const uniqueMessageIdsForUsers = new Set();
        const uniqueMessageIdsForMsgedUsers = new Set();
          
        const calTotalUsers =  e.filter((eData) => {
          const messageId = eData.get('message_id');
          if (!uniqueMessageIdsForUsers.has(messageId)) {
            uniqueMessageIdsForUsers.add(messageId);
            return true; 
          }
          return false;
        }).map((eData) => Number(pvUsersDataDaily.find((pvuData) => pvuData.get('ymd') == eData.get('ymd') && pvuData.get('site_id') == eData.get('site_id')).get('unique_users'))).reduce((prev, current) => prev + current) || 0;
        const calTotalMsgedUsers = mapReduceSum(
          e.filter((mi) => {
            const messageId = mi.get('message_id');
            if (!uniqueMessageIdsForMsgedUsers.has(messageId)) {
              uniqueMessageIdsForMsgedUsers.add(mi);
              return true;
            }
            return false;
          }),
          'messaged_users'
        ) || 0;
     
        const calConsentAll = mapReduceSum(e, 'message_opt_in_all') || 0;
        const calRejectAll = mapReduceSum(e, 'message_opt_out_all') || 0;
        const calLegitimateInterestObjection = mapReduceSum(e, 'message_li_only') || 0;
        const calConsentWithoutZeroMessagedUsers = e.filter((zmu) => Number(zmu.get('messaged_users') > 0));
        const calBounces = calTotalMsgedUsers - (mapReduceSum(calConsentWithoutZeroMessagedUsers, 'adjusted_actions') || 0);
        const calOther = mapReduceSum(e, 'message_other', 'message_previous_optin_to_all');

        expandableTableData = expandableTableData.push(
          Map({
            date: e.get(0).get('ymd'),
            criteriaId: e.get(0).get('ymd'),
            key: j,
            messageid: Number(mi),
            messageName: trimQuotes(e.get(0).get('msg_name')),
            totalUsers: calTotalUsers,
            totalMsgs: calTotalMsgedUsers,
            totalMsgsPer: divideByZeroErrChk(calTotalMsgedUsers, calTotalUsers).toFixed(2) + '%',
            consentAll: calConsentAll,
            consentAllPer: divideByZeroErrChk(calConsentAll, calTotalMsgedUsers).toFixed(2) + '%',
            rejectAll: calRejectAll,
            rejectAllPer: divideByZeroErrChk(calRejectAll, calTotalMsgedUsers).toFixed(2) + '%',
            legitimateIntObj: calLegitimateInterestObjection,
            legitimateIntObjPer: divideByZeroErrChk(calLegitimateInterestObjection, calTotalMsgedUsers).toFixed(2) + '%',
            bounces: calBounces > 0? calBounces : 0,
            bouncesPer: divideByZeroErrChk(calBounces, calTotalMsgedUsers).toFixed(2) + '%',
            other: calOther,
            otherPer: divideByZeroErrChk(calOther, calTotalMsgedUsers).toFixed(2) + '%',
            partitionName: '',
            siteId: e.get(0).get('site_id')
          })
        );
      });
       expandableTableData = expandableTableData.sort((a, b) => moment(b.get('date'), 'YYYY-MM-DD').valueOf() - moment(a.get('date'), 'YYYY-MM-DD').valueOf());

       aggMessageTableData = aggMessageTableData.push(
           Map({
               key: key,
               messageid: Number(mi),
               messageName: trimQuotes(msgData.get(0).get('msg_name')),
               totalUsers: stu,
               totalMsgs: stm,
               totalMsgsPer: divideByZeroErrChk(stm, stu).toFixed(2) + '%',
               consentAll: sca,
               consentAllPer: divideByZeroErrChk(sca, stm).toFixed(2) + '%',
               rejectAll: sra,
               rejectAllPer: divideByZeroErrChk(sra, stm).toFixed(2) + '%',
               legitimateIntObj: slio,
               legitimateIntObjPer: divideByZeroErrChk(slio, stm).toFixed(2) + '%',
               bounces: sb > 0? sb: 0,
               bouncesPer: divideByZeroErrChk(sb, stm).toFixed(2) + '%',
               other: so,
               otherPer: divideByZeroErrChk(so, stm).toFixed(2) + '%',
               partitionName: '',
               siteId: siteId,
               dataArr: expandableTableData.map((e) => MessageTableToRecord(e)),
            })
        );
        key++;
    });
  });

  return Map({
    aggMessageTableData: aggMessageTableData,
    aggTotalUsers: aggTotalUsers,
    aggTotalMsgs: aggTotalMsgs,
    aggConsentAll: aggConsentAll,
    aggRejectAll: aggRejectAll,
    aggLegitimateIntObj: aggLegitimateIntObj,
    aggBounces: aggBounces,
    aggOther: aggOther,
  });
}

function messageActionsIncompleteAggWindow(auxData, pvUsersDataDaily, partiallyRemovedData){
  let aggTotalUsers = 0;
  let aggTotalMsgs = 0;
  let aggConsentAll = 0;
  let aggRejectAll = 0;
  let aggLegitimateIntObj = 0;
  let aggBounces = 0;
  let aggOther = 0;
  let key = 0;
  let aggMessageTableData = List();

  
  const aggDailyData = auxData.filter((a) => a.get('message_id') != undefined).groupBy((dataPoint) => dataPoint.get('site_id') +'-'+ dataPoint.get('ymd'));
  aggDailyData.map((msgData) => {
    const uniqueMessageIds = new Set();
    const filteredMsgs = msgData.filter((dataPoint) => {
      const messageId = dataPoint.get('message_id');
      if (!uniqueMessageIds.has(messageId)) {
        uniqueMessageIds.add(messageId);
        return true;
      }
      return false;
    });
    const totalUsers = Number(pvUsersDataDaily.find((pvData) => pvData.get('ymd') == msgData.get(0).get('ymd') && pvData.get('site_id') == msgData.get(0).get('site_id')).get('unique_users'));
    const totalMsgs = mapReduceSum(filteredMsgs, 'messaged_users') || 0;
    const consentAll = mapReduceSum(msgData, 'message_opt_in_all') || 0;
    const rejectAll = mapReduceSum(msgData, 'message_opt_out_all') || 0;
    const legitimateIntObj = mapReduceSum(msgData, 'message_li_only') || 0;
    const bounces = totalMsgs- (mapReduceSum(msgData, 'adjusted_actions') || 0);
    const other = mapReduceSum(msgData, 'message_other') + mapReduceSum(msgData, 'message_previous_optin_to_all');

    aggTotalUsers += totalUsers;
    aggTotalMsgs += totalMsgs;
    aggConsentAll += consentAll;
    aggRejectAll += rejectAll;
    aggLegitimateIntObj += legitimateIntObj;
    aggBounces += bounces;
    aggOther += other;
  });

  const aggDailyMsgwise = auxData.filter((a) => a.get('message_id') != undefined).groupBy((dataPoint) => dataPoint.get('message_id'));
  aggDailyMsgwise.map((msgData, messageId) => {
    let stu = 0;
    let stmu = 0;
    let sca = 0;
    let sra = 0;
    let slio = 0;
    let sb = 0;
    let so = 0;
    let expandableTableData = List();

    msgData.map((mData, mi) => {
      const totalUsers = Number(pvUsersDataDaily.find((pvData) => pvData.get('ymd') == mData.get('ymd') && pvData.get('site_id') == mData.get('site_id')).get('unique_users'));
      const msgedUsers = Number(mData.get('messaged_users'));
      const consentAll = Number(mData.get('message_opt_in_all'));
      const rejectAll = Number(mData.get('message_opt_out_all'));
      const legitimateIntObj = Number(mData.get('message_li_only'));
      const bounces = Number(mData.get('messaged_users')) - Number(mData.get('adjusted_actions'));
      const other = Number(mData.get('message_other')) + Number(mData.get('message_previous_optin_to_all'));
      
      stu += totalUsers;
      stmu += msgedUsers;
      sca += consentAll;
      sra += rejectAll;
      slio += legitimateIntObj;
      sb += bounces;
      so += other;
    });
  
    partiallyRemovedData
      .filter((m) => m.get('message_id') != undefined && m.get('message_id') == messageId)
      .groupBy((dataPoint) => dataPoint.get('ymd') + '-' + dataPoint.get('message_id'))
      .map((e, j) => {
        const uniqueMessageIdsForMsgedUsers = new Set();
        const calTotalUsers = e.map((eData) => Number(pvUsersDataDaily.find((pvuData) => pvuData.get('ymd') == eData.get('ymd') && pvuData.get('site_id') == eData.get('site_id')).get('unique_users'))).reduce((prev, current) => prev + current) || 0;
        const calTotalMsgedUsers = mapReduceSum(
          e.filter((mi) => {
            const messageId = mi.get('message_id');
            if (!uniqueMessageIdsForMsgedUsers.has(messageId)) {
              uniqueMessageIdsForMsgedUsers.add(messageId);
              return true;
            }
            return false;
          }),
          'messaged_users'
        ) || 0;
        const calConsentAll = mapReduceSum(e, 'message_opt_in_all') || 0;
        const calRejectAll = mapReduceSum(e, 'message_opt_out_all') || 0;
        const calLegitimateInterestObjection = mapReduceSum(e, 'message_li_only') || 0;
        const calConsentWOZeroMessagedUsers = e.filter((zmu) => Number(zmu.get('messaged_users') > 0));
        const calBounces = calTotalMsgedUsers - (mapReduceSum(calConsentWOZeroMessagedUsers, 'adjusted_actions') || 0);
        const calOther = mapReduceSum(e, 'message_other', 'message_previous_optin_to_all');

        expandableTableData = expandableTableData.push(
          Map({
            date: e.get(0).get('ymd'),
            criteriaId: e.get(0).get('ymd'),
            key: j,
            messageid: Number(messageId),
            messageName: trimQuotes(e.get(0).get('msg_name')),
            totalUsers: calTotalUsers,
            totalMsgs: calTotalMsgedUsers,
            totalMsgsPer: divideByZeroErrChk(calTotalMsgedUsers, calTotalUsers).toFixed(2) + '%',
            consentAll: calConsentAll,
            consentAllPer: divideByZeroErrChk(calConsentAll, calTotalMsgedUsers).toFixed(2) + '%',
            rejectAll: calRejectAll,
            rejectAllPer: divideByZeroErrChk(calRejectAll, calTotalMsgedUsers).toFixed(2) + '%',
            legitimateIntObj: calLegitimateInterestObjection,
            legitimateIntObjPer: divideByZeroErrChk(calLegitimateInterestObjection, calTotalMsgedUsers).toFixed(2) + '%',
            bounces: calBounces > 0 ? calBounces : 0,
            bouncesPer: divideByZeroErrChk(calBounces, calTotalMsgedUsers).toFixed(2) + '%',
            other: calOther,
            otherPer: divideByZeroErrChk(calOther, calTotalMsgedUsers).toFixed(2) + '%',
            partitionName: '',
            siteId: e.get(0).get('site_id')
          })
        );
      });
    expandableTableData = expandableTableData.sort((a, b) => moment(b.get('date'), 'YYYY-MM-DD').valueOf() - moment(a.get('date'), 'YYYY-MM-DD').valueOf());
    aggMessageTableData = aggMessageTableData.push(
       Map({
          key: key,
          messageid: Number(messageId),
          messageName: trimQuotes(msgData.get(0).get('msg_name')),
          totalUsers: stu,
          totalMsgs: stmu,
          totalMsgsPer: divideByZeroErrChk(stmu, stu).toFixed(2) + '%',
          consentAll: sca,
          consentAllPer: divideByZeroErrChk(sca, stmu).toFixed(2) + '%',
          rejectAll: sra,
          rejectAllPer: divideByZeroErrChk(sra, stmu).toFixed(2) + '%',
          legitimateIntObj: slio,
          legitimateIntObjPer: divideByZeroErrChk(slio, stmu).toFixed(2) + '%',
          bounces: sb > 0? sb : 0,
          bouncesPer: divideByZeroErrChk(sb, stmu).toFixed(2) + '%',
          other: so,
          otherPer: divideByZeroErrChk(so, stmu).toFixed(2) + '%',
          partitionName: '',
          siteId: msgData.get(0).get('site_id'),
          dataArr: expandableTableData.map((e) => MessageTableToRecord(e)),
       })
    );
    key++;
  });

  return Map({
    aggMessageTableData: aggMessageTableData,
    aggTotalUsers: aggTotalUsers,
    aggTotalMsgs: aggTotalMsgs,
    aggConsentAll: aggConsentAll,
    aggRejectAll: aggRejectAll,
    aggLegitimateIntObj: aggLegitimateIntObj,
    aggBounces: aggBounces,
    aggOther: aggOther,
  });
}

function messageActionQuarterlyGraph(groupByWeekly, pvUsersDataDaily, index){
  let messagePerformanceList = List();
  let aggMsgGraphData = List();
  let graphKey = 0;

  groupByWeekly.map((item, i) => {
    const daywiseMappedData = item.groupBy((dataPoint) => dataPoint.get('ymd'));
    const date = item.get(0).get('ymd');

    let stu = 0;
    let stmu = 0;
    let sca = 0;
    let sra = 0;
    let slio = 0;
    let sb = 0;
    let so = 0;

    daywiseMappedData.map((dma, ymd) => {

        const sitewiseMappedData = dma.groupBy((dataPoint) => dataPoint.get('site_id'));
        const date = ymd;

        sitewiseMappedData.map((sma, site) => {
           const sitewiseUniqueUsers = Number(pvUsersDataDaily.find((pvuData) => pvuData.get('ymd') == ymd && pvuData.get('site_id') == site).get('unique_users'));
           const filteredMessages = sma.filter((msg, index, self) => {
            const isUniqueMessageId = self.findIndex(m => m.get('message_id') === msg.get('message_id')) === index;
            return  isUniqueMessageId;
           });
           const msgedUsersSitewise = mapReduceSum(filteredMessages, 'messaged_users') || 0;
           const rmZeroMsgedUsers = sma.filter((rmu) => Number(rmu.get('messaged_users') > 0));
           const sstmu = msgedUsersSitewise;
           const ssca = mapReduceSum(sma, 'message_opt_in_all') || 0;
           const ssra = mapReduceSum(sma, 'message_opt_out_all') || 0;
           const sslio = mapReduceSum(sma, 'message_li_only') || 0;
           const ssb = msgedUsersSitewise - (mapReduceSum(rmZeroMsgedUsers, 'adjusted_actions') || 0);
           const sso = (mapReduceSum(sma, 'message_other') || 0) + (mapReduceSum(sma, 'message_previous_optin_to_all') || 0);

           stu += sitewiseUniqueUsers;
           stmu += sstmu;
           sca += ssca;
           sra += ssra;
           slio += sslio;
           sb += ssb;
           so += sso;
        });
        graphKey++;
    });
       aggMsgGraphData = aggMsgGraphData.push(
          Map({
            key: graphKey,
            fancyDate: 'Week ' + i,
            date: date,
            ['totalUsers' + index]: stu,
            ['totalMsgs' + index]: divideByZeroErrChk(stmu, stu),
            ['consentAll' + index]: divideByZeroErrChk(sca, stmu),
            ['rejectAll' + index]: divideByZeroErrChk(sra, stmu),
            ['legitimateintobj' + index]: divideByZeroErrChk(slio, stmu),
            ['bounces' + index]: divideByZeroErrChk(sb, stmu),
            ['other' + index]: divideByZeroErrChk(so, stmu),
            ['siteIds' + index]: [...new Set(item.map((entry) => entry.get('message_id') ? [entry.get('site_id')] : []).reduce((prev, current) => prev.concat(current)))],
           })
        );
  });

  return Map({ messagePerformanceList, aggMsgGraphData });
}

function messageActionsWeeklyMonthlyGraph(groupByOrderedMap, pvUsersDataDaily, index){
  let messagePerformanceList = List();
  let aggMsgGraphData = List();
  let graphKey = 0;

  groupByOrderedMap.map((item, i) => {
    const date = i;
    graphKey++;

    const groupedToSitewise = item.groupBy((dataPoint) => dataPoint.get('site_id'));

    const propertyIds = groupedToSitewise.keySeq().toArray();

    let stmu = 0;
    let sca = 0;
    let sra = 0;
    let slio = 0;
    let sb = 0;
    let so = 0;
    groupedToSitewise.map((sma, site) => {
        const sitewiseUniqueUsers = Number(pvUsersDataDaily.find((pvuData) => pvuData.get('ymd') == i && pvuData.get('site_id') == site).get('unique_users'));
        const filteredMessages = sma.filter((msg, index, self) => {
          const isUniqueMessageId = self.findIndex(m => m.get('message_id') === msg.get('message_id')) === index;
          return  isUniqueMessageId;
         });
        const msgedUsersSitewise = mapReduceSum(filteredMessages, 'messaged_users') || 0;
        const rmZeroMsgedUsers = sma.filter((rmu) => Number(rmu.get('messaged_users') > 0));
        const sstmu = msgedUsersSitewise;
        const ssca = mapReduceSum(sma, 'message_opt_in_all') || 0;
        const ssra = mapReduceSum(sma, 'message_opt_out_all') || 0;
        const sslio = mapReduceSum(sma, 'message_li_only') || 0;
        const ssb = msgedUsersSitewise - (mapReduceSum(rmZeroMsgedUsers, 'adjusted_actions') || 0);
        const sso = (mapReduceSum(sma, 'message_other') || 0) + (mapReduceSum(sma, 'message_previous_optin_to_all') || 0);

        stmu += divideByZeroErrChk(sstmu, sitewiseUniqueUsers);
        sca += divideByZeroErrChk(ssca, sstmu);
        sra += divideByZeroErrChk(ssra, sstmu);
        slio += divideByZeroErrChk(sslio, sstmu);
        sb += divideByZeroErrChk(ssb, sstmu);
        so += divideByZeroErrChk(sso, sstmu);
    });

    let stu = propertyIds.map((p) => pvUsersDataDaily.find((pvuData) => pvuData.get('ymd') == i && pvuData.get('site_id') == p).get('unique_users')).map(Number).reduce((prev, current) => prev + current);
    const siteCount = groupedToSitewise.size;
    stmu = stmu/siteCount;
    sca = sca/siteCount;
    sra = sra/siteCount;
    slio = slio/siteCount;
    sb = sb/siteCount;
    so = so/siteCount;

    aggMsgGraphData = aggMsgGraphData.push(
      Map({
        key: graphKey,
        fancyDate: moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalUsers' + index]: stu,
        ['totalMsgs' + index]: stmu,
        ['consentAll' + index]: sca,
        ['rejectAll' + index]: sra,
        ['legitimateintobj' + index]: slio,
        ['bounces' + index]: sb,
        ['other' + index]: so,
        ['siteIds' + index]: [...new Set(item.map((entry) => entry.get('message_id') ? [entry.get('site_id')] : []).reduce((prev, current) => prev.concat(current)))],
      })
    );
  });

  return Map({ aggMsgGraphData });
}

function getMessagePerformance(daywiseMappedData){
   let messagePerformanceList = List();

   daywiseMappedData.map((dma, ymd) => {
      let messageObj = Map({ fancyDate: moment(ymd, 'YYYY-MM-DD').format('MMM Do'), date: ymd });
      dma.map((m) => {
        const sumConsentAll = Number(m.get('message_opt_in_all')) || 0;
        const messagedUsers = Number(m.get('messaged_users')) || 0;
        messageObj = messageObj.set(
          m.get('message_id').toString(),
          divideByZeroErrChk(sumConsentAll, messagedUsers) || 0
        );
      });
    messagePerformanceList = messagePerformanceList.push(messageObj);
   });

   return messagePerformanceList;
}

async function aggregateMessageData(
  data,
  rangeSelect,
  formattedData,
  index,
  pvUsersData
) {
  let groupedByDatesOrderedMap;
  let auxData;
  let datePicker = rangeSelect.get('datePicker');
  let aggMsgTableData;

  const pvUsersDataDaily = pvUsersData.get(index).get('messgeActionsDaily');
  const pvUsersDataWeeklyMonthly = pvUsersData.get(index).get('messagedActionsSitewise');

  let partiallyRemovedData = data.get('results').filter(msgData => msgData.get('message_id') && msgData.get('message_opt_in_all'));
  let auxUrl = datePicker === DATEPICKERS.quarter ? permissionsSvsBase + '/reports/tcfv2/dashboard-v2-messages/quarterly' : datePicker === DATEPICKERS.week ? permissionsSvsBase + '/reports/tcfv2/dashboard-v2-messages/weekly' : permissionsSvsBase + '/reports/tcfv2/dashboard-v2-messages/monthly';

  const quartData = partiallyRemovedData
    .filter((a) => a.get('message_id') != undefined)
    .map((dataPoint) => dataPoint.set('week', moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()))
    .groupBy((dataPoint) => dataPoint.get('week'));

  groupedByDatesOrderedMap = partiallyRemovedData
    .filter((a) => a.get('message_id') != undefined)
    .groupBy((dataPoint) => dataPoint.get('ymd'));

  const graphData = datePicker === DATEPICKERS.quarter ? messageActionQuarterlyGraph(quartData, pvUsersDataDaily, index) : messageActionsWeeklyMonthlyGraph(groupedByDatesOrderedMap, pvUsersDataDaily, index);

  const isIncompleteDate = isIncompleteDateRange(datePicker, formattedData.endDate);
  const isDailyMappeddData = isIncompleteDate;
  auxData =
    isDailyMappeddData
      ? partiallyRemovedData
      : await fetch(auxUrl, { method: 'post', credentials: 'include',  headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formattedData) })
          .then(checkStatus)
          .then((resp) => resp.json())
          .then((resp) => fromJS(resp.results));

  const tableData = isDailyMappeddData ? messageActionsIncompleteAggWindow(auxData, pvUsersDataDaily, partiallyRemovedData) : messageActionsCompleteAggWindow(auxData, pvUsersDataWeeklyMonthly, pvUsersDataDaily, partiallyRemovedData)

  aggMsgTableData = Map({
    key: index,
    messageid: 'Criteria ' + Number(index + 1),
    messageName: '',
    totalUsers: tableData.get('aggTotalUsers'),
    totalMsgs: tableData.get('aggTotalMsgs'),
    totalMsgsPer: divideByZeroErrChk(tableData.get('aggTotalMsgs'), tableData.get('aggTotalUsers')),
    consentAll: tableData.get('aggConsentAll'),
    consentAllPer: divideByZeroErrChk(tableData.get('aggConsentAll'), tableData.get('aggTotalMsgs')),
    rejectAll: tableData.get('aggRejectAll'),
    rejectAllPer: divideByZeroErrChk(tableData.get('aggRejectAll'), tableData.get('aggTotalMsgs')),
    legitimateIntObj: tableData.get('aggLegitimateIntObj'),
    legitimateIntObjPer: divideByZeroErrChk(tableData.get('aggLegitimateIntObj'), tableData.get('aggTotalMsgs')),
    bounces: tableData.get('aggBounces'),
    bouncesPer: divideByZeroErrChk(tableData.get('aggBounces'), tableData.get('aggTotalMsgs')),
    other: tableData.get('aggOther'),
    otherPer: divideByZeroErrChk(tableData.get('aggOther'), tableData.get('aggTotalMsgs')),
    dataArr: tableData.get('aggMessageTableData').map((s) => MessageTableToRecord(s)),
  });

  const msgPerformance = getMessagePerformance(groupedByDatesOrderedMap);

  return Map({
    messageData: MessageTableToRecord(aggMsgTableData),
    messageGraph: graphData.get('aggMsgGraphData'),
    messagePerformanceList: msgPerformance,
  });
}

function MessageTableToRecord(data) {
  return MessageTableReportV2Record({
    key: data.get('key'),
    criteriaId: data.get('criteriaId'),
    date: data.get('date') ? data.get('date') : '',
    messageid: data.get('messageid'),
    messagename: data.get('messageName'),
    partitionname: data.get('partitionName'),
    totalusers: data.get('totalUsers'),
    totalmsgs: data.get('totalMsgs'),
    totalmsgsper: data.get('totalMsgsPer'),
    consentall: data.get('consentAll'),
    consentallper: data.get('consentAllPer'),
    rejectall: data.get('rejectAll'),
    rejectallper: data.get('rejectAllPer'),
    legitimateintobj: data.get('legitimateIntObj') || 0,
    legitimateintobjper: data.get('legitimateIntObjPer') || 0.00,
    bounces: data.get('bounces'),
    bouncesper: data.get('bouncesPer'),
    other: data.get('other'),
    otherper: data.get('otherPer'),
    siteid: data.get('siteId') || null,
    aggData: data.get('dataArr') ? data.get('dataArr') : List(),
  });
}

function trimQuotes(stringWithDoubleQuotes){
  const str = stringWithDoubleQuotes ? stringWithDoubleQuotes : "";
  let string = str[0] && str[0] == '"' ? str.substr(1) : str;
  const strinLength = string.length;
  const chkEmptyStr = string[strinLength > 0 ? strinLength-1 : 0]
  string = chkEmptyStr && chkEmptyStr == '"' ? string.slice(0, -1) : str;
  return string;
}
