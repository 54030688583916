import React, { useContext, useRef, useState, Fragment } from 'react';
import { Collapse } from 'antd';
import { SettingsMap } from './SettingsMap';
import ColorPicker from './Components/ColorPicker/ColorPicker';
import FourOptions from './Components/FourOptions/FourOptions';
import Direction from './Components/Direction/Direction';
import Location from './Components/Location/Location';
import Align from './Components/Align/Align';
import FlexAlign from './Components/FlexAlign/FlexAlign';
import FlexJustify from './Components/FlexJustify/FlexJustify';
import Pixels from './Components/Pixels/Pixels';
import Text from './Components/Text/Text';
import TextLanguage from './Components/TextLanguage/TextLanguage';
import TextLanguageLimited from './Components/TextLanguageLimited/TextLanguageLimited';
import Input from './Components/Input/Input';
import InputLanguage from './Components/InputLanguage';
import Language from './Components/Language';
import InputNumber from './Components/InputNumber/InputNumber';
import Switch from './Components/Switch/Switch';
import Dimensions from './Components/Dimensions/Dimensions';
import ChoiceOptions from './Components/ChoiceOptions/ChoiceOptions';
import ActionOptions from './Components/ActionOptions';
import ActionOptionsIOS from './Components/ActionOptionsIOS';
import VendorList from './Components/VendorList';
import Type from './Components/Type/Type';
import ToggleType from './Components/ToggleType';
import Size from './Components/Size';
import SizeRem from './Components/SizeRem';
import Padding from './Components/Padding';
import Margin from './Components/Margin';
import BorderRadius from './Components/BorderRadius';
import ZIndex from './Components/ZIndex';
import Border from './Components/Border';
import BorderRem from './Components/BorderRem';
import Font from './Components/Font';
import FontRem from './Components/FontRem';
import Links from './Components/Links';
import Opacity from './Components/Opacity';
import SetInputOptions from './Components/SetInputOptions/SetInputOptions';
import LabelPosition from './Components/LabelPosition/LabelPosition';
import CommonDropdowns from './Components/CommonDropdowns/CommonDropdowns';
import SetCheckboxOptions from './Components/SetCheckboxOptions/SetCheckboxOptions';
import CardDetailsPreview from './Components/CardDetailsPreview';
import DefaultTab from './Components/DefaultTab';
import Navigation from './Components/Navigation';
import CategoryImagesPM from './Components/CategoryImagesPM';
import FontHeadingOptions from './Components/FontHeadingOptions/FontHeadingOptions';
import PreferencesActionOptions from './Components/PreferencesActionOptions'
import PreferencesPreviewSelect from './Components/PreferencesPreviewSelect';
import CategorySelector from './Components/CategorySelector';
import VendorSelector from './Components/VendorSelector';
import RadioSelector from './Components/RadioSelector';
import { getTextAndButtonComponents } from "../../../builderUtils";

import { SitesContext, CategoryContext, DefaultLanguageContext, IsApp, FirstParentSettingsContext, VendorListsContext } from '../../../../contexts';
import { usePrivacyManagerList } from '../../../../../../hooks';
import { CurrentComponentIndexContext, CurrentMessageViewContext } from '../../../../../contexts';
import { Alert } from '../../../../../../styleguide';
import { InfoCircleOutlined } from '@ant-design/icons';

const matching = { ActionOptionsIOS, ColorPicker, FourOptions, Direction, Location, Align, FlexAlign, FlexJustify, Size, SizeRem, Padding, Margin, BorderRadius, ZIndex, Text, Input, InputNumber, Switch, Pixels, Font, FontRem, Links, Opacity, Border, BorderRem, Dimensions, ChoiceOptions, Type, ActionOptions, VendorList, SetInputOptions, LabelPosition, CommonDropdowns, SetCheckboxOptions, ToggleType, InputLanguage, Language, TextLanguage, DefaultTab, Navigation, CardDetailsPreview, CategoryImagesPM, FontHeadingOptions, TextLanguageLimited, PreferencesActionOptions, PreferencesPreviewSelect, VendorSelector, CategorySelector, RadioSelector };
const Panel = Collapse.Panel;

const BannedPanels = {
  'ios': ['Text-links', 'Image-link']
};

const Settings = ({
  current: component,
  updateSetting,
  updateBulkSetting,
  siteId,
  accountId,
  features,
  messageCategory,
  messageSubcategory,
  pmMessages,
  path = [0],
  subviewIndex,
  isGlobalSettings,
  components,
  editing,
  preferencesLists,
  ...rest
}) => {
  const category = useContext(CategoryContext);
  const currentComponentIndex = useContext(CurrentComponentIndexContext);
  const { isSiteGroup, sites, siteGroupId } = useContext(SitesContext);
  const { defaultLanguage, updateDefaultLanguage, previewLanguage, supportedLanguages, updateSupportedLanguages, updateComponentText, translationComponent, updateComponentTranslation, updatePreviewLanguage, highlightComponent } = useContext(DefaultLanguageContext);
  const { currentView, pmData } = useContext(CurrentMessageViewContext);

  const isApp = useContext(IsApp);
  const pmList = usePrivacyManagerList(accountId, siteId);

  const fpSettings = useContext(FirstParentSettingsContext);
  const vendorLists = useContext(VendorListsContext);

  // This is hacky, but b/c antd wraps rc-collapse, we don't have access to pop open a panel (setActiveKey)
  // instead we control the activePanel prop, which we set when we have errors, and update on change
  // Preferences category should always be open
  const activePanelsRef = useRef(['settings-panel-category'])
  const [activePanels, setActivePanels] = useState(null);

  const openAdditionalPanel = (newPanelKey) => {
    if (!activePanelsRef.current.includes(newPanelKey)) {
      activePanelsRef.current = [...activePanelsRef.current, newPanelKey];
      setActivePanels(Date.now());
    }
  };

  const errorPanels = [];
  const errorPanelsRef = useRef([]);
  const collapseOnChange = (v) => {
    if (activePanelsRef.current != v) {
      activePanelsRef.current = v;
      // we don't really use this state var, but we need to re-render 
      setActivePanels(Date.now());
    }
  };

  const getDisplayName = (componentName) => {
    if (componentName === 'PreferencesCenter') {
      return 'Category';
    }
    return componentName
  }

  const index = 0;

  const ComponentSettings = new (SettingsMap[component.type + 'Settings'])({ /*fieldName: component.settings.name,*/ ...component.settings }, true);
  const ComponentTypes = SettingsMap[component.type + 'Types'];

  const panels = Object.entries(ComponentTypes)
    .filter(([typeKey]) => {
      if (typeKey === 'GPC Settings') {
        if (messageSubcategory === 'usnat_notice') return false;
        const vendorList = vendorLists.find(vl => vl.id === fpSettings.vendorList);
        if (!vendorList || !vendorList.respectGPC) {
          return false;
        }
      }
      if (typeKey === 'Toggle settings' && messageSubcategory === 'usnat_notice') return false;
      if (typeKey === 'Privacy Manager' && messageSubcategory === 'us_pm') return false;
      if (typeKey === 'privacy_manager' && !['notice-tcf-v2', 'notice_non_iab', 'notice', 'usnat_notice'].includes(messageSubcategory) && !['ccpa', 'gdpr'].includes(category)) {
        return false;
      } else if (messageSubcategory !== 'privacy-manager-ott' || component.type !== 'OTTCards') {
        return true;
      }
      if (currentView === 'card_details') {
        const { detailsPreviewType } = ComponentSettings;
        return typeKey.includes(`${detailsPreviewType} details`) || typeKey === 'details preview settings';
      }

      return typeKey.includes(currentView);
    })
    .map(([typeKey, type]) => {
      const forceRender = [
        'privacy_manager',
        'Privacy Manager Settings',
        'Privacy Manager OTT Settings',
        'OTT Global Settings',
        'Native OTT Global Settings',
        'Message Settings',
      ].includes(typeKey);

      const panelKey = 'settings-panel-' + typeKey;
      const options = Object.entries(type).map(([settingKey, setting]) => {

        const CustomComponent = matching[setting.type];

        if (ComponentSettings._errors && ComponentSettings._errors[settingKey] && ComponentSettings._errors[settingKey].length) {
          errorPanels.push(panelKey);
        }

        const isAppleMessaging = settingKey.includes("appleDataBroker") && !features.includes("apple_messaging");
        const isPureModel = settingKey === 'usePurModel' && !features.includes('pur_model');
        if (accountId != 22 && (isPureModel || isAppleMessaging)) {
          return <Fragment />
        };
        return (
          <li key={index + component.type + settingKey}>
            <CustomComponent
              value={ComponentSettings[settingKey]}
              allSettings={ComponentSettings}
              settingKey={settingKey}
              settingsTitle={setting.title}
              updateSetting={updateSetting}
              updateBulkSetting={updateBulkSetting}
              siteId={siteId}
              accountId={accountId}
              features={features}
              isSiteGroup={isSiteGroup}
              sites={sites}
              siteGroupId={siteGroupId}
              category={category}
              pmList={pmList}
              disabled={component.disableInBuilder}
              customOptions={setting.customOptions}
              messageSubcategory={messageSubcategory}
              messageCategory={messageCategory}
              pmMessages={pmMessages}
              defaultLanguage={defaultLanguage}
              updateDefaultLanguage={updateDefaultLanguage}
              supportedLanguages={supportedLanguages}
              updateSupportedLanguages={updateSupportedLanguages}
              updateComponentText={updateComponentText}
              translationComponent={translationComponent}
              updateComponentTranslation={updateComponentTranslation}
              currentComponentIndex={currentComponentIndex}
              pmData={pmData}
              subviewIndex={subviewIndex}
              validate={setting.validate}
              isApp={isApp}
              components={components}
              updatePreviewLanguage={updatePreviewLanguage}
              previewLanguage={previewLanguage}
              highlightComponent={highlightComponent}
              isGlobalSettings={isGlobalSettings} //for ott messages
              isNativeButton={component.type === 'NativeButton'}
              isButton={['PMButton', 'Button'].includes(component.type)}
              preferencesLists={preferencesLists}
              path={path}
              {...rest}
              openAdditionalPanel={openAdditionalPanel}
            />
          </li>
        );
      });

      const disabledSettings = ['content', 'fonts', 'selection controls', 'control color', 'spacing', 'checkboxes'];
      const disable = messageCategory === 'preferences' && ComponentSettings.categories && ComponentSettings.categories.hide && disabledSettings.includes(typeKey);

      const adjustedKey = typeKey.replace('_', ' ');
      const shouldShow = BannedPanels[messageSubcategory] && BannedPanels[messageSubcategory].includes(component.type + '-' + adjustedKey) ? false : true;
      return shouldShow ? (
        <Panel header={adjustedKey} key={panelKey} forceRender={forceRender} collapsible={disable ? 'disabled' : 'header'}>
          <ul className="settings-form">{options}</ul>
        </Panel>
      ) : null;
    });

  const collapseKey = 'settings-key-' + path.reduce((p, c) => (p + c.index), '');

  if (errorPanels.length) {
    if (JSON.stringify(errorPanelsRef.current) !== JSON.stringify(errorPanels)) {
      // If we have (new) errored panels, use those
      activePanelsRef.current = errorPanels;
      errorPanelsRef.current = errorPanels;
    }
  } else {
    // Reset our error ref if they don't exist or have been cleared
    errorPanelsRef.current = [];
  }

  const hasDuplicates = (arr, value) => {
    const hasDuplicate = arr.some((item, index) => item === value && arr.indexOf(value) !== index);
    return hasDuplicate
  }


  const shouldShowNameInput = (messageSubcategory === 'native-ott' && component.type === 'NativeView') || messageSubcategory !== 'native-ott';
  const nameFieldValidation = (value) => {
    const componentNames = getTextAndButtonComponents(components);
    const hasDuplicate = hasDuplicates(componentNames, value);

    if (value.trim() === '') return ['Name field can not be empty'];
    if (hasDuplicate && !editing) return ['Name already exists'];
    return []
  }

  let vendorCountAlert = null;
  const isAccountPartOfWave1 = features.includes('tcf2.2');
  if (messageSubcategory === 'notice-tcf-v2' && isAccountPartOfWave1) {
    if (!JSON.stringify(components).includes('$$!VC-ALL!$$') && !JSON.stringify(components).includes('$$!VC-IAB!$$')) {
      vendorCountAlert = <Alert showIcon icon={<InfoCircleOutlined />} type="warning" message={<>Adding <b>Vendor Count</b> in the description text is mandatory before saving the message.</>} style={{ margin: '10px 10px' }} />
    }
  }

  return (
    <React.Fragment>
      {vendorCountAlert}
      {!isGlobalSettings && (
        <div className="top-form">
          <ul className="settings-form">
            <li style={{ padding: '0 15px 15px 15px' }}>
              {shouldShowNameInput && (
                <Input
                  value={getDisplayName(component.name)}
                  settingKey="componentName"
                  settingsTitle="Name"
                  updateSetting={updateSetting}
                  validate={nameFieldValidation}
                />)}
              {messageSubcategory !== 'native-ott' && (
                <div style={{ marginTop: '10px' }}>
                  <Input
                    value={component.handle}
                    settingKey="componentHandle"
                    settingsTitle="CSS Handle"
                    updateSetting={updateSetting}
                  />
                </div>
              )}
            </li>
          </ul>
        </div>)
      }
      <Collapse
        onChange={collapseOnChange}
        key={collapseKey}
        className="settings-collapse"
        activeKey={activePanelsRef.current}
      >
        {panels}
      </Collapse>
      {component.type === "Stacks" &&
        <div className="language-settings"><b>Note:</b> The language settings for this component can be managed under the vendor management section</div>}
    </React.Fragment >
  );
}

export default Settings;
