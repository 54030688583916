import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {  message, RenderLimitedChips } from "../../../styleguide";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import DismissErrorModal from "../../common/DismissErrorModal";
import EditModal from "./EditModal";
import { handleServerSideErrors, renderCannotUpdateRegulationError } from "../vendor_list_v2/helper";
import { List, Map} from "immutable";
import { getSiteGroups, getAllSites } from '../../../actions/site_actions.js';
import moment from "moment";
import { Tag, Button, Modal, Switch,  Flex, Space, Typography, theme, Divider, Row, Col, Tooltip } from "antd";
import { activateDeactivatePreferenceConfiguration, clearPreferenceConfigurationError, getAllLatestLegalDocuments, getAllPreferenceConfiguration, getAllVendorConnections, getAllVendors, getPreferenceCampaignTypes, getPreferenceConfiguration, updatePreferenceConfiguration } from "../../../actions/preferences_actions.js";
import { getParameterByName, hasFeature } from "../../utils.js";
import Loading from "../../common/Loading.js.jsx";
import { browserHistory } from "react-router";
import CategoriesTable from "./Screens/AddCategories/CategoriesTable.jsx";
import { renderInfoTip } from "../us_privacy_regulation/helper.js";

const { useToken } = theme;

const { Title, Text } = Typography;

const EditConfigurations = (props) => {
  const [configuration, setConfiguration] = useState(null);
  const [editEntity, setEditEntity] = useState(null);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showActivateErrorModal, setShowActivateErrorModal] = useState(false)
  const [serverErrors, setServerErrors] = useState(List([]))
  const [searchValue, setSearchValue] = useState('');
  const [defaultTabOnEditModal, setShowDefaultTabOnEditModal] = useState('');

  const [modal, contextHolder] = Modal.useModal();
  
    //server side error
   const indexSiteNameMap = useMemo(() => props.sites.toJS().reduce((map, obj) => (map[obj.id] = obj.domain, map), {}), [props.sites]);
    useEffect(() => {
      if(props.preferenceConfigurationServerSideError){
        const serverErrors = handleServerSideErrors(props.preferenceConfigurationServerSideError, indexSiteNameMap);
        if(serverErrors){
          setServerErrors(serverErrors)
          setShowActivateErrorModal(true);
        } else {
          message.error("Failed to save configuration. Something went wrong");
        }
      }
      return ()=>{
        props.clearPreferenceConfigurationError();
      }
    },[props.preferenceConfigurationServerSideError]);

  function saveConfiguration(configuration) {
    if(configuration.propertyIds.size == 0 && configuration.isActive) {
      Modal.error({
        title: "Failed to save Configuration",
        content: "Active Configurations must have at least one property"
      })
    } else {
      props.updatePreferenceConfiguration(configuration).then((resp) => {
        if(resp?.get('id')){
          setConfiguration(resp);
          message.success(
            <>
              Configuration <b>{resp.get('name')}</b> has been updated
            </>
          );
          setEditEntity(null);
        }
      }).catch((err) => {
        message.error(err);
      })
    }
  }

  const handleDeactivate = () => {
    if( !configuration.isActive && configuration?.propertyIds.size < 1) {
      setShowActivateErrorModal(true);
    } else {
      props.activateDeactivatePreferenceConfiguration(configuration?.toJS()).then((resp) => {
        if(resp?.get('id')){
          message.success(<>Configuration <b>{configuration?.name}</b> is successfully {configuration?.isActive?'deactivated':'activated'}</>)
          setConfiguration(resp);
        }
      })
    }
    setShowDeactivateModal(false)
  }

  const managePreferencesModalOnLoad = (defaultTab) => {
    setEditEntity("manage_preferences");
    setShowDefaultTabOnEditModal(defaultTab);
  }

  const configurationId = getParameterByName("configurationId");

  useEffect(() => {
    (async () => {
      try {
        if (props.currentUser) {
          props.getSiteGroups(props.currentUser);
          await props.getAllSites();
          props.getAllVendorConnections();
          props.getAllVendors();
          props.getAllPreferenceConfiguration(props.currentUser?.accountId);
          props.getAllLatestLegalDocuments();
          props.getPreferenceCampaignTypes();
          let preferenceConfiguration = await props.getPreferenceConfiguration(configurationId, props.currentUser.accountId);
          if(preferenceConfiguration.get('id')){
            setConfiguration(preferenceConfiguration);
          }
        }
      } catch(err) {
        console.log(err);
      }
    })();
  }, [props.currentUser]);

  const propertiesChips = configuration?.propertyIds?.map(id => <Tag>{props.sitesNameMap.get(String(id))}</Tag>);

  let dataSource = searchValue.trim().length ?  configuration?.categories.filter(category => category.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : configuration?.categories
  dataSource = dataSource?.filter(cat => !Boolean(cat.dateDeleted));
  const legalTransactionCats = dataSource?.filter((cat) => cat.type === "LEGAL-TRANSACTION");
  const marketingPreferencesCats = dataSource?.filter((cat) => cat.type === "MARKETING");

  const hasMarkertingPreferenceAccess = hasFeature(props.currentUser, "marketing_preferences");
  const hasLegalPreferenceAccess = hasFeature(props.currentUser, "legal_preferences");

  const { token } = useToken();

  return configuration && !props.pendingRequestsMap.get('savePending') ? (
    <>
    <div className="us-privacy-regulation-edit">
      <Row gutter={[0, token.marginMD]}>
        <Col span={24}>
          <Flex justify="space-between">
            <Space size={token.marginXXS} >
              <Button icon={<LeftOutlined />} type="text" size="large" onClick={() => {browserHistory.push("/preferences/")}}/>
              <Title style={{marginLeft: token.marginXS, marginBottom: 0}} level={4}>{configuration.name}</Title>
              <Button type="link" icon={<EditOutlined/>} onClick={() => setEditEntity("name")}/>
            </Space>
            <Space size={token.marginSM}>
              <Switch checked={configuration.isActive} onClick={() => setShowDeactivateModal(true)}/>
              <Text>{configuration?.isActive ? "Active" : "Inactive"}</Text>
              <Tooltip title="Language & Translations">
                <Button icon={<img src="/svg_icons/language-icon.svg"/>} onClick={() => setEditEntity("language")}/>
              </Tooltip>
            </Space>
          </Flex>
        </Col>
        <Col span={24}>
          <div className="edit-preferences-container">
            <Space direction="vertical" size={token.marginSM}>
              <Flex justify="space-between">
                <Typography.Title level={5} style={{marginBottom: 0}}>Property ({configuration.propertyIds.size})</Typography.Title>
                <Button size={"small"}  icon={<EditOutlined/>} type="link" onClick={() => setEditEntity("properties")}/>
              </Flex>
              <Divider/>
              <RenderLimitedChips chips={propertiesChips?.toJS()} cutOff={15}/>
            </Space>
          </div>
        </Col>
        <Col span={24}>
          <div>
            <Typography.Title level={5} style={{marginBottom: token.margin}}>Categories</Typography.Title>
            {hasLegalPreferenceAccess ? (
              <div className="edit-preferences-container">
                <Space direction="vertical" size={token.marginSM}>
                  <Flex justify="space-between">
                    <Title style={{marginBottom: 0}} level={5}>Legal Preferences {renderInfoTip("Legal preference categories enable you to provide notification of and/or collect agreements to legal documents (configured in the Legal Document Library) used by your organization")}</Title>
                    <Space size={token.marginSM}>
                      <Button type="primary" onClick={() => setEditEntity("manage_transactions")}>Manage Categories</Button>
                    </Space>
                  </Flex>
                  <CategoriesTable
                    readOnly
                    dataSource={legalTransactionCats?.toJS()}
                    columnsToDisplay={["ID", "Name", "Type", "Legal Document", "Default User State"]}
                  />
                </Space>
              </div>
            ) : null}
          </div>
        </Col>
        <Col span={24}>
          <div>
            {hasMarkertingPreferenceAccess ? (
              <div className="edit-preferences-container">
                <Space direction="vertical" size={token.marginSM}>
                <Flex justify="space-between">
                  <Title style={{marginBottom: 0}} level={5}>Marketing Preferences {renderInfoTip("Marketing preference categories are the subscriptions, topics, or other marketing preferences that are displayed to the user via your Universal Consent and Preferences message")}</Title>
                  <Space size={token.marginSM}>
                    <Button onClick={() => managePreferencesModalOnLoad("int_mapping")} disabled={!Boolean(marketingPreferencesCats.size)} title={!Boolean(marketingPreferencesCats.size) ? "No Marketing Prefernce Category added" : null}>Manage Integrations</Button>
                    <Button type="primary" onClick={() =>  managePreferencesModalOnLoad("categories")}>Manage Categories</Button>
                  </Space>
                </Flex>
                <CategoriesTable
                  readOnly
                  dataSource={marketingPreferencesCats?.toJS()}
                  columnsToDisplay={["ID", "Name", "Channels", "Default User State", "Connections"]}
                />
                </Space>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
    {editEntity ? (
      <EditModal
        title={null}
        modalWrapperClass="activate-confirmation"
        saveConfiguration={saveConfiguration}
        cancelEdit={() => setEditEntity(null)}
        configuration={configuration}
        edit={editEntity}
        sites={props.sites}
        siteGroups={props.siteGroups}
        preferenceConfigurationNamesList={props.preferenceConfigurationList?.map((con) => con.name) ?? List([]).filter( name => name != configuration.name)}
        onEditScreen
        defaultTab={defaultTabOnEditModal}
      />) : null}
    {showDeactivateModal && (<DismissErrorModal
      modalWrapperClass="operation-confirmation"
      title= {`Configuration - ${configuration?.isActive? "Deactivate":"Activate"}`}
      error={<>Are you sure you want to {configuration?.isActive? "deactivate":"activate"} the following Configuration <b>{configuration?.name}</b>&nbsp;?</>}
      isModalVisible={showDeactivateModal}
      handleCancel={() => setShowDeactivateModal(null)}
      renderOk
      okText={configuration?.isActive? "Deactivate":"Activate"}
      cancelText={"Cancel"}
      handleOk={handleDeactivate}
      primaryAction="submit"
    />)}
    {showActivateErrorModal && (<DismissErrorModal
      modalWrapperClass="activate-confirmation"
      title={`Configuration - Activate`}
      error={renderCannotUpdateRegulationError(configuration?.toJS(), serverErrors, "Configuration")}
      isModalVisible={showActivateErrorModal}
      handleCancel={() => setShowActivateErrorModal(false)}
      renderOk={false}
      okText={"OK"}
      cancelText={"Close"}
      handleOk={() => setShowActivateErrorModal(false)}
      primaryAction="submit"
    />)}
    {contextHolder}
    </>
  ) : <Loading/>;
};
const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value'])?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')),
    sitesNameMap: store.siteState.getIn(['sitesNameMap', 'value']),
    siteGroups: store.siteState
      .getIn(['siteGroups', 'value'])
      .sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      ),
    vendorConnections: store.preferencesState.getIn(["vendorConnections", "value"]),
    vendorsHash: store.preferencesState.getIn(["vendorsHash", "value"]),
    preferenceConfigurationServerSideError: store.preferencesState.getIn(["preferenceConfigurationList", "error"]),
    preferenceConfigurationList: store.preferencesState.getIn(['preferenceConfigurationList', 'value']),
 
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
      vendorConnections: store.preferencesState.getIn(["vendorConnections","pending"]),
      vendors: store.preferencesState.getIn(["vendors", "pending"]),
      savePending: store.preferencesState.get('savePending')
    }),
  };
};

export default connect(mapStateToProps, {
  getSiteGroups,
  getAllSites,
  getAllVendors,
  getPreferenceConfiguration,
  getAllPreferenceConfiguration,
  getAllVendorConnections,
  updatePreferenceConfiguration,
  activateDeactivatePreferenceConfiguration,
  clearPreferenceConfigurationError,
  getAllLatestLegalDocuments,
  getPreferenceCampaignTypes
})(EditConfigurations);
