/* eslint-disable no-plusplus */
import { InfoCircleOutlined, CloseOutlined, BlockOutlined, CommentOutlined, RetweetOutlined } from '@ant-design/icons';
import { Tooltip, Modal, Divider, Image } from 'antd';
import React, { useEffect, useRef, useMemo, useState } from 'react';
import moment from 'moment';

import cookieIcon from '../../../../assets/images/diagnose/cookie.svg';
import cookieFilledIcon from '../../../../assets/images/diagnose/cookie_filled.svg';
import localStorageIcon from '../../../../assets/images/diagnose/local_storage.svg';
import fingerprintIcon from '../../../../assets/images/diagnose/fingerprint.svg';
import cookieless from '../../../../assets/images/diagnose/cookiless.svg';
import { Button } from '../../../../styleguide';

const InfoTooltip = ({ message }) => {
  return (
    <Tooltip
      getPopupContainer={(trigger) => trigger.parentElement}
      title={() => (
        <div className="diagnose--tooltip-message">
          <span>{message}</span>
        </div>
      )}
      overlayClassName="diagnose--tooltip"
    >
      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
    </Tooltip>
  );
};

const Content = ({ data, property }) => {
  const technologiesContainerRef = useRef(null);
  const [countToShow, setCountToShow] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const onShowMore = () => setShowMore((pr) => !pr);

  const TrackingMethods = useMemo(() => {
    if (!data) {
      return <span>None</span>;
    }
    const list = [];
    if (data.is_cookie_present) {
      list.push({ icon: cookieIcon, title: 'Cookies' });
    }
    if (data.is_cookie_persistent) {
      list.push({ icon: cookieFilledIcon, title: 'Persistent cookies' });
    }
    if (data.is_local_storage) {
      list.push({ icon: localStorageIcon, title: 'Local storage' });
    }
    if (data.is_fingerprinting) {
      list.push({ icon: fingerprintIcon, title: 'Fingerprinting' });
    }
    if (data.is_pixel) {
      list.push({ icon: <BlockOutlined />, title: 'Pixels' });
    }
    if (data.is_chatbot) {
      list.push({ icon: <CommentOutlined />, title: 'Chatbot' });
    }
    if (data.is_cookieless) {
      list.push({ icon: cookieless, title: 'Cookieless IDs' });
    }
    if (data.is_session_replay) {
      list.push({ icon: <RetweetOutlined />, title: 'Session Replay' });
    }
    return list?.length ? (
      list.map((item, index) => (
        <React.Fragment key={item.title}>
          <span>
            {typeof item.icon === 'string' ? (
              <Image src={item.icon} className="tracking-method-icon" preview={false} />
            ) : (
              <span className="tracking-method-icon">{item.icon}</span>
            )}
            {item.title}
          </span>
          {index !== list.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))
    ) : (
      <span>None</span>
    );
  }, [data]);
  

  const Techonologies = useMemo(() => {
    if (!data?.technologies.length) {
      return '-';
    }
    return (
      <>
        {Array.from({ length: !showMore && countToShow ? countToShow : data?.technologies.length })?.map((_, idx) => (
          <span className="technology" key={data?.technologies[idx]} title={data?.technologies[idx]}>
            {data?.technologies[idx]}
          </span>
        ))}
        {!!countToShow && (
          <button onClick={onShowMore} type="button" className="technology wide">
            {!showMore ? '+' : '-'}
          </button>
        )}
      </>
    );
  }, [data?.map_key, data?.technologies, countToShow, showMore]);

  useEffect(() => {
    if (data && technologiesContainerRef.current) {
      let total = document.querySelector('.technologies-categorization').getBoundingClientRect().width;
      const { width: containerWidth = 0 } = technologiesContainerRef.current?.getBoundingClientRect();
      const listOfTechnologies = [...technologiesContainerRef.current?.querySelectorAll('.technology:not(.wide)')];
      if (!listOfTechnologies.length || listOfTechnologies.length === 1) return;

      let index = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of listOfTechnologies) {
        const { width = 0 } = item.getBoundingClientRect();
        if (total + width + 10 /* row-gap */ >= containerWidth - 76 /* more button */) {
          setCountToShow(index);
          break;
        } else {
          // eslint-disable-next-line no-plusplus
          index++;
          total += width + 10;
        }
      }
    }
  }, [data?.map_key, technologiesContainerRef.current]);

  return (
    <>
      <div className="section">
        <div className="section">
          <span className="section--row">
            Vendor Name:{' '}
            <span className="section--value" title={data?.vendor_name}>
              {data?.vendor_name}
            </span>
          </span>
        </div>
        <div className="section--grid">
          <span className="section--row">
            <span>
              First Seen
              <InfoTooltip
                message={
                  <>
                    <b>First seen: </b>
                    First time we identified the vendor on the selected property regardless of the date range selected.
                  </>
                }
              />
              :
            </span>
            <span className="section--value">
              {data?.first_seen ? moment(data?.first_seen).format('MMM-DD-YYYY') : ''}
            </span>
          </span>
          <span className="section--row">
            <span>
              Last Seen
              <InfoTooltip
                message={
                  <>
                    <b>Last seen: </b>
                    Last time we identified the vendor on the selected property regardless of the date range selected.
                  </>
                }
              />
              :
            </span>
            <span className="section--value">
              {data?.last_seen ? moment(data?.last_seen).format('MMM-DD-YYYY') : ''}
            </span>
          </span>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section--grid">
          <span className="section--row">
            Property Name: <span className="section--value">{property || ''}</span>
          </span>
          <span className="section--row">
            Prevalence: <span className="section--value">{Math.round(data?.relative_prevalence * 100)}%</span>
          </span>
        </div>

        <div className="section">
          <span className="section--row">
            Vendor Domain:{' '}
            <a
              href={data?.vendor_domain}
              className="section--value"
              rel="noopener noreferrer"
              title={data?.vendor_domain}
              target="_blank"
            >
              {data?.vendor_domain}
            </a>
          </span>
        </div>
        <div className="section">
          <span className="section--row">
            Privacy Policy:{' '}
            <a
              href={data?.policy_url}
              className="section--value"
              rel="noopener noreferrer"
              target="_blank"
              title={data?.vendor_domain}
            >
              {data?.policy_url}
            </a>
          </span>
        </div>
        <div className="section--grid">
          <span className="section--row" title={data?.referrer || '-'}>
            Referrer Vendor:{' '}
            <span className="section--value" title={data?.referrer || '-'}>
              {data?.referrer || '-'}
            </span>
          </span>
          <span className="section--row">
            <span>
              Referred Vendor
              <InfoTooltip
                message={
                  'Click on the download button below to get detailed information (names, URLs) of the referred vendors'
                }
              />
              :
            </span>
            <span className="section--value">{data?.referred?.length || 0}</span>
          </span>
        </div>
      </div>
      <Divider />
      <div className="section">
        <span className="section--row">
          Tracking methods detected: <span>{TrackingMethods}</span>
        </span>
        <span className={'section--row technologies full'} ref={technologiesContainerRef}>
          <span className="technologies-categorization">Technology Categorization:</span>
          {Techonologies}
        </span>
      </div>
    </>
  );
};

const VendorDetails = ({ data, property, onClose, onExport }) => {
  const [isLoading, setLoading] = useState(false);
  const handleExport = async () => {
    try {
      setLoading(true);
      await onExport(data);
    } finally {
      setLoading(false);
    }
  };

  const footer = (
    <div>
      <Button onClick={onClose}>Close</Button>
      <Button type={'primary'} loading={isLoading} onClick={handleExport}>
        Download
      </Button>
    </div>
  );

  return (
    <Modal
      wrapClassName={'avocado-modal vendor-details--modal'}
      closeIcon={<CloseOutlined />}
      title={<h4>Vendor details</h4>}
      open={!!data}
      onCancel={onClose}
      footer={footer}
      destroyOnClose
      width={850}
    >
      <Content data={data} property={property} />
    </Modal>
  );
};

export default VendorDetails;
