import { Button, Col, Flex, Input, message, Row, Space, Table, Tag, theme, Typography,Tooltip, Dropdown, Modal, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useDebouncedSearch } from "../../../../hooks.js";
import {  DownloadOutlined,  EllipsisOutlined, ExclamationCircleFilled, GlobalOutlined, UploadOutlined } from "@ant-design/icons";
import LegalDocumentManagementModal from "./LegalDocumentManagementModal.jsx";
import { downloadLegalDocument, getAllLegalDocuments, postLegalDocument, deletehLegalDocAjax, publishLegalDocAjax, updateLegalDocumentName, updateLegalDocumentValues, updateLegalDocumentVersion } from "../../../../api/preferences.js";
import dayjs from "dayjs";
import EditDocumentModal from "./EditDocumentModal.jsx";
import Loading from "../../../common/Loading.js.jsx";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";

const { Text, Paragraph } = Typography;

// Method to highlight the given substring within the text
const getHighlightedText = (text, highlight) => {
  let parts = []
  if(text) {
    parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  }
  
  return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
          <Text key={index} mark>
              {part}
          </Text>
      ) : (
          part
      )
  );
};

const LegalDocumentManagementHome = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [allLegalDocs, setAllLegalDocs] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [openUploadDocumentModal, setOpenUploadDocumentModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [docTypeGroup, setDocTypeGroup] = useState(null);
  const [documentToEdit, setDocumentToEdit] = useState(null);
  const { token } = theme.useToken();
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);
  const [modal, contextHolder] = Modal.useModal();

  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value']))

  const handleAddNew = (docTypeGroup) => {
    setOpenUploadDocumentModal(true);
    if(docTypeGroup) {
      const docGroup = allLegalDocs.find((docGroup) => docGroup.documentName === docTypeGroup?.documentName);
      setDocTypeGroup((docs) => ({
        ...docTypeGroup,
        documents: docGroup?.documents ?? docs.children
      }));
    } else {
      setDocTypeGroup(null);
    }
  }

  const handleEdit = (record) => {
    const docGroup = allLegalDocs.find((docGroup) => docGroup.documentName === record.documentName);
    setDocTypeGroup(docGroup ?? null)
    setOpenEditModal(true);
    setDocumentToEdit(record);
  }

  const handleDownload = async (record) => {
    const documentId = record._id;
    try {
      setLoading(true);
  
      const blob = await downloadLegalDocument(documentId);
  
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = record.versionName ?? `document-${documentId}`;
      document.body.appendChild(a); 
      a.click();
      a.remove(); 

      URL.revokeObjectURL(url);
  
      setLoading(false);

      message.success(<>Your document <b>{record.versionName}</b> has been successfully downloaded, Please check my downloads</>)
      
    } catch (err) {
      console.error(err); 
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  };

  function sortDocsFn(doc1, doc2) {
    if (doc1.status === "LIVE" && doc2.status !== "LIVE") return -1;
    if (doc2.status === "LIVE" && doc1.status !== "LIVE") return 1;
    
    if (doc1.status === "PREV_LIVE") return 1;
    if (doc2.status === "PREV_LIVE") return -1;

    return dayjs(doc2.dateCreated).diff(dayjs(doc1.dateCreated));
}


  async function fetchAllDocs() {
    try {
      setLoading(true);
      let docs = await getAllLegalDocuments();
      if(docs) {
        docs = docs.map((docsContainer) => ({...docsContainer,  documents: docsContainer.documents.sort(sortDocsFn).map((doc) => ({...doc, status: doc.status ?? "DRAFT"}))}))
        setAllLegalDocs(docs);
      }
      setLoading(false);
    } catch(err) {
      console.log(err)
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllDocs();
  }, []);

  function getStatusTag(status) {
    switch(status) {
      case 'LIVE':
        return (
          <Tag color="green">
            <SVG src="/svg_icons/Ellipse.svg" className="ellipse-icon green-8"/> Live
          </Tag>
        )
      case 'PREV_LIVE':
        return <Tag>Prev. Live</Tag>
      case 'DRAFT':
        return <Tag color="blue">Draft</Tag>
    }
  }

  async function handleDelete(doc) {
    const confirmation = await modal.confirm({
      title: "Document - Delete",
      content: (<>Are you sure you want to delete the following Document Version <b>{doc.versionName}</b> in {doc?.documentName} ? </>),
      okText: "Delete",
      cancelText: "Cancel",
      width: 542
    })
    if(confirmation) {
      try {
        setLoading(true);
        const resp = await deletehLegalDocAjax(doc._id);
        fetchAllDocs();
        message.success(<>Document Version <b>{doc.versionName}</b> deleted successfully</>)
      } catch(err) {
        message.error(err.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  }

  async function handlePublish(documentId) {
    const confirmation = await modal.confirm({
      title: 'Publish Document',
      icon: <ExclamationCircleFilled style={{color: token.colorPrimary}}/>,
      content: (
        <>
        Please confirm you want to publish this document.
        <ul>
          <li>Publishing documents may trigger a reconsent message if your scenarios leverage legal document conditions.</li>
          <li>Publishing a document will also update any document associations set up for your Preference messages.</li>
        </ul>
        </>
      ),
      okText: 'Publish',
      cancelText: 'Cancel',
      width: 542
    });
    if(confirmation) {
      try {
        setLoading(true);
        const resp = await publishLegalDocAjax(documentId);
        fetchAllDocs();
        message.success(<>Your document has been successfully Published</>)
      } catch(err) {
        message.error(err.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  }

  const columns = [
    {
      title: 'Doc Name',
      dataIndex: 'documentName',
      key: 'documentName',
      filters: allLegalDocs.map((doc) => ({text: doc.documentName, value: doc.documentName})),
      filterMultiple: true,
      onFilter: (value, record) => record.documentName === value,
      render: (documentName, record) =>  record.isLatest ? <Paragraph  ellipsis={{tooltip:{title: documentName}, rows: 2}}>{documentName} </Paragraph> : null
    },
    {
      title: 'Version Name',
      dataIndex: 'versionName',
      key: 'versionName',
      render: (versionName, record) => {
       return getHighlightedText(versionName, searchValue.trim());
      }
    },
    {
      title: 'Version ID',
      dataIndex: '_id',
      key: '_id',
      render: (_id, record) => {
        return <>{getHighlightedText(_id, searchValue.trim())} {/*record.isLatest ? <Tag color="blue">Latest</Tag> : null*/}</>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {text: getStatusTag("LIVE"), value: "LIVE"},
        {text: getStatusTag("DRAFT"), value: "DRAFT"},
        {text: getStatusTag("PREV_LIVE"), value: "PREV_LIVE"}
      ],
      onFilter: (value, record) => { 
        return value === record.status
      },
      render: (status, record) => getStatusTag(status),
      width: 100
    },
    {
      title: 'Published On',
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      sorter: (a, b) => dayjs(a.publishedAt).unix() - dayjs(b.publishedAt).unix(),
      render: (publishedAt, record) =>  dayjs(publishedAt).format('MMMM D, YYYY, h:mm A'),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Actions',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      // width: 150,
      align: 'center',
      render: (_, record) => {
        const items = [
          {
            key: '1',
            label: (
              <div type="text" onClick={() => handleEdit(record)}>
                Edit
              </div>
            ),
          },
          {
            key: '2',
            danger: true,
            label: (
              <div danger type="text" onClick={() => handleDelete(record)}>
                Delete
              </div>
            ),
          }
        ]
        const uploadItem =  {
          key: '2',
          label: (
            <div danger type="text" onClick={() => handleAddNew(record)}>
              Upload
            </div>
          ),
        }
        if(record.status === "DRAFT" && record.isLatest) {
          items.unshift(uploadItem);
          return (
            <Row align={"middle"}>
              <Col span={12}>
                <Button type="link" icon={<GlobalOutlined/>} iconPosition="start" onClick={() => handlePublish(record._id)} >Publish</Button>
              </Col>
              <Col span={6}>
                <Tooltip title="Download"><Button icon={<DownloadOutlined/>} type="link" onClick={() => handleDownload(record)}/> </Tooltip>
              </Col>
              <Col span={6}>
                <Dropdown
                  placement="bottomRight"
                  arrow
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <EllipsisOutlined/>
                </Dropdown>
              </Col>
            </Row>
          )
        } else if(record.status === "DRAFT" ){
          return (
            <Row align={"middle"}>
              <Col span={12}>
                <Button type="link" icon={<GlobalOutlined/>} iconPosition="start" onClick={() => handlePublish(record._id)} >Publish</Button>
              </Col>
              <Col span={6}>
                <Tooltip title="Download"><Button icon={<DownloadOutlined/>} type="link" onClick={() => handleDownload(record)}/> </Tooltip>
              </Col>
              <Col span={6}>
                <Dropdown
                  placement="bottomRight"
                  arrow
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <EllipsisOutlined/>
                </Dropdown>
              </Col>
            </Row>
          )
        } else if(record.status === "LIVE" && record.isLatest) {
          return (
            <Row align={"middle"}>
              <Col span={12}>
                <Button icon={<UploadOutlined />} type="link" iconPosition="start" onClick={() => handleAddNew(record)}>Upload</Button>
              </Col>
              <Col span={6}>
                <Tooltip title="Download"><Button icon={<DownloadOutlined/>} type="link" onClick={() => handleDownload(record)}/> </Tooltip>
              </Col>
              <Col span={6}></Col>
            </Row>
          )
        } else if(record.status === "PREV_LIVE") {
          return (
            <Row align={"middle"}>
              <Col span={12}></Col>
              <Col span={6}>
                <Tooltip title="Download"><Button icon={<DownloadOutlined/>} type="link" onClick={() => handleDownload(record)}/> </Tooltip>
              </Col>
              <Col span={6}></Col>
            </Row>
          )
        } else {
          return (
            <Row/>
          )
        }
      }
    },
  ];

  useEffect(() => {
    let rowKeys = []
    let ds = allLegalDocs?.map((docsCollectionObj) => {

      let children = docsCollectionObj.documents;
      if(!Boolean(docsCollectionObj.latestVersion)) {//latestVersion is null when none of the docs are published
        children = children?.map((c) => ({...c, noLiveDoc: true}))
      }
      children = children?.map((obj) => ({...obj, documentName: docsCollectionObj.documentName}))?.splice(1);
      // let children = docsCollectionObj.documents.filter(obj => obj.goLiveDate !== docsCollectionObj.latestVersion).map(obj => ({...obj, documentName: docsCollectionObj.documentName})).sort((a, b) => dayjs(a.goLiveDate).unix() - dayjs(b.goLiveDate).unix());
   
      if (searchValue.trim().length) {
        children = children?.filter((doc) => {
          const flag =
            doc._id?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !==
              -1 ||
            doc.versionName
              .toLowerCase()
              .indexOf(searchValue.trim().toLowerCase()) !== -1;
          if (flag) {
            rowKeys.push(docsCollectionObj.documentName);
          }
          return flag;
        });
      }
      let latestDoc = docsCollectionObj.documents?.[0];
      
      if(latestDoc) {
        return ({
          ...latestDoc,
          isLatest: true,
          documentName: docsCollectionObj.documentName,
          key: docsCollectionObj.documentName,
          children: children?.length ? children : null, 
        })
      } 
  
      return ({
        documentName: docsCollectionObj.documentName,
        key: docsCollectionObj.documentName,
        children: children?.length ? children : null, 
      })
    }).filter((docsCollectionObj) => searchValue.trim().length ? docsCollectionObj.children?.length > 0 || docsCollectionObj._id?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || docsCollectionObj.versionName.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 : true);

    setDataSource(ds);
    if(searchValue.trim().length) {
      setExpandedRowKeys(rowKeys);
    } else {
      setExpandedRowKeys([]);
    }
  },[searchValue, allLegalDocs]);

  const handleSaveDocument = async (formData, isNew) => {
    let data = new FormData()
    Object.entries(formData).forEach(([key, value]) => {
      if (value) data.append(key, value);
    });
    data.append("accountId", currentUser.accountId);
    try {
      setLoading(true);
      if(isNew) {
        const resp = await postLegalDocument(data);
        fetchAllDocs();
        message.success(<>New Document <b>{formData.documentName}</b> saved successfully</>)
      } else {
        const resp = await updateLegalDocumentVersion(data);
        fetchAllDocs();
        message.success(<>New Document Version <b>{formData.versionName}</b> uploaded successfully</>)
      }
      setOpenUploadDocumentModal(false);
      setDocTypeGroup(null);
      setLoading(false);
   
    } catch(err) {
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  }

  const handleEditDocument = async (formData, editDocName) => {
    formData.accountId = currentUser.accountId;
    try {
      setLoading(true);
      if(editDocName && formData.documentName !== documentToEdit.documentName){
        const resp1 = await updateLegalDocumentName({accountId: currentUser.accountId, documentName: formData.documentName}, documentToEdit.documentName);
        const resp2 = await updateLegalDocumentValues(formData, documentToEdit._id);
        fetchAllDocs();

        message.success("doc values updated")
      } else {
        const resp2 = await updateLegalDocumentValues(formData, documentToEdit._id);
        fetchAllDocs();
        message.success("doc values updated")
      }
      setOpenEditModal(false);
      setDocTypeGroup(null);
      setDocumentToEdit(null);
      setLoading(false);
    } catch(err) {
      message.error(err.message || "Something went wrong");
      setLoading(false);
    }
  };

  return currentUser ? (
    <>
    <Row gutter={[0,token.marginMD]}>
      <Col span={24}>
        <Typography.Title level={2}>Legal Document Library</Typography.Title>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={token.marginXS}>
          <Typography.Text type="secondary">The Legal Documents Library is where you create a repository of your legal documents to keep a record of what documents have been disclosed to your users.</Typography.Text>
        </Space>
      </Col>
        {dataSource?.length < 1 && !loading ? (
          <Flex flex={1} justify="center" align="center" vertical={true} style={{ height: 500, background:'white' }}>
            <Typography.Title level={3}>Start by adding your First Document</Typography.Title>
            <Button type="primary" onClick={() => handleAddNew()}>+ New Doc</Button>
          </Flex>
        ) : (
          <>
            <Col span={24}>
              <Flex justify="space-between" align="center">
                <Input.Search
                  placeholder="Search by version name or ID"
                  onChange={({ target: { value } }) => {
                    debouncedChangeHandler(value);
                  }}
                  style={{ width: 360 }}
                />
                <Button
                  type="primary"
                  onClick={() => handleAddNew()}
                >
                  + New Doc
                </Button>
              </Flex>
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                expandable={{
                  expandedRowKeys,
                  indentSize: 8,
                  onExpand: (expanded, record) => {
                    if (expanded) {
                      setExpandedRowKeys([...expandedRowKeys, record.key])
                    } else {
                      setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key))
                    }
                  },
                }}
                dataSource={dataSource}
                loading={loading}
                pagination={false}
                scroll={{ y: `calc(100vh - 350px)` }}
              />
            </Col>
          </>)
        }
    </Row>
    {openUploadDocumentModal ? (
      <LegalDocumentManagementModal
        loading={loading}
        open={openUploadDocumentModal}
        onCancel={() => {setOpenUploadDocumentModal(false);setDocTypeGroup(null)}}
        docTypeGroup={docTypeGroup}
        handleSaveDocument={handleSaveDocument}
        documentNames={allLegalDocs.filter((doc) => doc.documentName !== docTypeGroup?.documentName).map((doc) => doc.documentName)}
      />
    ) : null}
    {openEditModal ? (
      <EditDocumentModal
        loading={loading}
        open={openEditModal}
        onCancel={() => {setOpenEditModal(false);setDocTypeGroup(null);setDocumentToEdit(null)}}
        docTypeGroup={docTypeGroup}
        documentToEdit={documentToEdit}
        handleSaveDocument={handleEditDocument}
        documentNames={allLegalDocs.filter((doc) => doc.documentName !== documentToEdit?.documentName).map((doc) => doc.documentName)}
      />
    ) : null}
    {contextHolder}
    </>
  ) : <Loading/>
};

export default LegalDocumentManagementHome;