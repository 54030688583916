/* eslint-disable */
export default class DisclosedTriggeringNonDisclosedMetricRule {
  isSupport(filters, node) {
    if (filters.highlight_filter === 'is_disclosed') {
      for (const value of filters.disclosure_highlight) {
        if (value === 'disclosed-triggering-non-disclosed' && node.data.attributes.is_disclosed) {
          if (node.data.attributes.referred.length) {
            for (const child of node.data.attributes.referred) {
              if (!child?.data?.attributes?.is_disclosed) {
                return true;
              }
            }
          } else {
            return false;
          }
        }
      }
    }
    return false;
  }
  getStrokeColor() {
    return '#E77408';
  }
  getBorderColor() {
    return '#E77408';
  }
}
