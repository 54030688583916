export const antTheme = {
  cssVar: { key: 'avocado' },
  hashed: false,
  token: {
    // Seed Token
    fontFamily: "Open Sans",
    colorPrimary: "#4f28b3",
    colorInfo: "#4f28b3",
    colorSuccess: "#46a716",
    colorError: "#f5222d",
    colorTextBase: "#272727",
    colorText: "#272727",
    colorTextSecondary: "#727272",
    colorTextTertiary: "#8f8f8f",
    colorTextQuaternary: "#c9c9c9",
    colorBorder: "#d3d9dd",
    colorBorderSecondary: "#e9e9e9",
    colorFill: "#dfdfdf",
    colorFillSecondary: "#f2f2f2",
    colorFillTertiary: "#f7f7f7",
    colorFillQuaternary: "#f8f8f8",
    colorBgSpotlight: "#474747",
    controlItemBgActive: "#EAE4F2",
    controlItemBgActiveDisabled: "#DFDFDF",
    controlItemBgActiveHover: "#D3C5E6",
    controlItemBgHover: "#F7F7F7",
    controlOutline: "#ECE8F3",
    controlTmpOutline: "#FBFBFB",
    colorSuccessText: "#2a610f",
    colorErrorText: "#a8071a",
    wireframe: true,
    colorWarning: "#c88704",
    colorWarningText: "#775103",
    colorWarningBg: "#fffef5",
    colorSuccessBg: "#f7faf4"
  },
  components: {
    Modal: {
      titleFontSize: "20px",
      titleLineHeight: 1.4,
      paddingLG: 16
    },
    Tag: {
      fontSizeSM: 14,
      marginXS: 12
    },
    Layout: {
      bodyBg: "#fff"
    },
    Menu: {
      itemBorderRadius: 0,
      itemHeight: 64,
      itemSelectedColor: "#272727"
    },
    Divider: {
      verticalMarginInline: 12,
      colorSplit: "#d3d9dd"
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0
    },
    Tree: {
      colorBgTextHover: "rgba(242,242,242,0.60)"
    },
    Button: {
      dangerShadow: 0,
      defaultShadow: 0,
      primaryShadow: 0
    }
  }
}