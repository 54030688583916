import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Typography, Row, Col, theme, Space } from 'antd';
import { browserHistory } from 'react-router';

const { Text, Title, Paragraph } = Typography;

import {
  FirstAppearedScanColumnApp as FirstAppearedScanColumn,
  LastAppearedScanColumnApp as LastAppearedScanColumn,
  PrevalenceColumnApp as PrevalenceColumn,
  VendorNameColumnApp as VendorColumn,
  VendorReferenceColumn,
  VendorStatusColumnApp as VendorStatusColumn,
  VendorTypeColumn,
  PrivacyPolicyColumn,
  VendorDomainColumn,
  PrevalenceColumnApp
} from '../../shared/DiagnoseColumns';

import { getVendorsPriorToConsentList } from '../../../../../actions/app_dashboard_actions';
import {
  getVendorManagementStatusesList,
  getVendorsPriorToConsentFilter,
  vendorsPriorToConsentExport,
} from '../../../../../actions/diagnose_dashboard_actions';
import TableFilterDropdown from '../../shared/TableFilterDropdown';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  isFiltersProvided,
  mapSelectedFiltersApp,
  requestDataDependOnTableFilters,
  getUniqueVendorIdsListApp,
} from '../../details_pages/helpers';
import VendorDomainModal from '../VendorDomainModal';

const DetailedBreakdown = ({
  getVendorsPriorToConsentList,
  vendorsPriorToConsentList,
  vendorsPriorToConsentListPending,
  vendorsPriorToConsentFilter,
  getVendorsPriorToConsentFilter,
  selectedAppFilters,
  vendorsPriorToConsentExportPending,
  vendorsPriorToConsentExport,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFiltersApp(selectedAppFilters), [selectedAppFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const [modalOpenType, setModalOpenType] = useState(false);
  const [vendorDomainModalData, setVendorDomainModalData] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const { token } = theme.useToken();

  const getVendorsPriorToConsentListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getVendorsPriorToConsentList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
      accountId: userDetails?.accountId,
    });
    return res;
  };

  const openVendorDomainModal = (vendorDomain) => {
    setModalOpenType(true);
    setVendorDomainModalData(vendorDomain);
  };

  // const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
  //   const query = {
  //     metric: 'Vendors triggered prior to consent',
  //     vendors,
  //     status,
  //     tag,
  //     websites: selectedAppFilters?.websites,
  //   };
  //   const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
  //   if (isCacheValid) {
  //     return vendorManagementStatuses;
  //   }
  //   const res = await getVendorManagementStatusesList(query);
  //   return res;
  // };

  const getVendorsPriorToConsentFiltersData = () => {
    getVendorsPriorToConsentFilter(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      // getVendorsPriorToConsentListData(filters).then((res) => {
      //   getVendorManagementStatusesListData({ ...filters, vendors: getUniqueVendorIdsListApp(res?.vendors) })
      // })
      getVendorsPriorToConsentListData(filters);
      // requestDataDependOnTableFilters(
      //   { filters: filters || {}, isEnabledVendorStatusManagementFeature },
      //   (f, vendorId) => getVendorsPriorToConsentListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
      //   getVendorManagementStatusesListData,
      // );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      // getVendorsPriorToConsentFiltersData();
    }
  }, [filters]);

  // useEffect(() => {
  //   if (vendorManagementActivities?.length) {
  //     getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsListApp(vendorsPriorToConsentList?.vendors) }, false);
  //   }
  // }, [vendorManagementActivities]);

  const vendorsFilter = vendorsPriorToConsentList?.vendors?.map((v) => ({ text: v?.name, value: v?.name }));
  const vendorCategorizationFilter = Array.from(
    new Set(vendorsPriorToConsentList?.vendors?.map((filter) => filter?.vendor_categorization)),
  ).map((value) => ({ text: value, value }));
  const cookiesFilter = vendorsPriorToConsentFilter?.cookies?.map((filter) => ({ text: filter, value: filter }));
  const referenceVendorsFilter = vendorsPriorToConsentFilter?.reference_vendors?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const legalBasisFilter = vendorsPriorToConsentFilter?.legal_basis?.map((filter) => ({ text: filter, value: filter }));

  const columns = [
    VendorColumn({ filters: vendorsFilter || [] }),
    // ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    {
      title: 'Vendor Categorization',
      dataIndex: 'vendor_categorization',
      key: 'vendor_categorization',
      sorter: (a, b) => {
        const firstTagA = a.vendor_categorization.split(';')[0].trim(); // Get the first tag of 'a'
        const firstTagB = b.vendor_categorization.split(';')[0].trim(); // Get the first tag of 'b'
        return firstTagA.localeCompare(firstTagB);
      },
      filters: vendorCategorizationFilter,
      onFilter: (value, record) => record.vendor_categorization.includes(value),
      width: 228,
    },
    PrevalenceColumnApp,
    FirstAppearedScanColumn,
    LastAppearedScanColumn,
    PrivacyPolicyColumn,
    VendorDomainColumn(openVendorDomainModal)
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    vendorsPriorToConsentExport(filters);
  };

  const tableData = useMemo(() => {
    // if (!isEnabledVendorStatusManagementFeature) {
    //   return vendorsPriorToConsentList?.data
    // }

    return vendorsPriorToConsentList?.data
  }, [isEnabledVendorStatusManagementFeature, vendorsPriorToConsentList?.data]);

  return (
    <div className={'app-detailed-breakdown-container'}>
      <Row gutter={[0, 16]}>
        <Col span={24} gutter={[0, 4]}>
          <Title level={5}>Detailed Breakdown</Title>
          <Paragraph className="subtext">
            "Triggered Prior to Consent" means these vendors made a network call before the user took any action.
            Vendors listed here don't necessarily access user information, but it's recommended to scrutinize them
            according to regulatory guidelines.
          </Paragraph>
        </Col>
        <Col span={24}>
          <Table
            id="detailed-breakdown"
            scroll={{ x: 'max-content', y: 430 }}
            rowKey={(record) => record?.vendor_id}
            rowClassName={(record) =>
              record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
                ? 'diagnose-notification-highlight'
                : ''
            }
            dataSource={tableData}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              current: page,
              pageSize: limit,
              total:
                tableFilters?.vendor_status?.length && !vendorManagementStatuses.length
                  ? 0
                  : vendorsPriorToConsentList?.total_count,
              position: ['bottomCenter'],
              onChange: onPaginationChange,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
            }}
            // onChange={onTableChange}
            loading={{
              className: 'diagnose-spinning',
              spinning: vendorsPriorToConsentListPending || vendorManagementStatusesPending,
              tip: vendorsPriorToConsentList?.results?.length
                ? ''
                : 'Processing a large query; please wait or leave it open and check later.',
              size: 'large',
            }}
          />
        </Col>
      </Row>
      {modalOpenType && (
        <VendorDomainModal
          modalOpenType={modalOpenType}
          data={vendorDomainModalData}
          onClose={() => {
            setModalOpenType(false);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorsPriorToConsentList: store.appDashboardState.getIn(['vendorsPriorToConsentList', 'value']),
    vendorsPriorToConsentListPending: store.appDashboardState.get('vendorsPriorToConsentListPending'),
    vendorsPriorToConsentExportPending: store.diagnoseDashboardState.get('vendorsPriorToConsentExportPending'),
    vendorsPriorToConsentFilter: store.diagnoseDashboardState.getIn(['vendorsPriorToConsentFilter', 'value']),
    selectedAppFilters: store.appDashboardState.getIn(['selectedAppFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVendorsPriorToConsentList,
      getVendorsPriorToConsentFilter,
      vendorsPriorToConsentExport,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
