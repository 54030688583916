import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Table, Tabs, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { browserHistory, Link } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  getChatbotHarFileData,
  getChatbotsTechDetectedList,
  getCookielessHarFileData,
  getCookielessTechDetectedList,
  getPixelsHarFileData,
  getPixelsTechDetectedList,
  getSessionReplayHarFileData,
  getSessionReplaysTechDetectedList,
  getTrackingTechDetectedList,
  getVendorManagementStatusesList,
} from '../../../../../actions/diagnose_dashboard_actions';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import CellContentWithPrimaryTag from '../../shared/CellContentWithPrimaryText';
import { getUniqueVendorIdsList, isFiltersProvided, mapSelectedFilters } from '../helpers';
import useSearchParams from '../../shared/useSearchParams';
import VendorStatusModal from '../../vendor_status/VendorStatusModal';
import InfoModal from './InfoModal';
import {
  getChatbotColumn,
  getCookielesColumns,
  getPixelsColumns,
  getSessionReplayColumn,
  pixelsColumns,
} from './helper';

const { Text } = Typography;

const VendorsDetails = ({
  selectedFilters,
  userDetails,
  getCookielessTechDetectedList,
  cookielessTechDetectedList,
  cookielessTechDetectedListPending,
  getPixelsTechDetectedList,
  pixelsTechDetectedList,
  pixelsTechDetectedListPending,
  chatbotsTechDetectedList,
  getChatbotsTechDetectedList,
  chatbotsTechDetectedListPending,
  sessionReplaysTechDetectedList,
  getSessionReplaysTechDetectedList,
  sessionReplaysTechDetectedListPending,
  vendorManagementActivities,
  getVendorManagementStatusesList,
  getPixelsHarFileData,
  getCookielessHarFileData,
  getChatbotHarFileData,
  getSessionReplayHarFileData,
}) => {
  const vendorId = useMemo(() => new URLSearchParams(window.location.search).get('id'), []);
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const [tableFilters, setTableFilters] = useState(null);
  const [modalOpenType, setModalOpenType] = useState(null);
  const [scanUrlData, setScanUrlData] = useState(null);

  const vendorList = useSelector((state) => state.diagnoseDashboardState.getIn(['trackingTechDetectedList', 'value']));
  const allProperties = useSelector((state) => state.diagnoseDashboardState.getIn(['websitePropertiesList', 'value']));
  const vendorStatus = useSelector((state) =>
    state.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
  );

  const vendor = useMemo(() => vendorList?.results?.find((v) => v.vendor_id === vendorId), [vendorList, vendorId]);
  const detailedStatus = useMemo(() => vendorStatus?.filter((v) => v.vendor === vendorId), [vendorId, vendorStatus]);
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const accountId = userDetails.accountId;

  if (!vendor) {
    browserHistory.push('/diagnose_dashboard/tracking_tech_detected');
  }

  const vendorWithStatus = {
    ...vendor,
    vendor_status: detailedStatus.length ? detailedStatus : [],
  };

  const categories = useMemo(() => {
    return vendor?.categories?.[0]?.split(',') || [];
  }, [vendor]);

  const [firstCategory, ...restCategories] = categories;

  const referenceVendor = useMemo(() => {
    return vendor?.reference_vendors?.map((v) => v.name).join(',') || '';
  }, [vendor]);

  const [firstVendor, ...restVendors] = referenceVendor.split(', ');

  const getWebsiteName = (filters, allProperties) => {
    if (!filters?.websites || !allProperties) return [];

    return filters.websites
      .map((websiteId) => {
        const matchedProperty = allProperties.find((property) => property.value === websiteId);
        return matchedProperty?.title || null;
      })
      .filter(Boolean);
  };

  const websiteName = getWebsiteName(selectedFilters, allProperties);

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const onTableChange = (pagination, filters, sorter) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const showModal = (data) => {
    const scanUrlData = { record: data.record, vendorId: data.vendorId, techId: data.techId };
    setModalOpenType(data.type);
    setScanUrlData(scanUrlData);
  };

  const showParameterModal = (parameter) => {
    setModalOpenType('parameterModal');
    setScanUrlData(parameter);
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Tracking Tech',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(vendorList?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const fetchTechData = async (fetchFunction, tableFiltersData = {}, vendorId = []) => {
    const res = await fetchFunction({
      ...filters,
      accountId,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getPixelsTechDetected = (tableFiltersData = {}, vendorId = []) => {
    return fetchTechData(getPixelsTechDetectedList, tableFiltersData, vendorId);
  };

  const getCookilesTechDetected = (tableFiltersData = {}, vendorId = []) => {
    return fetchTechData(getCookielessTechDetectedList, tableFiltersData, vendorId);
  };

  const getChatbotTechDetected = (tableFiltersData = {}, vendorId = []) => {
    return fetchTechData(getChatbotsTechDetectedList, tableFiltersData, vendorId);
  };

  const getSessionReplayTechDetected = (tableFiltersData = {}, vendorId = []) => {
    return fetchTechData(getSessionReplaysTechDetectedList, tableFiltersData, vendorId);
  };

  useEffect(() => {
    if (vendorId && isFiltersProvided(filters)) {
      fetchData();
    }
  }, [vendorId, filters, page, limit, orderBy]);

  const fetchData = async () => {
    try {
      const results = await Promise.all([
        vendor?.use_pixels && getPixelsTechDetected({}, vendorId),
        vendor?.use_cookieless && getCookilesTechDetected({}, vendorId),
        vendor?.use_chatbots && getChatbotTechDetected({}, vendorId),
        vendor?.use_session_replay && getSessionReplayTechDetected({}, vendorId),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchHarFileData = async (fetchFunction, tableFiltersData = {}, vendorId = [], scanUrl, techId) => {
    const res = await fetchFunction({
      ...filters,
      accountId,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
      techId,
      scanUrl,
    });
    return res;
  };

  const handleHarFileDownload = async (data) => {
    const scanUrl = data.record.join(', ');
    const vendorId = data.vendorId;
    const techId = data.techId;

    if (vendor.use_pixels) {
      await fetchHarFileData(getPixelsHarFileData, filters, vendorId, scanUrl, techId);
    }
    if (vendor.use_cookieless) {
      await fetchHarFileData(getCookielessHarFileData, filters, vendorId, scanUrl, techId);
    }
    if (vendor.use_chatbots) {
      await fetchHarFileData(getChatbotHarFileData, filters, vendorId, scanUrl, techId);
    }
    if (vendor.use_session_replay) {
      await fetchHarFileData(getSessionReplayHarFileData, filters, vendorId, scanUrl, techId);
    }
  };

  const pixelColumns = getPixelsColumns({
    showParameterModal,
    showModal,
    handleHarFileDownload,
    vendorId,
  });

  const cookielesColumns = getCookielesColumns({
    showModal,
    handleHarFileDownload,
    vendorId,
  });
  const chatbotColumn = getChatbotColumn({
    showModal,
    handleHarFileDownload,
    vendorId,
  });
  const sessionReplayColumn = getSessionReplayColumn({
    showModal,
    handleHarFileDownload,
    vendorId,
  });

  const tabItems = useMemo(
    () =>
      [
        vendor?.use_pixels && {
          key: '1',
          label: 'Pixels',
          children: (
            <Table
              columns={pixelColumns}
              dataSource={pixelsTechDetectedList?.results}
              pagination={{
                current: page,
                pageSize: limit,
                total: pixelsTechDetectedList?.total_count,
                position: ['bottomCenter'],
                onChange: onPaginationChange,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '200'],
              }}
              onChange={onTableChange}
              loading={{
                className: 'diagnose-spinning',
                spinning: pixelsTechDetectedListPending,
                tip: pixelsTechDetectedList?.results?.length
                  ? ''
                  : 'Processing a large query; please wait or leave it open and check later.',
                size: 'large',
              }}
            />
          ),
        },
        vendor?.use_cookieless && {
          key: '2',
          label: 'Cookieless IDs',
          children: (
            <Table
              columns={cookielesColumns}
              dataSource={cookielessTechDetectedList?.results}
              pagination={{
                current: page,
                pageSize: limit,
                total: cookielessTechDetectedList?.total_count,
                position: ['bottomCenter'],
                onChange: onPaginationChange,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '200'],
              }}
              onChange={onTableChange}
              loading={{
                className: 'diagnose-spinning',
                spinning: cookielessTechDetectedListPending,
                tip: cookielessTechDetectedList?.results?.length
                  ? ''
                  : 'Processing a large query; please wait or leave it open and check later.',
                size: 'large',
              }}
            />
          ),
        },
        vendor?.use_chatbots && {
          key: '3',
          label: 'Chatbots',
          children: (
            <Table
              columns={chatbotColumn}
              dataSource={chatbotsTechDetectedList?.results}
              pagination={{
                current: page,
                pageSize: limit,
                total: chatbotsTechDetectedList?.total_count,
                position: ['bottomCenter'],
                onChange: onPaginationChange,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '200'],
              }}
              onChange={onTableChange}
              loading={{
                className: 'diagnose-spinning',
                spinning: chatbotsTechDetectedListPending,
                tip: chatbotsTechDetectedList?.results?.length
                  ? ''
                  : 'Processing a large query; please wait or leave it open and check later.',
                size: 'large',
              }}
            />
          ),
        },
        vendor?.use_session_replay && {
          key: '4',
          label: 'Session Replay Tech',
          children: (
            <Table
              columns={sessionReplayColumn}
              dataSource={sessionReplaysTechDetectedList?.results}
              pagination={{
                current: page,
                pageSize: limit,
                total: sessionReplaysTechDetectedList?.total_count,
                position: ['bottomCenter'],
                onChange: onPaginationChange,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '200'],
              }}
              onChange={onTableChange}
              loading={{
                className: 'diagnose-spinning',
                spinning: sessionReplaysTechDetectedListPending,
                tip: sessionReplaysTechDetectedList?.results?.length
                  ? ''
                  : 'Processing a large query; please wait or leave it open and check later.',
                size: 'large',
              }}
            />
          ),
        },
      ].filter(Boolean),
    [
      vendor,
      cookielessTechDetectedList,
      pixelsTechDetectedList,
      sessionReplaysTechDetectedList,
      chatbotsTechDetectedList,
    ],
  );

  return (
    <div className="diagnose_container">
      <div className="vendor-details-title">
        <Link to={'/diagnose_dashboard/tracking_tech_detected'}>
          <Button type="text" size="large">
            <LeftOutlined />
          </Button>
        </Link>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Vendor Details
        </Typography.Title>
      </div>

      {vendor ? (
        <div className="vendor-details-container">
          <Row justify="space-between">
            <Col span={11}>
              Vendor Name: <Text strong>{vendor.name}</Text>
            </Col>
            <Col span={11}>
              <div className="vendor-details-category">
                Vendor Status:
                <div style={{ marginLeft: '8px' }} key={vendorWithStatus?.vendor_id}>
                  {vendorWithStatus?.vendor_status.length ? (
                    <VendorStatusModal
                      data={vendorWithStatus}
                      defaultOpen={
                        vendorWithStatus.vendor_id === searchParams.vendor &&
                        ['CHANGED_STATUS', 'CHANGES_DETECTED'].includes(searchParams.type)
                      }
                    />
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              First Seen
              <Tooltip title="First time we identified the vendor on the selected property">
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
              :<Text strong>{moment(vendor.first_appeared_scan).format('MMM-D-YYYY')}</Text>
            </Col>
            <Col span={11}>
              Last Seen
              <Tooltip title="Last time we identified the vendor on the selected property">
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
              :<Text strong>{moment(vendor.last_appeared_scan).format('MMM-D-YYYY')}</Text>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              Prevalence (my properties): <Text strong>{vendor.prevalence}%</Text>
            </Col>
            <Col span={11}>
              Prevalence (benchmark): <Text strong>{vendor.prevalence_benchmark}%</Text>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <div className="vendor-details-category">
                Vendor Categorization:
                <Text strong>
                  <CellContentWithPrimaryTag
                    className={'reference-vendors-cell'}
                    content={firstCategory}
                    tooltipTitle={`+${restCategories.length}`}
                    tooltipContent={restCategories.join('; ')}
                  />
                </Text>
              </div>
            </Col>
            <Col span={11}>
              <div className="vendor-details-category">
                Referrer Vendor:
                <Text strong>
                  <CellContentWithPrimaryTag
                    className={'reference-vendors-cell'}
                    content={firstVendor}
                    tooltipTitle={`+${restVendors.length}`}
                    tooltipContent={restVendors.join('; ')}
                  />
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="vendor-details-container">No Vendor Found</div>
      )}

      <div className="vendor-details-properties">
        <Card title="Properties">
          {websiteName.length > 0 ? (
            websiteName.map((name, index) => <Tag key={index}>{name}</Tag>)
          ) : (
            <Tag>No Properties Found</Tag>
          )}
        </Card>
      </div>

      <div className="vendor-details-tech-container">
        <Card title="Tech Information">
          {tabItems.length > 0 ? (
            <Tabs defaultActiveKey="1" items={tabItems} />
          ) : (
            <div>No tech information available</div>
          )}
        </Card>
      </div>
      {modalOpenType && (
        <InfoModal
          modalOpenType={modalOpenType}
          data={scanUrlData}
          onClose={() => {
            setModalOpenType(null);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  userDetails: store.accountState.getIn(['userDetails', 'value']),
  selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
  vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
  cookielessTechDetectedList: store.diagnoseDashboardState.getIn(['cookielessTechDetectedList', 'value']),
  cookielessTechDetectedListPending: store.diagnoseDashboardState.get('cookielessTechDetectedListPending'),
  chatbotsTechDetectedList: store.diagnoseDashboardState.getIn(['chatbotsTechDetectedList', 'value']),
  chatbotsTechDetectedListPending: store.diagnoseDashboardState.get('chatbotsTechDetectedListPending'),
  sessionReplaysTechDetectedList: store.diagnoseDashboardState.getIn(['sessionReplaysTechDetectedList', 'value']),
  sessionReplaysTechDetectedListPending: store.diagnoseDashboardState.get('sessionReplaysTechDetectedListPending'),
  pixelsTechDetectedList: store.diagnoseDashboardState.getIn(['pixelsTechDetectedList', 'value']),
  pixelsTechDetectedListPending: store.diagnoseDashboardState.get('pixelsTechDetectedListPending'),
  vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
  vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesQuery', 'value']),
  pixelsHarFileData: store.diagnoseDashboardState.getIn(['pixelsHarFileData', 'value']),
  pixelsHarFileDataPending: store.diagnoseDashboardState.get('pixelsHarFileDataPending'),
  cookielessHarFileData: store.diagnoseDashboardState.getIn(['cookielessHarFileData', 'value']),
  cookielessHarFileDataPending: store.diagnoseDashboardState.get('cookielessHarFileDataPending'),
  chatbotHarFileData: store.diagnoseDashboardState.getIn(['chatbotHarFileData', 'value']),
  chatbotHarFileDataPending: store.diagnoseDashboardState.get('chatbotHarFileDataPending'),
  sessionReplayHarFileData: store.diagnoseDashboardState.getIn(['sessionReplayHarFileData', 'value']),
  sessionReplayHarFileDataPending: store.diagnoseDashboardState.get('sessionReplayHarFileDataPending'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVendorManagementStatusesList,
      getCookielessTechDetectedList,
      getPixelsTechDetectedList,
      getSessionReplaysTechDetectedList,
      getChatbotsTechDetectedList,
      getPixelsHarFileData,
      getCookielessHarFileData,
      getChatbotHarFileData,
      getSessionReplayHarFileData,
      getTrackingTechDetectedList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VendorsDetails);
