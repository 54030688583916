import React, { useState } from 'react';
import { Modal } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ViewMessage } from '../../common/ViewMessage';
import MessagePreview from '../messages/MessagePreview';
import { getParameterByName } from '../../utils';
import { fromJS } from 'immutable';

function MessagePreviewModal({ 
  viewMsg,
  showMessagePreview,
  togglePreviewModal,
}){

   return (<Modal
            centered
            onCancel={() => togglePreviewModal()}
            title={<h3>{viewMsg.description}<span className='meesage-title-verticle-line'> | </span><span className='message-id'>ID: {viewMsg.id}</span></h3>}
            footer={null}
            visible={showMessagePreview}
            wrapClassName="scenario-modal-wrapper"
            closeIcon={<React.Fragment>Close Preview <ArrowRightOutlined /></React.Fragment>}>
            {viewMsg.message_category_id != 0 && (
              <ViewMessage
                viewMsg={fromJS(viewMsg)}
                siteId={viewMsg?.get("site_id") ?? parseInt(getParameterByName('site_id', window.location))}
              />
            )}
            {viewMsg.message_category_id == 0 &&
              viewMsg.message_config && (
                <MessagePreview
                  choiceConfig={viewMsg.choice_config}
                  messageConfig={viewMsg.message_config}
                  variables={viewMsg.variables}
                  siteScreenshotUrl={
                    viewMsg.message_config.data.userData
                      .customScreenshotUrl
                  }
                />
            )}
          </Modal>);
}

export default MessagePreviewModal;