import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from 'react-router';
import {
  getAllPreferenceConfiguration,
  clearPreferenceConfigurationError,
  deletePreferenceConfiguration,
  clonePreferenceConfiguration,
  activateDeactivatePreferenceConfiguration,
  getAllVendorConnections,
} from '../../../actions/preferences_actions';
import { useActions } from "../../../hooks";
import RegulationListGeneric from "../vendor_list_v2/RegulationListGeneric";
import Loading from "../../common/Loading.js";
import { Button, Flex, Modal, Typography, Space } from "antd";
import { PlusOutlined, RightSquareOutlined } from "@ant-design/icons";
import { hasFeature } from "../../utils.js";

const PreferencesHomePage = (props) => {
  //redux actions
  const getAllPreferenceConfigurationAction = useActions(getAllPreferenceConfiguration);
  const cloneConfiguration = useActions(clonePreferenceConfiguration);
  const deleteConfiguration = useActions(deletePreferenceConfiguration);
  const activateDeactivateConfiguration = useActions(activateDeactivatePreferenceConfiguration);
  const getVendorConnections = useActions(getAllVendorConnections);
  const clearConfigurationError = useActions(clearPreferenceConfigurationError);

  //redux states
  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value']))
  const sites = useSelector(state => state.siteState.getIn(['sites', 'value']))?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-'));
  const siteGroups = useSelector(state => state.siteState.getIn(['siteGroups', 'value']))
  const preferenceConfigurationServerSideError =  useSelector(state => state.preferencesState.getIn(["preferenceConfigurationList", "error"]));
  const configurationsList = useSelector(state => state.preferencesState.getIn(['preferenceConfigurationList', 'value']));
  const configurationsListPending = useSelector(state => state.preferencesState.getIn(['preferenceConfigurationList', 'pending']));
  const vendorConnections = useSelector(state => state.preferencesState.getIn(['vendorConnections','value']));
  const vendorConnectionsPending = useSelector(state => state.preferencesState.getIn(['vendorConnections', 'pending']))

  const [modal, contextHolder] = Modal.useModal();

  const editUSPRegulation = (configuationId) => {
    browserHistory.push(`/preferences/edit?configurationId=${configuationId}`);
  };

  const [tableStates, setTableStates] = useState({
    sorter: {},
    filters: {},
    pagination: {}
  });

  const hasMarkertingPreferenceAccess = hasFeature(currentUser, "marketing_preferences") && ((currentUser.featureAccess && currentUser.featureAccess.includes('Integration Hub')) || currentUser.allFeatureAccess);
  const hasLegalPreferenceAccess = hasFeature(currentUser, "legal_preferences");

  const calculateAccess = (sites, vlSites) => {
    return false;
    // if (currentUser?.allSiteAccess) return false;
    // const siteSet = new Set(sites);
    // let viewOnly = false;
    // vlSites.forEach(s => {
    //   if (!siteSet.has(s)) viewOnly = true;
    // });
    // return viewOnly;
  };

  useEffect(() => {
    if(currentUser){
      getAllPreferenceConfigurationAction(currentUser.accountId);
      getVendorConnections();
    }
  },[currentUser])

  const handleCreate = async () => {
    if(hasMarkertingPreferenceAccess && hasLegalPreferenceAccess && vendorConnections?.size === 0) {
      const confirm = await modal.confirm({
        title: 'Integration Hub Required',
        content: <>A Marketing Preference category needs at least one Integration Hub connection.<br/>Continue without it? You can add a Legal Preferences category now and Marketing Preference later.</>,
        cancelText: 'Cancel',
        okText: 'Continue',
        icon: null,
        width: 720
      });
      if(confirm) {
        browserHistory.push(`/preferences/create`)
      }
    } else {
      browserHistory.push(`/preferences/create`);
    }
  }

  let readOnly = false;
  const isAccountPartOfWave1 = true;

  const regulationList = configurationsListPending && vendorConnectionsPending ? (
    <Loading/>
  ) : (
    <RegulationListGeneric
      currentUser={currentUser}
      vendorLists={configurationsList.map((config) => config.set("siteIds", config.get("propertyIds")))}
      sites={sites}
      siteGroups={siteGroups}
      vendorListsPending={configurationsListPending}
      usnat={props.route.usnat}
      nonIab={props.route.nonIab}
      readOnly={readOnly}
      calculateAccess={calculateAccess}
      entity={"Configuration"}
      handleCreate={handleCreate}
      handleEdit={editUSPRegulation}
      cloneVendorList={cloneConfiguration}
      deleteVendorList={deleteConfiguration}
      vendorListError={preferenceConfigurationServerSideError}
      clearVendorListError={clearConfigurationError}
      activateDeactivateVendorList={activateDeactivateConfiguration}
      isAccountPartOfWave1={isAccountPartOfWave1}
      tableStates={tableStates}
      setTableStates={setTableStates}
    />
  )

  return configurationsListPending ? <Loading/> :  (
    <Flex vertical={true} gap={20}>
      <Typography.Title level={2}>Configurations</Typography.Title>
      <Typography.Text type="secondary">Configurations enable you to manage the Legal Preferences and Marketing Preference categories for which you want to collect user interaction and choice data.</Typography.Text>
      { vendorConnections?.size == 0 && !hasLegalPreferenceAccess ? (
        <Flex justify="center" align="center" vertical={true} style={{ height: 300 }}>
          <Typography.Title level={3}> You first need to setup Connections in Integration Hub</Typography.Title>
          <Button type="primary" icon={<RightSquareOutlined />} onClick={() => browserHistory.push("/preferences/connections")}>Go to Integration Hub</Button>
        </Flex>
      ) : configurationsList.size ? (
        regulationList
      ) : (
        <Flex justify="center" align="center" vertical={true} style={{ height: 300 }}>
          <Typography.Title level={3}> Start by creating your first configuration</Typography.Title>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate}>Add Configuration</Button>
        </Flex>
      )}
      {contextHolder}
    </Flex>
  )
}

export default PreferencesHomePage
