import React, { Component } from 'react';
import { Slider } from '../../../../../../../styleguide/index.js';
import ColorPickerInput from '../../../../../../common/ColorPickerInput.js.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderStyle } from '@fortawesome/free-solid-svg-icons';

class Border extends Component {
  updateBorder = (type, value) => {
    const border = JSON.parse(JSON.stringify(this.props.value));
    if (type.slice(-3) === 'Rem') {
      border[type] = value + 'rem';
      this.setNonRemTypes(border);
    } else {
      border[type] = value;
    }
    this.props.updateSetting(this.props.settingKey, border);
  }
  calculatePx = (rem) => {
    let baseFontSize;
  
    const screenWidth = window.innerWidth;
  
    if (screenWidth <= 960) {
      baseFontSize = 11;  // Amazon Fire Stick / Android TV
    } else if (screenWidth >= 961 && screenWidth <= 1919) {
      baseFontSize = 14;  // HD
    } else if (screenWidth >= 1920 && screenWidth <= 3839) {
      baseFontSize = 21;  // Full HD
    } else {
      baseFontSize = 42;  // 4K
    }
  
    const pxValue = rem * baseFontSize;
    return pxValue;
  }
  setNonRemTypes = (border) => {  
    const notRem = ['borderWidth', 'borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'];
    notRem.forEach(type => {
      const remVal = border[type + 'Rem'];
      if (!remVal || typeof remVal === "number") return;
      border[type] = this.calculatePx(Number(border[type + 'Rem'].slice(0, -3)));
    });
    return border;    
  }
  render() {
    const { value, settingsTitle } = this.props;

    return (
      <div>
        <div className="four-options">
          <h4>{settingsTitle}</h4>
          <div className="border-flex">
            <div className="border-label">Size</div>
            <Slider min={0} max={5} step={0.01} tipFormatter={l=>l+'rem'}
              onChange={val => this.updateBorder('borderWidthRem', val)}
              value={typeof value.borderWidthRem === 'string' ? parseFloat(value.borderWidthRem) : 0}
            />
          </div>
          <div className="border-flex">
            <div className="border-label">Color</div>
            <div className="border-color">
              <ColorPickerInput
                value={ value.borderColor }
                onUpdate={(value) => this.updateBorder('borderColor', value)}
                editing={ true }
              />
            </div>
          </div>
          <h3>Rounded Corners</h3>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} /></div>
            <Slider min={0} max={3} step={0.01} tipFormatter={l=>l+'rem'}
              onChange={val => this.updateBorder('borderTopLeftRadiusRem', val)}
              value={typeof value.borderTopLeftRadiusRem === 'string' ? parseFloat(value.borderTopLeftRadiusRem) : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} rotation={90} /></div>
            <Slider min={0} max={3} step={0.01} tipFormatter={l=>l+'rem'}
              onChange={val => this.updateBorder('borderTopRightRadiusRem', val)}
              value={typeof value.borderTopRightRadiusRem === 'string' ? parseFloat(value.borderTopRightRadiusRem) : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} rotation={270} /></div>
            <Slider min={0} max={3} step={0.01} tipFormatter={l=>l+'rem'}
              onChange={val => this.updateBorder('borderBottomLeftRadiusRem', val)}
              value={typeof value.borderBottomLeftRadiusRem === 'string' ? parseFloat(value.borderBottomLeftRadiusRem) : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} rotation={180} /></div>
            <Slider min={0} max={3} step={0.01} tipFormatter={l=>l+'rem'}
              onChange={val => this.updateBorder('borderBottomRightRadiusRem', val)}
              value={typeof value.borderBottomRightRadiusRem === 'string' ? parseFloat(value.borderBottomRightRadiusRem) : 0}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Border;
