import React, { useEffect } from "react";
import { Col, Form, Input,  Modal, Row,  Spin, Typography } from "antd";
import { nameFieldValidator } from "../helper";
import { renderInfoTip } from "../../us_privacy_regulation/helper";

const EditDocumentModal = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if(props.documentToEdit) {
      const { documentName,  versionName, webPageLink } = props.documentToEdit;
      form.setFieldsValue({
        documentName,
        versionName,
        webPageLink
      });
    }
  },[props.documentToEdit])

  const onValuesChange = (changedValues, allValues) => {
    //if needed
  }

  const onFinish = (arg) => {
    const document = {
      documentName: arg.documentName,
      versionName: arg.versionName,
      webPageLink: arg.webPageLink
    }
    props.handleSaveDocument(document, props.documentToEdit.isLatest)
  }

  const onFinishFailed = (arg) => {
    //todo
  }

  return (
    <Modal
      title={"Edit Document"}
      onOk={form.submit}
      onCancel={props.onCancel}
      okText="Save"
      open={props.open}
      destroyOnClose={true}
      confirmLoading={props.loading}
      width={720}
    >
      <Spin spinning={props.loading}>
      <Form
        form={form}
        name="ldms"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={"optional"}
      >
        <Row gutter={[20, 16]}>
          <Col span={props.documentToEdit.isLatest ? 12 : 24}>
            <Form.Item
              label={<>Document name {renderInfoTip("The name of the new document category you're creating.")}</>}
              name={"documentName"}
              rules={[
                {
                  required: true,
                  message: "Please enter Document Name"
                },
                {
                  validator:(_,value) =>  nameFieldValidator(value, props.documentNames, "Document name")
                }
              ]}
              hidden={!props.documentToEdit.isLatest}
            >
              <Input maxLength={255} showCount={true} />
            </Form.Item>
            {props.documentToEdit.isLatest ? null : (
              <Typography.Text>Document Name <Typography.Text strong>: {props.documentToEdit.documentName}</Typography.Text></Typography.Text>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label={<>Version name{renderInfoTip("Defaults to the file name but can be edited. Helps track document versions.")}</>}
              name={"versionName"}
              rules={[
                {
                  required: true,
                  message: "Please enter Version Name"
                },
                {
                  validator:(_,value) =>  nameFieldValidator(value, props.docTypeGroup?.documents?.map((doc) => doc.versionName)?.filter((verName) => verName !== props.documentToEdit?.versionName), "Version name")
                }
              ]}
            >
              <Input maxLength={255} showCount={true}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label={<>Webpage Link{renderInfoTip(" The link to the document that users will see.")}</>}
              required={false}
              name={"webPageLink"}
              rules={[
                {
                  type: "url"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      </Spin>
    </Modal>
  );
}

export default EditDocumentModal;