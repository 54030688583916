/* eslint-disable default-case */
import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const emptyPaginationResponse = { results: [], total_count: 0 };
export const initialState = Map({
    summaryAppDiagnose: new StoreRecord({ value: {} }),
    summaryAppPending: false,
    vendorsPriorToConsentList: new StoreRecord({ value: {} }),
    vendorsPriorToConsentListPending: false,
    vendorsPriorToConsentExportPending: false,
    vendorsPriorToConsentFilter: new StoreRecord({ value: {} }),
    selectedMetrics: new StoreRecord({
        value: [
            'Vendors triggered prior to consent',
            'Vendors after opt out',
            'Vendors after reject all',
            'All vendors found',
            // My Privacy Experience
        ],
    }),
    metricsList: new StoreRecord({ value: [
        'Vendors triggered prior to consent',
        'Vendors after opt out',
        'Vendors after reject all',
        'All vendors found',
        ],
    }),
    selectedAppFilters: new StoreRecord({ value: {} }),
    regionsList: new StoreRecord({ value: [] }),
    vendorDetails: new StoreRecord({ value: {} }),
    vendorDetailsPending: false,
    vendorWebsiteDetails: new StoreRecord({ value: new List([]) }),
    vendorWebsiteDetailsPending: false,
    vendorDetailsExportPending: false,
    websitePropertiesList: new StoreRecord({ value: new List([]) }),
    websitePropertiesListPending: false,
    vendorStatusSelectedPropertyList: new StoreRecord({ value: new List([])}),
    vendorStatusFilterList: new StoreRecord({ value: ['Unreviewed', 'Under Review', 'Approved', 'Action Required', 'New', 'Change Detected', 'Approved with risk']}),
    vendorManagementStatuses: new StoreRecord({ value: new List([]) }),
    vendorManagementStatusesParams: new StoreRecord({ value: {} }),
    vendorManagementStatusesPending: false,
    vendorManagementActivities: new StoreRecord({ value: new List([]) }),
    vendorManagementActivitiesList: new StoreRecord({ value: new List([]) }),
    getVendorManagementActivitiesListPending: new StoreRecord({ value: false }),
    addVendorManagementActivitiesPending: false,
    isVendorStatusMultiselectOperationActive: new StoreRecord({ value: false }),
    vendorsAfterOptOutApp: new StoreRecord({ value: [] }),
    vendorsAfterOptOutAppPending: false,
    allVendorsFound:  new StoreRecord({ value: [] }),
    allVendorsFoundPending: false,
    vendorsAfterRejectAll:  new StoreRecord({ value: [] }),
    vendorsAfterRejectAllPending: false,
    vendorsAfterOptOutFilter: new StoreRecord({ value: {} }),
    vendorsAfterOptOutFilterPending: false,
    vendorsAfterOptOutExportPending: false,
    vendorsAfterOptOutWebsites: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    vendorsAfterOptOutWebsitePending: false,
    vendorsAfterOptOutVendorData: new StoreRecord({ value: {} }),
    vendorsAfterOptOutVendorDataPending: false,
  
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SUMMARY_APP_PENDING:
            return setValue(state, ['summaryAppDiagnose'], []).set('summaryAppPending', true);

        case types.SUMMARY_APP_LOADED:
            return setValue(state, ['summaryAppDiagnose'], action.summaryDiagnose).set('summaryAppPending', false);

        case types.SUMMARY_APP_ERROR:
            return setError(state, ['summaryAppDiagnose'], action.errorMessage).set('summaryAppPending', false);

        case types.APP_VENDORS_PRIOR_TO_CONSENT_LIST_PENDING:
            return setPending(state, ['vendorsPriorToConsentList']).set('vendorsPriorToConsentListPending', true);
    
        case types.APP_VENDORS_PRIOR_TO_CONSENT_LIST_LOADED:
            return setValue(state, ['vendorsPriorToConsentList'], action.vendorsPriorToConsentList).set('vendorsPriorToConsentListPending', false);
        
        case types.APP_VENDORS_PRIOR_TO_CONSENT_LIST_ERROR:
            return setError(state, ['vendorsPriorToConsentList'],action.errorMessage)

        case types.APP_VENDORS_OPT_OUT_LIST_PENDING:
            return setPending(state, ['vendorsAfterOptOutApp']).set('vendorsAfterOptOutAppPending', true);
    
        case types.APP_VENDORS_OPT_OUT_LIST_LOADED:
            return setValue(state, ['vendorsAfterOptOutApp'], action.vendorsOptOutList).set('vendorsAfterOptOutAppPending', false);

        case types.APP_VENDORS_OPT_OUT_LIST_ERROR:
                return setValue(state, ['vendorsAfterOptOutApp'],action.errorMessage).set('allVendorsFoundPending', false);

        case types.APP_ALL_VENDORS_FOUND_PENDING:
            return setPending(state, ['allVendorsFound']).set('allVendorsFoundPending', true);
        
        case types.APP_ALL_VENDORS_FOUND_LOADED:
            return setValue(state, ['allVendorsFound'], action.allVendorsFound).set('allVendorsFoundPending', false);

        case types.APP_ALL_VENDORS_FOUND_ERROR:
            return setValue(state, ['allVendorsFound'], action.errorMessage).set('allVendorsFoundPending', false);

        case types.APP_VENDORS_REJECT_ALL_PENDING:
            return setPending(state, ['vendorsAfterRejectAll']).set('vendorsAfterRejectAllPending', true);
            
        case types.APP_VENDORS_REJECT_ALL_LOADED:
            return setValue(state, ['vendorsAfterRejectAll'], action.vendorsAfterRejectAll).set('vendorsAfterRejectAllPending', false);
    
        case types.APP_VENDORS_REJECT_ALL_PENDING:
            return setValue(state, ['vendorsAfterRejectAll'], action.errorMessage).set('vendorsAfterRejectAllPending', false);

        case types.SET_METRICS_LIST_APP:
            return setValue(state, ['selectedMetrics'], action.selectedMetrics);

        case types.SET_AVAILABLE_METRICS_APP:
            return setValue(state, ['metricsList'], action.metricsList);

        case types.SET_SELECTED_APP_FILTERS:
            return setValue(state, ['summaryAppDiagnose'], [])
                .setIn(['selectedAppFilters'].concat('value'), action.selectedAppFilters)
                .setIn(['vendorsNotOnVendorList'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['cookiesWithLongLifespansList'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['allCookiesFoundList'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['dataLeavingEeaList'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['possibleFingerprintingList'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['vendorsPriorToConsentList'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['disclosedVendors'].concat('value'), { results: new List([]), total_count: 0 })
                .setIn(['vendorsAfterOptOut'].concat('value'), { results: new List([]), total_count: 0 });

           
    };
    return state;
}

