import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import  moment  from 'moment';
import { SearchOutlined } from "@ant-design/icons";
import { Table, Typography, Row, Col, Input, Button, Modal, Flex, Space,theme,Spin } from 'antd'
import { useDebouncedSearch } from "../../../hooks";
import AddNewDiagnoseProperty from "./AddNewDiagnoseProperty";
import {
  getAllProperties,
} from '../../../actions/diagnose_property.js';
import {
  getAllSites,
} from '../../../actions/site_actions.js';


const { Title, Text } = Typography

const { useToken } = theme;

const PropertiesDashboard = (props) => {

  const [searchValue, setSearchValue] = useState('');
  const [showAddDiagnosePropertyModal, setAddDiagnosePropertyModal] = useState(false);

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);


  useEffect(() => {
    props.getAllProperties(props.currentUser?.accountId)
  },[])


  const columns = [
    {
      title: 'Property ID',
      dataIndex: 'dialogue_property_id',
      key: 'id',
      width:"33.13%",
      sorter: (a, b) => a.dialogue_property_id - b.dialogue_property_id,
    },
    {
      title: 'Property',
      dataIndex: 'diagnose_website_name',
      key: 'domain',
      width:"33.13%",
      onFilter: (value, record) => record.diagnose_website_name.indexOf(value) === 0,
      sorter: (a, b) => a.diagnose_website_name.localeCompare(b.diagnose_website_name),
    },
    {
      title: 'Creation Date',
      dataIndex: 'created_at',
      key: 'createdAt',
      width:"33.13%",
      render: (value) => (moment(value).format('MMM-DD-YYYY')),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(), 
    },
  ];

  const refreshPropertyList = () => {
   props.getAllProperties(props.currentUser?.accountId);
  // props.getAllSites();
  }

  const { token } = useToken();
  const data = props?.sites?.length> 0 ?props?.sites?.filter(site => site.type === "app"): [];
  let dataSource = searchValue.trim().length ? _.cloneDeep(data)?.filter(property => property.diagnose_website_name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || property.dialogue_property_id.toString().indexOf(searchValue.trim().toLowerCase()) !== -1) : _.cloneDeep(data)
  return (
    <>
    <Row gutter={[0, token.margin]}>
      <Col span={24}>
        <Title level={2}>Properties</Title>
      </Col>
      <Row justify="space-between" style={{ width: "100%"}}>
        <Col span={6}>
        <Input
          placeholder="Search Properties"
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value.trim().toLocaleLowerCase());
          }}
          suffix={<SearchOutlined />}
        />
          </Col>
        <Row justify="end">
        <Col>
          <Button type="primary" onClick={() => setAddDiagnosePropertyModal(true)}>+ Add New Property</Button>
        </Col>
        </Row>
      </Row>
      <Col span={24}>
      <Table
          columns={columns}
          dataSource={dataSource}
          loading={props?.pending}
          pagination={{ position: ['bottomCenter'], pageSize:"10" }}
        />
      </Col>
      </Row>
      <AddNewDiagnoseProperty
        refreshPropertyList={refreshPropertyList}
        showAddDiagnosePropertyModal={showAddDiagnosePropertyModal}
        handleCancel={() => setAddDiagnosePropertyModal(false)}
      />
    </>
  )
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.diagnoseDashboardState.getIn(['appPropertiesList', 'value']),
    pending: store.diagnoseDashboardState.getIn(['appPropertiesListPending', 'pending']),
    //sites: store.siteState.getIn(['sites', 'value']),
    //pending: store.siteState.getIn(['sites', 'pending']),
    error: store.siteState.getIn(['sites', 'error']),
  };
};

export default connect(mapStateToProps,{getAllProperties,getAllSites})(PropertiesDashboard);