import { CloseOutlined, DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Image, Modal, Select, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cookieNonsecure from '../../../../../assets/images/diagnose/Cookie_not_locked.svg';
import cookieIcon from '../../../../../assets/images/diagnose/cookie.svg';
import cookieFilledIcon from '../../../../../assets/images/diagnose/cookie_filled.svg';
import {
  cookieDetailsExport,
  getCookieDetailsList,
  getCookieDetailsListFilters,
  getVendorsAfterOptOutCookiesData,
  getVendorsAfterOptOutCookiesFilters,
  exportCSVVendorsAfterOptOutCookies,
} from '../../../../../actions/diagnose_dashboard_actions';
import { getPropertiesData } from '../../../../../api/diagnose_dashboard/diagnose_dashboard';
import useTableDisplayConfig from '../useTableDispayConfig';

const CookieDetailsModal = ({
  data,
  selectedFilters,
  getCookieDetailsList,
  cookieDetailsList,
  cookieDetailsListPending,
  getCookieDetailsListFilters,
  cookieDetailsListFilter,
  cookieDetailsExport,
  cookieDetailsExportPending,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder, setLimit } = useTableDisplayConfig(
    10,
    1,
    'cookie_size_desc',
  );

  const [benchmarks] = useState((selectedFilters?.benchmark_websites).join(';'));
  const [region] = useState((selectedFilters?.regions).join(';'));

  const [cookieNames, setCookieNames] = useState('');
  const [partyCookie, setPartyCookie] = useState('');
  const [cookieCategories, setCookieCategories] = useState('');
  const [cookieSameSite, setCookieSameSite] = useState('');
  const [cookieHost, setCookieHost] = useState('');
  const [isCookieFlagged, setIsCookieFlagged] = useState('');
  const [isCookiePersistent, setIsCookiePersistent] = useState('');
  const [isCookieSecure, setIsCookieSecure] = useState('');

  const [propertyApplied, setPropertyApplied] = useState(selectedFilters?.websites);
  const [propertiesList, setPropertiesList] = useState([]);

  const getCookieDetailsData = () => {
    getCookieDetailsList({
      vendorId: data.vendor_id,
      dateFrom: moment(selectedFilters?.start).format('YYYY-MM-DD'),
      dateTo: moment(selectedFilters?.end).format('YYYY-MM-DD'),
      page,
      limit,
      orderBy,
      benchmarks,
      properties: propertyApplied.join(';'),
      region,
      cookieNames,
      partyCookie,
      isCookieFlagged,
      cookieCategories,
      isCookiePersistent,
      isCookieSecure,
      cookieSameSite,
      cookieHost,
    });
  };

  const getCookieDetailsFiltersData = () => {
    getCookieDetailsListFilters({
      vendorId: data.vendor_id,
      dateFrom: moment(selectedFilters?.start).format('YYYY-MM-DD'),
      dateTo: moment(selectedFilters?.end).format('YYYY-MM-DD'),
      benchmarks,
      properties: propertyApplied.join(';'),
      region,
    });
  };

  const cookieNameFilter = cookieDetailsListFilter?.cookie_name?.map((filter) => ({ text: filter, value: filter }));
  const cookieCategoryFilter = cookieDetailsListFilter?.cookie_category?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const cookieSameSiteFilter = cookieDetailsListFilter?.cookie_same_site?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const cookieHostFilter = cookieDetailsListFilter?.cookie_host?.map((filter) => ({ text: filter, value: filter }));

  const showModal = () => {
    getPropertiesData().then((resp) => {
      let list = [];
      selectedFilters?.websites.map((selectedWebsiteId) => {
        resp.map((website) => {
          if (website.value === selectedWebsiteId) {
            list.push(website);
          }
        });
      });
      setPropertiesList(list);
    });
    setIsModalVisible(true);
    getCookieDetailsFiltersData();
    getCookieDetailsData();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setLimit(10);
    handlePageChange(1);
    setPropertyApplied(selectedFilters?.websites);
  };

  useEffect(() => {
    isModalVisible && getCookieDetailsData();
  }, [
    page,
    orderBy,
    limit,
    cookieNames,
    partyCookie,
    cookieCategories,
    isCookieFlagged,
    isCookiePersistent,
    isCookieSecure,
    cookieSameSite,
    cookieHost,
    propertyApplied,
  ]);

  const Option = Select.Option;
  const columns = [
    {
      title: () => {
        return (
          <div>
            Cookie name
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    This is the name which the vendor has given to the cookie they are setting on the user
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'cookie_name',
      key: 'cookie_name',
      filters: cookieNameFilter ? cookieNameFilter : [],
      filterSearch: true,
      sorter: true,
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Flagged
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    Cookies are flagged if they meet at least one of the following criteria: non-secure, persistent, or
                    large (over 100b).
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'flagged',
      key: 'flagged',
      render: (text, record) => {
        return (
          <>
            {!record.secure && (
              <span title="Non-Secure" style={{ marginRight: '5px' }}>
                <Image alt="Non-Secure" src={cookieNonsecure} preview={false} />{' '}
              </span>
            )}
            {record.persistent && (
              <span title="Persistent Cookies" style={{ marginRight: '5px' }}>
                <Image alt="Persistent cookies" src={cookieIcon} preview={false} />{' '}
              </span>
            )}
            {record.cookie_size > 100 && (
              <span title="Large" style={{ marginRight: '5px' }}>
                <Image alt="Large" src={cookieFilledIcon} preview={false} />
              </span>
            )}
          </>
        );
      },
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            1st or 3rd Party
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    1st party cookies are created by the domain of the property selected and can only be used to track
                    user behaviour on the domain that created it. 3rd party cookies are created by a 3rd party domain
                    and can be used to track a user cross-domains.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'party_cookie',
      key: 'party_cookie',
      filters: [
        { text: '1st', value: '1st' },
        { text: '3rd', value: '3rd' },
      ],
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Cookie Category
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    Cookies are categorised by Sourcepoint into one of 4 categories (based on the ePrivacy cookie
                    purposes) by reviewing the function of the technology used. The categories are:
                    Targeting/Advertising, Performance, Functionality or Strictly Necessary. For any technologies that
                    are uncategorised or if you wish to question one of the categorisations please reach out to your
                    Sourcepoint account manager.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'category',
      key: 'category',
      filters: cookieCategoryFilter ? cookieCategoryFilter : [],
      filterSearch: true,
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Persistent / Session
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    Persistent cookies store information in the user’s browser for a long time. Session cookies
                    generally expire when the browser closes
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'persistent',
      key: 'persistent',
      render: (text) => <div> {text ? 'Persistent' : 'Session'} </div>,
      filters: [
        { text: 'Persistent', value: true },
        { text: 'Session', value: false },
      ],
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Secure / Non-Secure
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    If the cookies are secure, the cookie's confidentiality is protected from attackers. For cookies
                    that store sensitive or personal information it is recommended at a minimum that secure cookies are
                    used.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'secure',
      key: 'secure',
      render: (text) => <div> {text ? 'Secure' : 'Non-Secure'} </div>,
      filters: [
        { text: 'Secure', value: true },
        { text: 'Non-Secure', value: false },
      ],
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Samesite
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    The SameSite attribute tells the browser when and how to fire cookies. SameSite is used by a variety
                    of browsers to identify whether or not to allow a cookie to be accessed.
                    <ul>
                      <li>
                        <b>lax</b> enables only first-party cookies to be sent/accessed
                      </li>
                      <li>
                        <b>strict</b> is a subset of <b>lax</b> and won’t fire if the incoming link is from an external
                        site (used mainly by risk averse institutions such as banks)
                      </li>
                      <li>
                        <b>none</b> signals that the cookie data can be shared with third parties/external sites (for
                        advertising, embedded content, etc)
                      </li>
                      <li>
                        if it is not set, browsers may interpret this in different ways. Chrome will default to{' '}
                        <b>lax</b>
                      </li>
                    </ul>
                    You can use this to check cookies firing on your website have been labelled correctly.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay tooltip-overlay-wide-350"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'same_site',
      key: 'same_site',
      filters: cookieSameSiteFilter ? cookieSameSiteFilter : [],
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Cookie Creation Method
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    A browser cookie is created by one of two methods; either by the client-side JavaScript or a server
                    during an HTTP request. HTTP-only cookies cannot be accessed by Javascript and are generally more
                    secure. Javascript cookies can be open to theft if a domain is not specified when they are written
                    to the browser.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'is_javascript_cookie',
      key: 'is_javascript_cookie',
      render: (text) => <div> {text ? 'Javascript' : 'HTTP'} </div>,
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Cookie Size (b)
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    This is the size of the cookie in bytes. We would consider anything over 100b to be large.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'cookie_size',
      key: 'cookie_size',
      sorter: true,
      defaultSortOrder: 'descend',
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Cookie Duration
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    This is the length of time from when the cookie was set to when it expires in days
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'life_span',
      key: 'life_span',
      sorter: true,
      width: 100,
    },
    {
      title: () => {
        return (
          <div>
            Host Domain
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    This is the host domain from which the cookie was set
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'host',
      key: 'host',
      filters: cookieHostFilter ? cookieHostFilter : [],
      width: 150,
    },
    {
      title: () => {
        return (
          <div>
            Cookie Domain
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    This is the cookie domain from which the cookie was set
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'cookie_domain',
      key: 'cookie_domain',
      width: 150,
    },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);

    for (const filter in filters) {
      if (filters[filter]) {
        filter === 'cookie_name' && setCookieNames(filters[filter].join(';'));
        filter === 'party_cookie' && setPartyCookie(filters[filter].join(';'));
        filter === 'category' && setCookieCategories(filters[filter].join(';'));
        filter === 'same_site' && setCookieSameSite(filters[filter].join(';'));
        filter === 'host' && setCookieHost(filters[filter].join(';'));
        filter === 'flagged' && setIsCookieFlagged(filters[filter].join(';'));
        filter === 'persistent' && setIsCookiePersistent(filters[filter].join(';'));
        filter === 'secure' && setIsCookieSecure(filters[filter].join(';'));
      } else {
        filter === 'cookie_name' && setCookieNames('');
        filter === 'party_cookie' && setPartyCookie('');
        filter === 'category' && setCookieCategories('');
        filter === 'same_site' && setCookieSameSite('');
        filter === 'host' && setCookieHost('');
        filter === 'flagged' && setIsCookieFlagged('');
        filter === 'persistent' && setIsCookiePersistent('');
        filter === 'secure' && setIsCookieSecure('');
      }
    }
  };

  const onPaginationChange = (pageNumber, pageSize) => {
    handlePageChange(pageNumber);
  };
  const exportCsv = () => {
    cookieDetailsExport({
      vendorId: data.vendor_id,
      dateFrom: moment(selectedFilters?.start).format('YYYY-MM-DD'),
      dateTo: moment(selectedFilters?.end).format('YYYY-MM-DD'),
      benchmarks,
      properties: propertyApplied.join(';'),
      region,
      vendorName: data.name,
    });
  };

  const handleSelectPropertyApplied = (value) => {
    if (value.length) {
      setPropertyApplied(value);
    }
  };

  return (
    <>
      <button className={'anchor-button'} onClick={showModal}>
        View
      </button>
      <Modal
        wrapClassName={'cookie-details-modal'}
        closeIcon={<CloseOutlined style={{ color: 'blue' }} />}
        title={
          <div>
            Cookie Details - {data?.name}{' '}
            <span>
              [{moment(selectedFilters?.start).format('ll')} - {moment(selectedFilters?.end).format('ll')}]
            </span>
          </div>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
        width={850}
      >
        <div>
          <div className={'filters_block'}>
            <div>
              <div>Property applied: </div>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                popupClassName={'diagnose-dashboard-select cookie-details-property-applied-dropdown'}
                allowClear={true}
                placeholder={'Property applied'}
                mode="multiple"
                value={propertyApplied}
                onChange={handleSelectPropertyApplied}
                size="medium"
                maxTagCount={1}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {propertiesList?.map((site, index) => {
                  return (
                    <Option key={`property-${site?.value}`} value={site?.value}>
                      {site?.title}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div>
              <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={cookieDetailsExportPending} />
            </div>
          </div>
          <Table
            id="cookie-details-data"
            getPopupContainer={(trigger) => document.getElementById('cookie-details-data')}
            scroll={{ x: 'max-content', y: 430 }}
            dataSource={cookieDetailsList?.results}
            columns={columns}
            showSorterTooltip={false}
            rowKey={(record) => record?.cookie_name + record.value}
            pagination={{
              current: page,
              pageSize: limit,
              total: cookieDetailsList?.total_count,
              position: ['bottomCenter'],
              onChange: onPaginationChange,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
            }}
            onChange={onTableChange}
            loading={cookieDetailsListPending}
          />
          <div>Total Cookies : {cookieDetailsList?.total_count}</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = function (store, props) {
  if (props.metric === 'Vendors after opt out') {
    return {
      cookieDetailsList: store.diagnoseDashboardState.getIn(['vendorsAfterOptOutCookie', 'value']),
      cookieDetailsListPending: store.diagnoseDashboardState.get('vendorsAfterOptOutCookiePending'),
      selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
      cookieDetailsListFilter: store.diagnoseDashboardState.getIn(['vendorsAfterOptOutCookieFilter', 'value']),
      cookieDetailsExportPending: store.diagnoseDashboardState.get('vendorsAfterOptOutCookieExportPending'),
    };
  }
  return {
    cookieDetailsList: store.diagnoseDashboardState.getIn(['cookieDetailsList', 'value']),
    cookieDetailsListPending: store.diagnoseDashboardState.get('cookieDetailsListPending'),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    cookieDetailsListFilter: store.diagnoseDashboardState.getIn(['cookieDetailsListFilter', 'value']),
    cookieDetailsExportPending: store.diagnoseDashboardState.get('cookieDetailsExportPending'),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  if (props.metric === 'Vendors after opt out') {
    return bindActionCreators(
      {
        getCookieDetailsList: getVendorsAfterOptOutCookiesData,
        getCookieDetailsListFilters: getVendorsAfterOptOutCookiesFilters,
        cookieDetailsExport: exportCSVVendorsAfterOptOutCookies,
      },
      dispatch,
    );
  }
  return bindActionCreators(
    {
      getCookieDetailsList,
      getCookieDetailsListFilters,
      cookieDetailsExport,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieDetailsModal);
