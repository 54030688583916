import React, { Component, createRef } from 'react';
import Emitter from 'quill/core/emitter';
import { message } from 'antd';
import { Select } from "../../../../../../../../styleguide";
import LinkPM from './LinkPM';
import MessageLinkPM from './MessageLinkPM';
import ChoiceOptions from './ChoiceOptions';
import ReactQuill, { Quill } from 'react-quill';
import { PrivacyManager } from '../../../../../../../../records/choice_config_records';
import { COUNTRY_LANGUAGES, UUID_MSG_VARIABLE } from '../../../../../../../../constants.js';
import VendorCount from './VendorCount';
import IdSetter from './IdSetter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const { Option } = Select;

const myRef = React.createRef();
const coRef = React.createRef();
const vCountRef = React.createRef();
const idRef = React.createRef();

var Link = ReactQuill.Quill.import('formats/link');
var builtInFunc = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
  const isSiteGroup = window.location.search && window.location.search.indexOf('site_group_id') >= 0 ? true : false;
  var val = typeof linkValueInput === 'object' ? linkValueInput.href : linkValueInput;

  // do nothing, since this implies user's already using a custom protocol
  if (/^\w+:/.test(val));

  else if (!(isSiteGroup && (val.indexOf('$$') === 0)) && !val.match(URL_REGEX)) {
    val = "http://" + val;
  }


  return builtInFunc.call(this, val); // retain the built-in logic
};

const Inline = ReactQuill.Quill.import('blots/inline');

class NamedLinkBlot extends Inline {
  static create(value) {
    const node = super.create();
    if (typeof value === 'object') {
      node.setAttribute('href', value.href);
      if (value.id) {
        node.setAttribute('id', value.id); // Set the `id` attribute
      }
    } else {
      node.setAttribute('href', value);
    }
    node.setAttribute('target', '_blank');
    node.setAttribute('aria-label', 'message-link');
    return node;
  }

  static formats(node) {
    const formats = {};
    if (node.hasAttribute('href')) {
      formats.href = node.getAttribute('href');
    }
    if (node.hasAttribute('id')) {
      formats.id = node.getAttribute('id');
    }
    return formats;
  }

  format(name, value) {
    if (!value) return super.format(name, value);

    if (name === 'link' && typeof value === 'object') {
      if (value.href) {
        this.domNode.setAttribute('href', value.href);
      }
      if (value.id) {
        this.domNode.setAttribute('id', value.id);
      }
      if (value.id === null) {
        this.domNode.removeAttribute('id');
      }
    } else {
      super.format(name, value);
    }
  }
}

NamedLinkBlot.blotName = 'link';
NamedLinkBlot.tagName = 'a';

// Register the custom blot
Quill.register(NamedLinkBlot, true);

class PmLinkBlot extends NamedLinkBlot {
  static blotName = 'linkPrivacyManager';
  static className = 'link-privacy-manager';
  static tagName = 'A';

  static create(value) {

    let choice;
    if (typeof value !== "string") {
      ({ choice } = value);
    } else {
      choice = value;
    }

    let node = super.create("javascript:void(0);");

    node.setAttribute('data-choice', choice);

    return node;
  }

  static formats(node) {
    let choice = node.getAttribute('data-choice')

    // fallback to href, the legacy way to attach choice options
    if (choice === null) {
      choice = node.getAttribute('href')
      if (choice !== null) {
        choice = choice.replace('$$$$:', '')
      }
    }

    return { choice }
  }
}
Quill.register(PmLinkBlot);

class CoLinkBlot extends PmLinkBlot {
  static blotName = 'linkChoiceOptions';
  static className = 'link-choice-options';
}
Quill.register(CoLinkBlot);

const CustomToolbar = (props) => {
  const prohibitedSubcategories = ['notice', 'native-ott', 'ios', 'us_pm'];
  return (
    <div id="toolbar">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      {/* <button className="ql-strike" /> */}
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      {props.messageSubcategory !== 'ios' && <button className="ql-link" />}
      {/* {props.messageSubcategory !== 'ios' && <button className="ql-code-block" />} */}
      {(props.features && (props.features.includes('custom_choices') || props.features.includes('adblock_v2') || props.accountId === 22)) && props.messageSubcategory !== 'ios' && (
        <ChoiceOptions
          ref={coRef}
          accountId={props.accountId}
          choiceOptions={props.choiceOptions}
          features={props.features}
          options={props.options}
          updateActions={props.updateChoiceOptions}
          messageSubcategory={props.messageSubcategory}
          category={props.category}
          isFromAdblock={props.isFromAdblock}
          isFromCustom={props.isFromCustom}
        />
      )}
      {props.messageSubcategory === 'notice' && props.messageSubcategory !== 'ios' && !props.isFromAdblock && !props.isFromCustom && (
        <LinkPM
          category={props.category}
          options={props.pmMessages}
          updateActions={props.updateActions}
          choiceOptions={props.choiceOptions}
          ref={myRef}
        />
      )}
      {!prohibitedSubcategories.includes(props.messageSubcategory) && !props.isFromAdblock && !props.isFromCustom && (
        <MessageLinkPM
          options={props.pmMessages}
          messageSubcategory={props.messageSubcategory}
          updateActions={props.updateActions}
          choiceOptions={props.choiceOptions}
          ref={myRef}
        />
      )}
      {['notice-tcf-v2', 'privacy-manager'].includes(props.messageSubcategory) && props.renderVendorCount && (
        <VendorCount ref={vCountRef} />
      )}
      <button className="ql-uuid-var" style={{ width: 35, fontSize: 10 }}>
        <span>
          UUID
        </span>
      </button>
      <IdSetter
        ref={idRef}
        linkIdValue={props.linkIdValue}
        setLinkIdValue={props.setLinkIdValue}
      />
    </div>
  )
};

async function SnowTooltipSave() {
  const { value } = this.textbox;
  const isSiteGroup = window.location.search && window.location.search.indexOf('site_group_id') >= 0 ? true : false;
  switch (this.root.getAttribute('data-mode')) {
    case 'link': {
      if (!(isSiteGroup && (value.indexOf('$$') === 0)) && !value.match(URL_REGEX)) {
        this.textbox.classList.add("ql-error")
        message.error('Enter valid url');
        return;
      }
      this.textbox.classList.remove("ql-error")
      const { scrollTop } = this.quill.root;
      if (this.linkRange) {
        this.quill.formatText(this.linkRange, 'link', value, Emitter.sources.USER);
        delete this.linkRange;
      } else {
        this.restoreFocus();
        this.quill.format('link', value, Emitter.sources.USER);
      }
      this.quill.root.scrollTop = scrollTop;
      break;
    }
    default:
  }
  this.textbox.value = '';
  this.hide();
}

async function linkHandler(value) {
  if (value) {
    const range = this.quill.getSelection();
    if (!range) return;

    const formats = this.quill.getFormat(range);
    let preview;

    if (formats.link && typeof formats.link === 'object' && formats.link.href) {
      preview = formats.link.href;
    } else if (typeof formats.link === 'string') {
      preview = formats.link;
    } else {
      // Fallback: traverse from the leaf
      const [leaf] = this.quill.getLeaf(range.index);
      let blot = leaf;
      let found = false;

      while (blot && blot.formats) {
        const current = blot.formats();
        if (current?.link) {
          preview = typeof current.link === 'object' ? current.link.href : current.link;
          found = true;
          break;
        }
        blot = blot.parent;
      }

      if (!found) {
        preview = this.quill.getText(range.index, range.length);
        if (preview && !/^\w+:\/\//.test(preview)) {
          preview = `https://${preview}`;
        }
      }
    }

    const { tooltip } = this.quill.theme;
    tooltip.linkRange = range;
    tooltip.save = SnowTooltipSave;
    tooltip.edit('link', preview);
  } else {
    this.quill.format('link', false);
  }
}

async function customLinkHandler(modal, formatName, formatArgsFn) {
  try {
    const format = this.quill.getFormat();

    let { index: selectionIndex, length: selectionLength } = this.quill.getSelection(true);
    const selectedText = this.quill.getText(selectionIndex, selectionLength);

    // this is annoying but we need to make sure the leaf is selected
    // otherwise the PM link may not get updated properly
    // so if we're within a link or our own format, select the whole leaf
    // seems like there should be an easier way to set selection to a leaf but...
    if (format['link'] || format[formatName]) {
      selectionIndex += 1;  // quill bug? giving it the index a leaf starts at means we will get the previous leaf back
      const [leaf, leafOffset] = this.quill.getLeaf(selectionIndex);
      this.quill.setSelection(selectionIndex - leafOffset, leaf.length());
    } else if (!selectedText.length || !selectedText.trim().length) {
      throw new Error('no text selected');
    }

    const result = await modal.show(format[formatName] && format[formatName].choice);
    if (result) {
      this.quill.format('link', false);
      this.quill.format(formatName, false);
      this.quill.format(formatName, formatArgsFn(result));
    }
  } catch (err) { }
}

async function vendorCountHandler(modal) {
  const result = await modal.show();

  const { index: selectionIndex, length: selectionLength } = this.quill.getSelection(true);
  this.quill.deleteText(selectionIndex, selectionLength)
  this.quill.insertText(selectionIndex, result)
}

function uuidVariableHandler() {
  const { index: selectionIndex, length: selectionLength } = this.quill.getSelection(true);
  this.quill.deleteText(selectionIndex, selectionLength)
  this.quill.insertText(selectionIndex, UUID_MSG_VARIABLE)
}

async function idSetterHandler(modal, component) {

  const range = this.getSelection(true);
  const formats = this.getFormat(range);
  if (range && range.length > 0) {

    if (formats.link && formats.link.id) {
      component.setState({ linkIdValue: formats.link.id })
    } else if (!formats.link) {
      return
    }
  } else {
    return
  }

  await modal.show();

  this.format("link", {
    href: formats.link.href,
    id: component.state.linkIdValue || null
  });

}

class TextLanguage extends Component {
  state = {
    visible: false,
    showQuill: true,
    linkIdValue: '',
  }

  constructor(props) {
    super(props)
    this.handleIdSetter = this.handleIdSetter.bind(this);
    this.quillRef = createRef()
  }

  handleIdSetter(component, quill) {
    idSetterHandler.call(quill, idRef.current, component)
  }

  modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        link: linkHandler,
        linkPrivacyManager: function () {
          return customLinkHandler.call(
            this,
            myRef.current,
            'linkPrivacyManager',
            (result) => ({ choice: result.data.button_text })
          )
        },
        linkChoiceOptions: function () {
          return customLinkHandler.call(
            this,
            coRef.current,
            'linkChoiceOptions',
            (result) => ({ choice: result.data.button_text })
          )
        },
        ['vendor-count']: function () {
          vendorCountHandler.call(this, vCountRef.current)
        },
        ['uuid-var']: function () {
          uuidVariableHandler.call(this)
        },
        ['id-setter']: () => {
          this.handleIdSetter(this, this.quillInstance);
        },
      }
    },
  }

  formats = [
    'bold',
    'italic',
    'underline',
    // 'strike',
    'list',
    'bullet',
    'link',
    // 'code-block',
    'linkPrivacyManager',
    'linkChoiceOptions',
    'vendor-count',
    'uuid-var',
    'id-setter',
  ]

  componentDidMount() {
    if (this.quillRef.current) {
      this.quillInstance = this.quillRef.current.getEditor();
      this.attachLinkClickHandler();
    }
  
    const { languages } = this.props.allSettings;
    if (!languages || Object.keys(languages).length === 0) {
      this.updateText(this.props.value);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentComponentIndex !== this.props.currentComponentIndex) {
      this.forceQuillRemount();
    }
  
    if (!prevState.showQuill && this.state.showQuill) {
      if (this.quillRef.current) {
        this.quillInstance = this.quillRef.current.getEditor();
        this._linkClickHandlerAttached = false;
        this.attachLinkClickHandler();
      }
    }
  }
  
  attachLinkClickHandler = () => {
    if (!this.quillInstance) return;
  
    if (this._linkClickHandlerAttached) return;
    this._linkClickHandlerAttached = true;
  
    const editorRoot = this.quillInstance.root;
  
    editorRoot.addEventListener('click', (e) => {
      const linkEl = e.target.closest('a');
      if (!linkEl) return;
  
      e.preventDefault();
  
      const blot = Quill.find(linkEl);
      if (!blot) return;
  
      const index = this.quillInstance.getIndex(blot);
      const length = blot.length();
  
      this.quillInstance.setSelection(index, length, 'user');
  
      setTimeout(() => {
        linkHandler.call({ quill: this.quillInstance }, true);
      }, 0);
    });
  };
  

  forceQuillRemount = (values = {}) => this.setState({ showQuill: false, ...values }, () => this.setState({ showQuill: true }))

  updateText = (value) => {
    const {
      settingKey,
      defaultLanguage,
      updateSetting,
      allSettings,
      allSettings: {
        languages = {},
      },
    } = this.props;
    const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
    const languagesClone = Object.assign({}, languages || {});
    if (!languagesClone[selectedLanguage]) {
      languagesClone[selectedLanguage] = {};
    };
    languagesClone[selectedLanguage][settingKey] = value;

    updateSetting('languages', languagesClone);

    if (defaultLanguage === selectedLanguage) {
      updateSetting(settingKey, value);
    };
  }

  updateLanguage = (language) => {
    this.props.updateSetting('selectedLanguage', language);
    this.forceQuillRemount({ language });
  }

  updateActions = (choice) => {
    const choiceOptions = this.getPrivacyManagerOption()
    let { type, data, ...optionalKeypairs } = choice;
    const exists = choiceOptions.find(o => o.data.button_text === data.button_text);
    data = new PrivacyManager(data).toJS()
    if (data.hasOwnProperty('enabled')) delete data.enabled;
    if (!exists) choiceOptions.push({ type: 12, data, ...optionalKeypairs });
    this.props.updateSetting('choice_options', choiceOptions);
  }

  getPrivacyManagerOption = () => {
    if (this.props?.selected_privacy_manager) {
      return [...this.props.selected_privacy_manager]
    } if (this.props?.allSettings?.choice_options) {
      return [...this.props.allSettings.choice_options]
    } else {
      return []
    }
  }

  updateChoiceOptions = (choice) => {
    const choiceOptions = this.getPrivacyManagerOption();
    const exists = choiceOptions.find(o => o.data.button_text === choice.data.button_text);
    if (choice.hasOwnProperty('enabled')) delete choice.enabled;
    if (!exists) choiceOptions.push(choice);

    this.props.updateSetting('choice_options', choiceOptions);
  }

  render() {
    const {
      messageSubcategory,
      pmMessages,
      category,
      value,
      defaultLanguage,
      allSettings,
      accountId,
      features,
      allSettings: {
        languages = {},
        isDynamic,
      },
      settingKey
    } = this.props;

    const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
    const currentValue = (defaultLanguage === selectedLanguage)
      ? value
      : (languages[selectedLanguage] !== undefined)
        ? languages[selectedLanguage].text
        : '';

    const isFromAdblock = category === 'adblock' && messageSubcategory === 'notice';
    const isFromCustom = category === 'custom' && messageSubcategory === 'custom';

    return (
      <div className="text-editor">
        <div className="quill-container">
          <div className="screen" style={{ display: !isDynamic && 'none' }} />
          <CustomToolbar
            options={this.props.pmList}
            category={category}
            messageSubcategory={messageSubcategory}
            isFromAdblock={isFromAdblock}
            isFromCustom={isFromCustom}
            pmMessages={pmMessages}
            updateActions={this.updateActions}
            updateChoiceOptions={this.updateChoiceOptions}
            language={selectedLanguage}
            updateLanguage={this.updateLanguage}
            accountId={accountId}
            features={features}
            choiceOptions={this.props.allSettings.choice_options}
            renderVendorCount={settingKey !== 'iframeTitle'}
            linkIdValue={this.state.linkIdValue}
            setLinkIdValue={(linkIdValue) => this.setState({ linkIdValue })}
            uuidVariableHandler={uuidVariableHandler}
          />
          {this.state.showQuill && (
            <ReactQuill
              ref={this.quillRef}
              theme={'snow'}
              disabled={isDynamic}
              onChange={this.updateText}
              defaultValue={currentValue}
              modules={this.modules}
              formats={this.formats}
            />
          )}
        </div>
        <div className="text-language">
          <h4>Language: </h4>
          <Select disabled={isDynamic} onChange={this.updateLanguage} value={selectedLanguage}>
            {COUNTRY_LANGUAGES.map(({ code, language }) => <Option key={code}>{language}</Option>)}
          </Select>
        </div>
      </div>
    );
  }
}

export default TextLanguage;