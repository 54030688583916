import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Typography, Row, Col, theme, Space } from 'antd';
import { browserHistory } from 'react-router';

const { Text, Title, Paragraph } = Typography;

import {
  FirstAppearedScanColumnApp as FirstAppearedScanColumn,
  LastAppearedScanColumnApp as LastAppearedScanColumn,
  PrevalenceColumnApp as PrevalenceColumn,
  VendorNameColumnApp as VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumnApp as VendorStatusColumn,
  VendorTypeColumn,
  PrivacyPolicyColumn,
  VendorDomainColumn,
  PrevalenceColumnApp
} from '../../shared/DiagnoseColumns';

import { getVendorsAfterRejectList } from '../../../../../actions/app_dashboard_actions';
import {
  getVendorManagementStatusesList,
  getVendorsPriorToConsentFilter,
  vendorsPriorToConsentExport,
} from '../../../../../actions/diagnose_dashboard_actions';
import TableFilterDropdown from '../../shared/TableFilterDropdown';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  isFiltersProvided,
  mapSelectedFiltersApp,
  requestDataDependOnTableFilters,
  getUniqueVendorIdsListApp,
} from '../../details_pages/helpers';
import VendorDomainModal from '../VendorDomainModal';

const DetailedBreakdown = ({
  getVendorsAfterRejectList,
  vendorsAfterRejectAllList,
  vendorsAfterRejectAllListPending,
  selectedAppFilters,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFiltersApp(selectedAppFilters), [selectedAppFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const [modalOpenType, setModalOpenType] = useState(false);
  const [vendorDomainModalData, setVendorDomainModalData] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const { token } = theme.useToken();

  const getVendorsAfterRejectListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getVendorsAfterRejectList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
      accountId: userDetails?.accountId,
    });
    return res;
  };

  // const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
  //   const query = {
  //     metric: 'Vendors after opt out',
  //     vendors,
  //     status,
  //     tag,
  //     websites: selectedAppFilters?.websites,
  //   };
  //   const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
  //   if (isCacheValid) {
  //     return vendorManagementStatuses;
  //   }
  //   const res = await getVendorManagementStatusesList(query);
  //   return res;
  // };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      // getVendorsAfterRejectListData(filters).then((res) => {
      //   getVendorManagementStatusesListData({ ...filters, vendors: getUniqueVendorIdsListApp(res?.vendors) })
      // })
      getVendorsAfterRejectListData(filters);
      // requestDataDependOnTableFilters(
      //   { filters: filters || {}, isEnabledVendorStatusManagementFeature },
      //   (f, vendorId) => getVendorsPriorToConsentListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
      //   getVendorManagementStatusesListData,
      // );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      // getVendorsPriorToConsentFiltersData();
    }
  }, [filters]);

  // useEffect(() => {
  //   if (vendorManagementActivities?.length) {
  //     getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsListApp(vendorsAfterRejectAllList?.vendors) }, false);
  //   }
  // }, [vendorManagementActivities]);

  const openVendorDomainModal = (vendorDomain) => {
    setModalOpenType(true);
    setVendorDomainModalData(vendorDomain);
  };

  const vendorsFilter = vendorsAfterRejectAllList?.vendors?.map((filter) => ({
    text: filter.name,
    value: filter.name,
  }));
  const technologiesFilter = Array.from(
    new Set(vendorsAfterRejectAllList?.vendors?.map((filter) => filter?.vendor_categorization)),
  ).map((value) => ({ text: value, value }));

  const columns = [
    VendorNameColumn({ filters: vendorsFilter || [] }),
    // ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    {
      title: 'Vendor Categorization',
      dataIndex: 'vendor_categorization',
      key: 'vendor_categorization',
      sorter: (a, b) => {
        const firstTagA = a.vendor_categorization.split(';')[0].trim(); // Get the first tag of 'a'
        const firstTagB = b.vendor_categorization.split(';')[0].trim(); // Get the first tag of 'b'
        return firstTagA.localeCompare(firstTagB);
      },
      filters: technologiesFilter,
      onFilter: (value, record) => record.vendor_categorization.includes(value),
      // filterDropdown: (props) => (
      //   <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
      // ),
      width: 228,
    },
    PrevalenceColumnApp,
    FirstAppearedScanColumn,
    LastAppearedScanColumn,
    PrivacyPolicyColumn,
    VendorDomainColumn(openVendorDomainModal)
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    vendorsPriorToConsentExport(filters);
  };

  const tableData = useMemo(() => {
    // if (!isEnabledVendorStatusManagementFeature) {
    //   return vendorsAfterRejectAllList?.data
    // }

    return vendorsAfterRejectAllList?.data
  }, [isEnabledVendorStatusManagementFeature, vendorsAfterRejectAllList?.data]);

  return (
    <div className={'app-detailed-breakdown-container'}>
      <Row gutter={[0, 16]}>
        <Col span={24} gutter={[0, 4]}>
          <Title level={5}>Detailed Breakdown</Title>
          <Paragraph className="subtext">
            The table below lists vendors observed on your app properties firing after a user has rejected all. Use this
            information to determine whether to remove or contact these vendors. Consider if the vendor is permitted to
            process personal data despite the rejection. "Firing After Reject All" doesn't necessarily mean the vendor
            is accessing information, but it’s recommended to scrutinise these vendors according to regulatory
            guidelines.
          </Paragraph>
        </Col>
        <Col span={24}>
          <Table
            id="detailed-breakdown"
            scroll={{ x: 'max-content', y: 430 }}
            rowKey={(record) => record?.vendor_id}
            rowClassName={(record) =>
              record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
                ? 'diagnose-notification-highlight'
                : ''
            }
            dataSource={tableData}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              current: page,
              pageSize: limit,
              total:
                tableFilters?.vendor_status?.length && !vendorManagementStatuses.length
                  ? 0
                  : vendorsAfterRejectAllList?.total_count,
              position: ['bottomCenter'],
              onChange: onPaginationChange,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
            }}
            onChange={onTableChange}
            loading={{
              className: 'diagnose-spinning',
              spinning: vendorsAfterRejectAllListPending || vendorManagementStatusesPending,
              tip: vendorsAfterRejectAllList?.vendors?.length
                ? ''
                : 'Processing a large query; please wait or leave it open and check later.',
              size: 'large',
            }}
          />
        </Col>
      </Row>
      {modalOpenType && (
        <VendorDomainModal
          modalOpenType={modalOpenType}
          data={vendorDomainModalData}
          onClose={() => {
            setModalOpenType(false);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorsAfterRejectAllList: store.appDashboardState.getIn(['vendorsAfterRejectAll', 'value']),
    vendorsAfterRejectAllListPending: store.appDashboardState.get('vendorsAfterRejectAllPending'),
    selectedAppFilters: store.appDashboardState.getIn(['selectedAppFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVendorsAfterRejectList,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
