import React, { Component } from 'react'
import { Infotip } from '../../../../../../../../styleguide'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'

class IdSetter extends Component {
  state = { shown: false }

  promiseInfo = {}

  show = async () => {
    return new Promise((resolve, reject) => {
      this.promiseInfo = { resolve, reject }
      this.setState({ shown: true })
    })
  }

  save = async () => {
    this.hide()
    return this.promiseInfo.resolve()
  }

  hide = () => {
    try {
    } catch (_) {
      this.promiseInfo.reject()
    }
    this.setState({ shown: false })
  }

  render() {
    const { linkIdValue, setLinkIdValue } = this.props
    const menu = (
      <div>
        <div>
          <input
            value={linkIdValue}
            onInput={({ target }) => setLinkIdValue(target.value)}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
          <Button
            style={{ width: '45%' }}
            type='primary'
            onClick={() => this.save()}
          >
            ADD ID
          </Button>
          <Button
            style={{ width: '45%' }}
            variant='outlined'
            onClick={() => this.hide()}
          >
            CANCEL
          </Button>
        </div>
      </div>
    )

    return (
      <button id="ql-id-setter" className="ql-id-setter" style={{ width: 21 }} >
        <Infotip
          open={this.state.shown}
          placement="bottomLeft"
          className="id-infotip"
          content={menu}
          trigger="click"
        >
          <span>
            <FontAwesomeIcon icon={faHashtag} />
          </span>
        </Infotip>
      </button>
    )
  }
}

export default IdSetter