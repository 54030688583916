import React from 'react';

export const countryData = {
    EEA: [
      { value: "AT", title: "Austria" },
      { value: "BE", title: "Belgium" },
      { value: "CH", title: "Switzerland" },
      { value: "DE", title: "Germany" },
      { value: "DK", title: "Denmark" },
      { value: "ES", title: "Spain" },
      { value: "FI", title: "Finland" },
      { value: "FR", title: "France" },
      { value: "IE", title: "Ireland" },
      { value: "IS", title: "Iceland" },
      { value: "IT", title: "Italy" },
      { value: "LU", title: "Luxembourg" },
      { value: "NL", title: "Netherlands" },
      { value: "NO", title: "Norway" },
      { value: "PL", title: "Poland" },
      { value: "RO", title: "Romania" },
      { value: "SE", title: "Sweden" },
      { value: "SK", title: "Slovakia" },
      { value: "UK", title: "United Kingdom" }
    ],
    OTHER: [
      { value: "AF", title: "Afghanistan" },
      { value: "AU", title: "Australia" },
      { value: "BR", title: "Brazil" },
      { value: "CA", title: "Canada" },
      { value: "CN", title: "China" },
      { value: "CO", title: "Colombia" },
      { value: "DO", title: "Dominican Republic" },
      { value: "HK", title: "Hong Kong" },
      { value: "IN", title: "India" },
      { value: "JP", title: "Japan" },
      { value: "MX", title: "Mexico" },
      { value: "MY", title: "Malaysia" },
      { value: "NZ", title: "New Zealand" },
      { value: "PH", title: "Philippines" },
      { value: "TR", title: "Turkey" },
      { value: "TW", title: "Taiwan, Province of China" },
      { value: "UA", title: "Ukraine" },
      { value: "ZA", title: "South Africa" }
    ],
    CA: [
      { value: "AB", title: "Alberta" },
      { value: "BC", title: "British Columbia" },
      { value: "MB", title: "Manitoba" },
      { value: "NB", title: "New Brunswick" },
      { value: "NL", title: "Newfoundland and Labrador" },
      { value: "NS", title: "Nova Scotia" },
      { value: "NT", title: "Northwest Territories" },
      { value: "NU", title: "Nunavut" },
      { value: "ON", title: "Ontario" },
      { value: "PE", title: "Prince Edward Island" },
      { value: "QC", title: "Quebec" },
      { value: "SK", title: "Saskatchewan" },
      { value: "YT", title: "Yukon Territory" }
    ],
    US: [
      { value: "AK", title: "Alaska" },
      { value: "AL", title: "Alabama" },
      { value: "AR", title: "Arkansas" },
      { value: "AS", title: "American Samoa" },
      { value: "AZ", title: "Arizona" },
      { value: "CA", title: "California" },
      { value: "CO", title: "Colorado" },
      { value: "CT", title: "Connecticut" },
      { value: "DC", title: "District of Columbia" },
      { value: "DE", title: "Delaware" },
      { value: "FL", title: "Florida" },
      { value: "FM", title: "Micronesia, Federated States of" },
      { value: "GA", title: "Georgia" },
      { value: "GU", title: "Guam" },
      { value: "HI", title: "Hawaii" },
      { value: "IA", title: "Iowa" },
      { value: "ID", title: "Idaho" },
      { value: "IL", title: "Illinois" },
      { value: "IN", title: "Indiana" },
      { value: "KS", title: "Kansas" },
      { value: "KY", title: "Kentucky" },
      { value: "LA", title: "Louisiana" },
      { value: "MA", title: "Massachusetts" },
      { value: "MD", title: "Maryland" },
      { value: "ME", title: "Maine" },
      { value: "MH", title: "Marshall Islands" },
      { value: "MI", title: "Michigan" },
      { value: "MN", title: "Minnesota" },
      { value: "MO", title: "Missouri" },
      { value: "MP", title: "Northern Mariana Islands" },
      { value: "MS", title: "Mississippi" },
      { value: "MT", title: "Montana" },
      { value: "NC", title: "North Carolina" },
      { value: "ND", title: "North Dakota" },
      { value: "NE", title: "Nebraska" },
      { value: "NH", title: "New Hampshire" },
      { value: "NJ", title: "New Jersey" },
      { value: "NM", title: "New Mexico" },
      { value: "NV", title: "Nevada" },
      { value: "NY", title: "New York" },
      { value: "OH", title: "Ohio" },
      { value: "OK", title: "Oklahoma" },
      { value: "OR", title: "Oregon" },
      { value: "PA", title: "Pennsylvania" },
      { value: "PR", title: "Puerto Rico" },
      { value: "PW", title: "Palau" },
      { value: "RI", title: "Rhode Island" },
      { value: "SC", title: "South Carolina" },
      { value: "SD", title: "South Dakota" },
      { value: "TN", title: "Tennessee" },
      { value: "TX", title: "Texas" },
      { value: "US", title: "United States" },
      { value: "UT", title: "Utah" },
      { value: "VA", title: "Virginia" },
      { value: "VI", title: "Virgin Islands" },
      { value: "VT", title: "Vermont" },
      { value: "WA", title: "Washington" },
      { value: "WI", title: "Wisconsin" },
      { value: "WV", title: "West Virginia" },
      { value: "WY", title: "Wyoming" }
    ]
  };