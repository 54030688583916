import { combineReducers } from "redux";

import siteReducer from "./site_reducer";
import accountReducer from "./account_reducer";
import adblockReducer from "./adblock_reducer";
import messageReducer from "./message_reducer";
import messageEditorReducer from "./message_editor_reducer";
import scenarioReducer from "./scenario_reducer";
import partitionsetReducer from "./partitionset_reducer";
import campaignReducer from "./campaign_reducer";
import altpayReducer from "./altpay_reducer";
import whitelistReducer from "./whitelist_reducer";
import dashboardReducer from "./dashboard_reducer";
import customReportsReducer from "./custom_reports_reducer";
import vendorListReducer from "./vendor_list_reducer";
import privacyManagerReducer from "./privacy_manager_reducer.js";
import consentReportReducer from "./consent_report_reducer";
import consentPerformanceReducer from "./consent_performance_reducer";
import changeLogsReducer from "./change_log_reducer";
import consentPerformanceReportReducer from "./consent_performance_v1_reducer";
import dataRequestReducer from "./data_request_ccpa_reducer";
import dnsReducer from "./dns_reducer";
import ccpaDnsaPerformanceReducer from "./ccpa_dnsa_reporting_reducer";
import dsarReducer from "./dsar_reducer";
import consentCompensationReducer from "./consent_compensation_reducer";
import standardReducer from "./standard_reducer";
import domainSetReducer from "./domain_set_reducer";
import domainSoreReducer from "./domain_score_reducer";
import scenarioV1Reducer from "./sceanrio_v1_reducer";
import gdprV2ReportReducer from "./gdpr_performance_v2_reducer";
import gdprTcfv2V2ReportReducer from "./gdpr_tcfv2_performance_v2_reducer";
import goldenSetReducer from "./golden_set_reducer";
import publisherCmpIdsReducer from "./publisher_cmp_ids_reducer";
import integrationsReducer from "./integrations_reducer";
import diagnoseDashboardState from "./diagnose_dashboard_reducer";
import diagnoseVendorTraceState from "./diagnose_vendor_trace_reducer";
import scanAccuracyState from "./accuracy_reducer";
import privacyLensAccountsState from './privacy_lens_accounts_reducer';
import usPrivacyReducer from "./us_privacy_reducer";
import diagnoseScanSetup from "./diagnose_scan_setup.js";
import preferencesReducer from "./preferences_reducer.js";
import appDashboardState from "./app_dashboard_reducer.js"
import transactionReceiptReducer from "./transaction_receipt_reducer.js";

const reducers = combineReducers({
  accountState: accountReducer,
  adblockState: adblockReducer,
  siteState: siteReducer,
  messageState: messageReducer,
  messageEditorState: messageEditorReducer,
  scenarioState: scenarioReducer,
  partitionsetState: partitionsetReducer,
  campaignState: campaignReducer,
  altpayState: altpayReducer,
  whitelistState: whitelistReducer,
  dashboardState: dashboardReducer,
  customReportsState: customReportsReducer,
  vendorListState: vendorListReducer,
  privacyManagerState: privacyManagerReducer,
  consentReportState: consentReportReducer,
  consentPerformanceState: consentPerformanceReducer,
  changeLogsState: changeLogsReducer,
  consentPerformanceReportState: consentPerformanceReportReducer,
  dnsState: dnsReducer,
  dataRequestReducer,
  ccpaDnsaPerformanceState: ccpaDnsaPerformanceReducer,
  dsarState: dsarReducer,
  consentCompensationListState: consentCompensationReducer,
  standardState: standardReducer,
  domainSetState: domainSetReducer,
  domainScoreState: domainSoreReducer,
  scenariosState: scenarioV1Reducer,
  gdprV2ReportState: gdprV2ReportReducer,
  gdprTcfv2V2ReportState: gdprTcfv2V2ReportReducer,
  goldenSetState: goldenSetReducer,
  publisherCmpIdsState: publisherCmpIdsReducer,
  diagnoseDashboardState: diagnoseDashboardState,
  appDashboardState: appDashboardState,
  diagnoseVendorTraceState: diagnoseVendorTraceState,
  integrations: integrationsReducer,
  scanAccuracyState: scanAccuracyState,
  privacyLensAccountsState: privacyLensAccountsState,
  usPrivacyReducerState: usPrivacyReducer,
  diagnoseScanSetupState: diagnoseScanSetup,
  preferencesState: preferencesReducer,
  transactionReceiptState: transactionReceiptReducer
});

export default reducers;
