import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { unregisterAllWorkers } from './registerServiceWorker.js';
import store from './store';
import { ConfigProvider, List } from 'antd';
import { antTheme } from './ant-theme.js';

unregisterAllWorkers();

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider
      theme={antTheme}
    >
        <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("avocado")
);