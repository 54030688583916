import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Input, Select, Space, Button, message, Tooltip, theme, Flex, Typography } from 'antd';
import { CopyOutlined, FileSyncOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  getAllAPIKeys,
  createAPIKey,
  deleteAPIKey
} from '../../actions/account_actions';
import DismissErrorModal from '../common/DismissErrorModal';
const { useToken } = theme;
const { Title, Text } = Typography

const { Option } = Select;

const GenerateApiKeys = (props) => {
  const [searchText, setSearchText] = useState('');
  const [scheduleReportingAPIkeyList, setScheduleReportingAPIkeyList] = useState([])
  const [selectedOption, setSelectedOption] = useState('Scheduled Reporting');
  const [showGenerateAPIkeyModal, setShowGenerateAPIkeyModal] = useState(false)
  const [showExpireAPIKeyModal, setShowExpireAPIKeyModal] = useState(false);
  const [selectedchildKey, setSelectedchildKey] = useState(null)
  const [selectedAccountRecord, setSelectedAccountRecord] = useState(null)
  const [extraContent, setExtraContent] = useState("")
  const [tableLoading, setTableLoading] = useState(false);
  const { token } = useToken();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success('API Key copied to clipboard!');
  };

  useEffect(() => {
    props.getAllAPIKeys().then((resp) => {
      if (resp) {
        setScheduleReportingAPIkeyList(resp)
      }
    })
  }, [])

  const checkConditionForGenerateKey = (record) => {
    let activeOlderKeys = record.olderKeys.filter(key => !key.isDeleted)?.length
    if (record?.olderKeys.length === 0) {
      setExtraContent("")
    } else if (!activeOlderKeys || activeOlderKeys === 1) {
      setExtraContent(`Please expire any older API keys that are no longer required.`)
    } else if (!activeOlderKeys || activeOlderKeys > 1) {
      setExtraContent(`Please note that generating a new key requires manually expiring the old one once the client confirms they have started using the new key.`)
    } else {
      setExtraContent("")
    }
    setSelectedAccountRecord(record)
    setShowGenerateAPIkeyModal(true)
  }

  const generateAPIkey = async () => {
    setShowGenerateAPIkeyModal(false)
    setTableLoading(true)
    const payload = {
      "expirationDate": moment(selectedAccountRecord?.expirationDate).format("YYYY-MM-DD"),
      "accountId": selectedAccountRecord?.accountId,
      "generatedBy": selectedAccountRecord?.accountName
    }
    const resp = await props.createAPIKey(payload);
    if (resp) {
      // Update state by appending the new API key record.
      setScheduleReportingAPIkeyList(prevList => [...prevList, resp]);
      message.success('API Key generated successfully');
    } else {
      message.error('Failed to generate API Key');
    }
    setTableLoading(false)

  }

  // Group API key data by accountId and sort each group by full timestamp.
  // The most recent key becomes the main row and older keys are stored in "olderKeys".
  const groupedData = Object.entries(
    scheduleReportingAPIkeyList?.reduce((acc, key) => {
      if (!acc[key.accountId]) {
        acc[key.accountId] = [];
      }
      acc[key.accountId].push(key);
      return acc;
    }, {})
  ).map(([accountId, keys]) => {
    keys.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
    return { ...keys[0], olderKeys: keys.slice(1) };
  });

  // Combine accountList with API key data:
  // If an account has matching API key data, merge it; otherwise, create a blank row.
  const combinedData = props?.accountOwners?.toJS()?.map(account => {
    const apiKeyData = groupedData.find(item => Number(item.accountId) === Number(account.accountId));
    if (apiKeyData) {
      return { ...apiKeyData, accountName: account.accountName };
    } else {
      return {
        accountId: account.accountId,
        accountName: account.accountName,
        apiKey: '',
        dateCreated: '',
        generatedBy: '',
        olderKeys: [],
        features: account?.accountFeatures
      };
    }
  });

  const sortedCombinedData = combinedData.sort((a, b) => {
    if (a.apiKey && !b.apiKey) return -1;
    if (!a.apiKey && b.apiKey) return 1;
    return 0;
  });

  const infoContent = "You can generate a new API key and view the existing one if it was previously created.For security reasons, update the API key annually by regenerating it."
  // Define explicit column widths so that the expandable content aligns with the table headers.
  const columns = [
    {
      title: 'Account',
      dataIndex: 'accountName',
      key: 'accountName',
      width: 240,
      sorter: (a, b) => a.accountName.localeCompare(b.accountName),
      render: (text, record) =>
        text ? `${text}` : record.accountId,
    },
    {
      title: () => (<>External API Key<Tooltip title={infoContent}> <InfoCircleOutlined className='info-icon' /></Tooltip></>),
      dataIndex: 'apiKey',
      key: 'apiKey',
      width: 265,
      render: (text, record) => {
        const createdDate = new Date(record?.createdDate);
        const now = new Date();
        const monthsDiff = (now.getFullYear() - createdDate.getFullYear()) * 12 + (now.getMonth() - createdDate.getMonth());
        return (
          <Space>
            <Text ellipsis className='api-key-display'>
              {text}
            </Text>
            {text && <Button icon={<CopyOutlined />} onClick={() => handleCopy(text)} type="link" />}
            {monthsDiff >= 11 && (
              <Tooltip title="For security reasons, please generate a new API key within the next month, as your current key is 11 months old.">
                <ExclamationCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            )}
          </Space>
        )
      }
    },
    {
      title: 'Generated On',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      sortOrder: 'descend',
      width: 186,
      sorter: (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated),
      render: (date) => date ? moment(date).format('MMM D, YYYY HH:mm') : '',
    },
    {
      title: 'Generated By',
      dataIndex: 'generatedBy',
      key: 'generatedBy',
      width: 200,
    },
    {
      title: 'Action',
      key: 'action',
      width: 121,
      render: (_, record) => {
        let activeOlderKeys = record.olderKeys.filter(key => !key.isDeleted)?.length
        let haveFeatureAccess = record?.features?.includes("Reporting CCPA") || record?.features?.includes("Reporting TCFV2")
        let tooltipContent = (activeOlderKeys >= 2) ? "You can only have up to 3 active keys at any time. Expire the oldest one to perform this action." : "Generate"
        return (
          <Space size="middle">
            <Tooltip title={tooltipContent} >
              <Button disabled={activeOlderKeys >= 2} icon={<FileSyncOutlined />} type="link" onClick={() => checkConditionForGenerateKey(record)} />
            </Tooltip>
          </Space>
        )
      },
    },
  ];

  const expireAPIKey = async () => {
    setShowExpireAPIKeyModal(false)
    setTableLoading(true)
    const resp = await props.deleteAPIKey({ "_id": selectedchildKey?._id });
    if (resp) {
      setScheduleReportingAPIkeyList(prevList =>
        prevList.map(item =>
          item._id === selectedchildKey._id ? { ...item, isDeleted: resp?.isDeleted } : item
        )
      );
      message.success('API Key expired successfully');
    } else {
      message.error('Failed to expire API Key');
    }
    setTableLoading(false)
  }

  const renderApiKey = (apiKey, isDeleted) =>
    isDeleted ? <del style={{ color: "#8F8F8F" }}>{apiKey}</del> : apiKey;

  const cancelText = "Cancel"
  const generateModalContent = <Space direction='vertical' gap={token.margin}>{extraContent && (<div>{extraContent}</div>)}<div>Are you sure you want to proceed with generating a new key?</div></Space>
  const expireAPIKeyContent = <Space direction='vertical' gap={token.margin}><div>The API key will be fully deactivated once you expire it</div><div> Are you sure you want to expire this API key?</div></Space>

  // Use the same fixed widths for the expandable rows' CSS grid
  const gridTemplate = '349px 393px 213px 227px 100px';


  return (
    <>
      <div>
        <div style={{ marginTop: token.margin, marginBottom: token.margin, width: "100%" }}>
          <Flex justify='space-between' align="center">
            <Flex vertical gap={token.marginXXS}>
              <Text>Generate API key for</Text>
              <Select value={selectedOption} onChange={(value) => setSelectedOption(value)} style={{ width: 334, height: 32 }}>
                <Option value="Scheduled Reporting">External reporting API</Option>
              </Select>
            </Flex>
            <Input
              placeholder="Search"
              value={searchText}
              type='search'
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 360, height: 32 }}
            />
          </Flex>
        </div>
        <Table
          className='api-key-table'
          columns={columns}
          loading={props.pending || tableLoading}
          dataSource={sortedCombinedData.filter(item =>
            item.accountName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.accountId.toString().includes(searchText)
          )}
          expandable={{
            expandedRowRender: (record) => {
              const deletedKeys = record?.olderKeys?.filter((key) => key.isDeleted);
              const nonDeletedKeys = record?.olderKeys?.filter((key) => !key.isDeleted);

              // Get the latest 3 deleted keys and latest 2 non-deleted keys
              const visibleDeletedKeys = deletedKeys?.slice(0, 3);
              const visibleNonDeletedKeys = nonDeletedKeys?.slice(0, 2);

             // Combine them for display
              const displayedKeys = [...visibleNonDeletedKeys, ...visibleDeletedKeys];
              return (
                <div>
                  {displayedKeys?.map(child => (
                    <div
                      key={child._id}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: gridTemplate,
                        padding: "16px 0px",
                        alignItems: "center",
                        color: child?.isDeleted ? "#8F8F8F" : "#272727"
                      }}
                    >
                      <div></div>
                      <div>
                        <Space>
                          <Text ellipsis className='api-key-display'>
                            {renderApiKey(child.apiKey, child.isDeleted)}
                          </Text>
                          {child.apiKey && (
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() => handleCopy(child.apiKey)}
                              type="link"
                            />
                          )}
                        </Space>
                      </div>
                      <div>{child.dateCreated ? moment(child?.dateCreated).format('MMM D, YYYY HH:mm') : ''}</div>
                      <div>{child.generatedBy || '-'}</div>
                      {!child?.isDeleted && (<div>
                        <Space size="middle">
                          <Button onClick={() => { setShowExpireAPIKeyModal(true); setSelectedchildKey(child) }} icon={<DeleteOutlined />} type="link" />
                        </Space>
                      </div>)}
                    </div>
                  ))}
                </div>
              )
            },
            rowExpandable: (record) => record.olderKeys && record.olderKeys.length > 0,
          }}
          rowKey="accountId"
          pagination={{ position: ['bottomCenter'], pageSize: "10" }}
          scroll={{ x: 1024 }}
        />
      </div>
      {showGenerateAPIkeyModal && (<DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title="Generate new API Key"
        error={generateModalContent}
        isModalVisible={showGenerateAPIkeyModal}
        handleCancel={() => setShowGenerateAPIkeyModal(false)}
        renderOk={true}
        okText={"Yes"}
        cancelText={cancelText}
        handleOk={generateAPIkey}
        primaryAction="submit"
      />)}
      {showExpireAPIKeyModal && (<DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title="Expire the API Key"
        error={expireAPIKeyContent}
        isModalVisible={showExpireAPIKeyModal}
        handleCancel={() => setShowExpireAPIKeyModal(false)}
        renderOk={true}
        okText={"Yes"}
        cancelText={cancelText}
        handleOk={expireAPIKey}
        primaryAction="submit"
      />)}
    </>
  );
};

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    apiKeyList: store.accountState.getIn(['apikeyList', 'value']),
    pending: store.accountState.getIn(['apikeyList', 'pending']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    //sites: store.siteState.getIn(['sites', 'value']),
    //pending: store.siteState.getIn(['sites', 'pending']),
    error: store.siteState.getIn(['sites', 'error']),
  };
};

GenerateApiKeys.propTypes = {
  pending: PropTypes.bool,
  accountOwners: PropTypes.object,
  getAllAPIKeys: PropTypes.func.isRequired,
  createAPIKey: PropTypes.func.isRequired,
  deleteAPIKey: PropTypes.func.isRequired,
};


export default connect(mapStateToProps, { getAllAPIKeys, createAPIKey, deleteAPIKey })(GenerateApiKeys);
