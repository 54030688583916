import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Menu, Space } from 'antd';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

const TableFilterDropdown = ({
  filters,
  clearFilters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  showSearch,
  dropdownClassName,
  multiple,
  handleSelectedKeysOnSave,
  allowAllSelect = false,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const handleReset = () => {
    setSelectedKeys([]);
    handleSelectedKeysOnSave && handleSelectedKeysOnSave([]);
    setSearchValue('');
    clearFilters();
    confirm({ closeDropdown: true });
  };

  const handleSave = (val) => {
    if (selectedKeys.length === 0 || (selectedKeys.length === filters.length && !allowAllSelect)) {
      clearFilters();
      handleSelectedKeysOnSave && handleSelectedKeysOnSave([]);
    } else {
      handleSelectedKeysOnSave && handleSelectedKeysOnSave(selectedKeys);
    }
    confirm({ closeDropdown: true });
    setSearchValue('');
  };

  const onSelect = (item) => {
    if (multiple) {
      const exists = selectedKeys?.includes(item);
      setSelectedKeys(exists ? [...selectedKeys].filter((o) => o !== item) : [...selectedKeys, item]);
    } else {
      setSelectedKeys([item]);
      handleSelectedKeysOnSave && handleSelectedKeysOnSave([item]);
      confirm({ closeDropdown: true });
    }
  };

  const filterOptions = useMemo(() => {
    return searchValue
      ? filters.filter((f) => {
          return f.text.toLowerCase().includes(searchValue.toLowerCase());
        })
      : filters;
  }, [searchValue]);

  const className = classNames('spsg-filter-dropdown', dropdownClassName);

  return (
    <>
      <div className={className}>
        {showSearch && (
          <div className="list-search">
            <Input placeholder="Search" onChange={handleSearch} value={searchValue} prefix={<SearchOutlined />} />
          </div>
        )}
        <Menu selectedKeys={selectedKeys}>
          {filterOptions.map((o) => (
            <Menu.Item
              key={o.value}
              className={selectedKeys.includes(o.value) ? 'selactive' : ''}
              onClick={() => onSelect(o.value)}
            >
              <Space>
                {o.icon ? o.icon : null}
                {o.text}
              </Space>
              {multiple && <Checkbox checked={selectedKeys.includes(o.value)} />}
            </Menu.Item>
          ))}
        </Menu>
        <div className="filter-button-group">
          <Button style={{ marginRight: '10px' }} onClick={handleReset}>
            Reset
          </Button>
          {multiple && (
            <Button onClick={handleSave} type="primary">
              Save
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default TableFilterDropdown;
