export default class TrackingMetricRule {
  constructor(type, strokeColor, borderColor) {
    this.type = type;
    this.strokeColor = strokeColor;
    this.borderColor = borderColor;
  }

  isSupport(filters, node) {
    if (
      filters.highlight_filter === 'is_tracking_detected' &&
      Array.isArray(filters.tracking_highlight)
    ) {
      for (const value of filters.tracking_highlight) {
        if (value === this.type && node?.data?.attributes?.[this.type]) {
          return true;
        }
      }
    }
    return false;
  }

  getStrokeColor(filters, node) {
    const flag = this.calculateFlag(filters, node);
    return flag > 1 ? '#F5222D' : this.strokeColor;
  }

  getBorderColor(filters, node) {
    const flag = this.calculateFlag(filters, node);
    return flag > 1 ? '#F5222D' : this.borderColor;
  }

  calculateFlag(filters, node) {
    let flag = 0;
    if (
      filters.highlight_filter === 'is_tracking_detected' &&
      Array.isArray(filters.tracking_highlight)
    ) {
      for (const value of filters.tracking_highlight) {
        if (node?.data?.attributes?.[value]) {
          flag++;
        }
      }
    }
    return flag;
  }
}
