import * as types from '../actions/action_types';

import { List, Map } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setError, setPending, setValue } from './helpers.js';

export const initialState = Map({
    accountScans: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    accountBulkScans: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    accountBulkScanDetails: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    bulkScanFilters: new StoreRecord(new List([])),
    bulkScanDetailsFilters: new StoreRecord(new List([])),
    consentCategories: new StoreRecord(new List([])),
    proxyTools: new StoreRecord(new List([])),
    websitePages: new StoreRecord(new List([])),
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_PENDING:
            return setPending(state, ['accountScans']);

        case types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_ERROR:
            return setError(state, ['accountScans'], action.errorMessage).setIn(['accountScans', 'value'], []);

        case types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_LOADED:
            return setValue(state, ['accountScans'], action.data);

        case types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_PENDING:
            return setPending(state, ['consentCategories']);

        case types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_ERROR:
            return setError(state, ['consentCategories'], action.errorMessage);

        case types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_LOADED:
            return setValue(state, ['consentCategories'], action.data);

        case types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_PENDING:
            return setPending(state, ['proxyTools']);

        case types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_ERROR:
            return setError(state, ['proxyTools'], action.errorMessage);

        case types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_LOADED:
            return setValue(state, ['proxyTools'], action.data);

        case types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_PENDING:
            return setPending(state, ['websitePages']);

        case types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_ERROR:
            return setError(state, ['websitePages'], action.errorMessage);

        case types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_LOADED:
            return setValue(state, ['websitePages'], action.data);

        case types.DIAGNOSE_GET_BULK_SCAN_LIST_PENDING:
            return setPending(state, ['accountBulkScans']);

        case types.DIAGNOSE_GET_BULK_SCAN_LIST_ERROR:
            return setError(state, ['accountBulkScans'], action.errorMessage).setIn(['accountBulkScans', 'value'], []);

        case types.DIAGNOSE_GET_BULK_SCAN_LIST_LOADED:
            return setValue(state, ['accountBulkScans'], action.data);

        case types.DIAGNOSE_GET_BULK_SCAN_FILTERS_PENDING:
            return setPending(state, ["bulkScanFilters"]);

        case types.DIAGNOSE_GET_BULK_SCAN_FILTERS_ERROR:
            return setError(state, ["bulkScanFilters"], action.errorMessage).setIn(['bulkScanFilters', 'value'], []);
        
        case types.DIAGNOSE_GET_BULK_SCAN_FILTERS_LOADED:
            return setValue(state, ["bulkScanFilters"], action.data);
        
        case types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_PENDING:
            return setPending(state, ["accountBulkScanDetails"]);

        case types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_ERROR:
            return setError(state, ["accountBulkScanDetails"], action.errorMessage).setIn(['accountBulkScanDetails', 'value'], []);
        
        case types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_LOADED:
            return setValue(state, ["accountBulkScanDetails"], action.data);
        
        case types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_FILTERS_PENDING:
            return setPending(state, ["bulkScanDetailsFilters"]);

        case types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_FILTERS_ERROR:
            return setError(state, ["bulkScanDetailsFilters"], action.errorMessage).setIn(['bulkScanDetailsFilters', 'value'], []);
        
        case types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_FILTERS_LOADED:{
            const bulkScanDetailsFilters= Object.fromEntries(
                Object.entries(action.data).map(([k, v]) => [k, Array.isArray(v) ? v.filter(Boolean) : v])
              );
            return setValue(state, ["bulkScanDetailsFilters"], bulkScanDetailsFilters);

        }
        
        
        default:
            return state;
    }
}