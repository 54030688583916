/* eslint-disable */
export default class NonDisclosedMetricRule {
  isSupport(filters, node) {
    if (filters.highlight_filter === 'is_disclosed') {
      for (const value of filters.disclosure_highlight) {
        if (value === 'non-disclosed' && !node.data.attributes.is_disclosed) {
          return true;
        }
      }
    }
    return false;
  }
  getStrokeColor() {
    return '#F5222D';
  }
  getBorderColor() {
    return '#F5222D';
  }
}
