import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Table, Space, Tooltip, Typography,theme, Row,Col,Button } from 'antd';
import React from 'react';
import { browserHistory } from 'react-router';

const { Title,Text } = Typography

const ComplianceMetricsApp = ({ metricsList, resetSummary, type = "diagnose" }) => {
  
  const { token } = theme.useToken();
 
  const criteriaTooltips = {
    'Vendors triggered prior to consent':
      'Vendors that we have observed on the selected app properties before any user action',
    'Vendors after opt out':
      'Vendors that we have observed on your app properties after a user has opted out of the sharing and/or selling of personal information',
    'All vendors found':'Number of distinct vendors we have observed on your app properties',
    'Vendors after reject all':'Vendors that we have observed on your app properties firing after a user has rejected all consent choices'
  };
  const renderCriteriaTooltip = (criteria) => {
    if (Object.keys(criteriaTooltips).includes(criteria)) {
      return criteriaTooltips[criteria];
    }
  };


  const columns = [
    {
      title: 'Metrics',
      dataIndex: 'criteria',
      key: 'criteria',
      width: 150,
      sorter: (a, b) => a.criteria.localeCompare(b.criteria),
      render: (text, record) => (
        <div>
          <div>
            <Text>{text}</Text>
            <Tooltip overlayClassName="diagnose--tooltip" title={renderCriteriaTooltip(record.criteria)}>
              {' '}
              {/* <InfoCircleFilled style={{ color: '#808080' }} /> */}
              <Button type="link" icon={ <InfoCircleOutlined />}></Button>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>Vendor Count</span>,
      dataIndex: 'count',
      key: 'count',
      width: 250,
      sorter: (a, b) => a.count - b.count,
      render: (value, record) => (
        <Text style={{textAlign: 'center'}}>{value}</Text>
      ),
    },
  ]

  const scrollToTop = () => {
    document.querySelector('.spt-content').scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadDetailsData = (metricName) => {
    resetSummary([]);
    const route = {
      'Vendors triggered prior to consent': 'app_metrics/vendors_triggered_prior_to_consent',
      'Vendors after opt out': 'app_metrics/vendors_after_opt_out',
      'All vendors found': 'app_metrics/all_vendors_found',
      'Vendors after reject all': 'app_metrics/vendors_after_reject_all',
    }?.[metricName]
    browserHistory.push(route);
    scrollToTop();
  };

   // Function to handle right-click and open a route in a new tab
   const handleRightClick = (event, metricName) => {
    event.preventDefault(); // Prevent the default context menu

    const routePath = {
      'Vendors triggered prior to consent': '/app_metrics/vendors_triggered_prior_to_consent',
      'Vendors after opt out': '/app_metrics/vendors_after_opt_out',
      'All vendors found': '/app_metrics/all_vendors_found',
      'Vendors after reject all': '/app_metrics/vendors_after_reject_all',
    }?.[metricName]

    // Construct the full URL using the current location origin
    const newTabUrl = window.location.origin + routePath;
    window.open(newTabUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={'compliance_metrics_container_app'}>
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Space direction="vertical" size={token.marginXXS}>
          <Title level={4}>Compliance Metrics</Title>
          <Text>The cards below show the metrics we record for apps and the vendor count for each metric. Click on a card to get detailed insights and recommendations.</Text>
        </Space>
        </Col>
        <Col span={24}>
          <Table
            pagination={false}
            dataSource={metricsList}
            columns={columns}
            rowKey={(record) => record.criteria}
            showSorterTooltip={false}
            tableLayout='fixed'
            onRow={(record, rowIndex) => {
            return {
            onContextMenu: (event) => handleRightClick(event, record.criteria),
            onClick: () => {
              loadDetailsData(record.criteria);
             },
             };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ComplianceMetricsApp;
