import React, { Component } from 'react';
import { Infotip, Button, Select } from "../../../../../../../../styleguide";
import { PRIVACY_MANAGER_URL, NOTICE_URL } from '../../../../../../../../constants.js';
import { SelectedPrivacyManager } from '../../../../../../contexts';
import { mergePmChoiceOptions } from '../../../../../../../utils';

const { Option } = Select;

export default class LinkPM extends Component {
  static contextType = SelectedPrivacyManager
  state = {
    show: false,
    choice: null
  }
  promiseInfo = {}
  show = async (selectedButtonText) => {
    return new Promise((resolve, reject) => {
      this.promiseInfo = { resolve, reject };
      let newState = { 
        show: true, 
      };

      // find choice option whose button text matches what is selected
      let choice = selectedButtonText && this.props.choiceOptions.find((co) => ( co.data.button_text == selectedButtonText ))

      if(choice) {
        // merge so that we are showing the proper settings
        newState.choice = mergePmChoiceOptions(this.context.selectedPrivacyManager, choice);
      } else {
        // if we don't have one use a default
        this.changeValue('?pmTab=purposes')
      }

      this.setState(newState);
    });
  }
  hide = () => this.setState({ show: false })
  changeValue = (value) => {
    // we don't create the whole choice option here, only the pieces that won't come from the top level setting
    const choice = { type: 12, data: {
      privacy_manager_iframe_url: value,
      button_text: JSON.stringify(Date.now())
    }}

    if(this.context.selectedPrivacyManager.master_privacy_manager_iframe_url) {
      choice.master_privacy_manager_iframe_url = value;
    }
    
    this.setState({ 
      choice: value 
        ? mergePmChoiceOptions(this.context.selectedPrivacyManager, choice) 
        : null 
    });
  }
  getSelectedValue = () => {
    let selectedValue = '';
    const { choice } = this.state;

    if(choice) {
      const { data, master_privacy_manager_iframe_url } = choice;
      selectedValue = master_privacy_manager_iframe_url || (data && data.privacy_manager_iframe_url);
    }

    return selectedValue;
  }
  selectValue = () => {
    const { resolve, reject } = this.promiseInfo;
    this.hide();
    if (this.state.choice) {
      this.props.updateActions(this.state.choice);
      resolve(this.state.choice);
    } else {
      reject();
    }
  }
  changeDefaultTab = (tab, url) => {
    const newUrl = url.replace('&default_tab=vendors', '');
    if (tab === 'vendors') {
      this.changeValue(`${newUrl}&default_tab=vendors`);
    } else {
      this.changeValue(newUrl);
    }
  }

  changeDefaultTab = (tab, url) => {
    const newUrl = url
      .replace('&pmTab=purposes-li', '')
      .replace('&pmTab=purposes', '')
      .replace('&pmTab=features', '')
      .replace('&pmTab=li', '')
      .replace('&pmTab=vendors-li', '')
      .replace('&pmTab=vendors', '');

    tab ? this.changeValue(`${newUrl}&pmTab=${tab}`) : this.changeValue(newUrl);
  }

  generateUrl = (option) => `${NOTICE_URL}/privacy-manager/index.html?message_id=${option.id}`

  getTabValue = (urlValue) => {
    if (urlValue.includes('&pmTab=vendors')) {
      return urlValue.includes('&pmTab=vendors-li') ? 'vendors-li' : 'vendors';
    } else if (urlValue.includes('&pmTab=features')) {
      return 'features';
    } else {
      return urlValue.includes('&pmTab=purposes-li') ? 'purposes-li' : 'purposes';
    };
  }

  render() {
    const { show } = this.state;
    const { reject } = this.promiseInfo;
    const selectedValue = this.getSelectedValue() || '';

    const urlValueNoTabs = selectedValue
      .replace('&pmTab=purposes-li', '')
      .replace('&pmTab=purposes', '')
      .replace('&pmTab=features', '')
      .replace('&pmTab=li', '')
      .replace('&pmTab=vendors-li', '')
      .replace('&pmTab=vendors', '');
    const menu = (
      <div style={{ padding: '8px 0', width: '200px' }}>
        <h5>Privacy Manager</h5>
        <Select
          style={{ width: '100%' }}
          value={urlValueNoTabs}
          onChange={this.changeValue}
          disabled={true}     // this is disabled because we rely on the top level setting to change it
        >
          <Option value={null}><i>None</i></Option>
          {this.props.options.map((opt) => (
            <Option key={opt.id} value={this.generateUrl(opt)}>{opt.description}</Option>
          ))}
        </Select>
        { selectedValue && this.props.messageSubcategory !== "usnat_notice" && (
          <React.Fragment>
            <h4>Default PM Tab</h4>
            <div className="option-inputs">
              <Select
                style={{ width: '100%' }}
                value={this.getTabValue(selectedValue) === 'li' ? 'purposes' : this.getTabValue(selectedValue)}
                onChange={val => this.changeDefaultTab(val, selectedValue)}
              >
                <Option value="purposes">
                  Purposes (Consent)
                </Option>
                <Option value="purposes-li">
                  Purposes (Legitimate Interest)
                </Option>
                <Option value="vendors">
                  Vendors (Consent)
                </Option>
                <Option value="vendors-li">
                  Vendors (Legitimate Interest)
                </Option>
                <Option value="features">
                  Features
                </Option>
              </Select>
            </div>
          </React.Fragment>
        )}
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Button onClick={() => { this.hide(); reject(); }}>Cancel</Button>
          <Button style={{ marginLeft: '5px' }} type="primary" onClick={this.selectValue}>OK</Button>
        </div>
      </div>
    );
    return (
      <button id="ql-linkPrivacyManager" style={{ width: 'auto' }} className="ql-linkPrivacyManager">
        <Infotip open={show} placement="bottom" title="Select Privacy Manager:" content={menu} trigger="click">
          <span>PM</span>
        </Infotip>
      </button>
    );
  }
}