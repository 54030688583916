/* eslint-disable no-restricted-syntax */
import PrevalenceRule from './prevalence_rule';
import VendorNameRule from './vendor_name_rule';
import TrackingMetricRule from './tracking_tech_rule/metric_name_rule';

import DisclosedMetricRule from './disclosure_rule/disclosed_metric_rule';
import NonDisclosedMetricRule from './disclosure_rule/non_disclosed_metric_rule';
import DisclosedTriggeringNonDisclosedMetricRule from './disclosure_rule/disclosed_triggering_non_disclosed_rule';
import VendorHighlightRule from './highlight_rule';
import ConsentRule from './consent_rule';
import DefaultRule from './default_rule';


export default class RuleService {
  constructor() {
    /*  prioirity depends on the order of array
        provide only neccessary styling for rule */
    this.rules = [
      new DefaultRule(),

      new PrevalenceRule(),
      new DisclosedTriggeringNonDisclosedMetricRule(),
      new NonDisclosedMetricRule(),
      new DisclosedMetricRule(),
      new VendorHighlightRule(),
      new VendorNameRule(),
      new ConsentRule(),
      new TrackingMetricRule('is_cookie_present', '#03A233', '#03A233'),
      new TrackingMetricRule('is_cookie_persistent', '#E77408', '#E77408'),
      new TrackingMetricRule('is_local_storage', '#08979C', '#08979C'),
      new TrackingMetricRule('is_fingerprinting', '#1677FF', '#1677FF'),
      new TrackingMetricRule('is_pixel','#C41D7F','#C41D7F'),
      new TrackingMetricRule('is_chatbot', '#8D6E63', '#8D6E63'),
      new TrackingMetricRule('is_cookieless', '#613400', '#613400'),
      new TrackingMetricRule('is_session_replay','#004C3F', '#004C3F')
    ]
  }
  getStrokeColor(filters, node) {
    const result = [];
    for (const rule of this.rules) {
      if (rule.isSupport(filters, node) && rule?.getStrokeColor) {
        result.push(rule.getStrokeColor(filters, node, result));
      }
    }
    return result.pop();
  }
  getTextColor(filters, node) {
    const result = [];
    for (const rule of this.rules) {
      if (rule.isSupport(filters, node) && rule.getTextColor) {
        result.push(rule.getTextColor(filters, node, result));
      }
    }
    return result.pop();
  }
  getNodeBackgroundColor(filters, node) {
    const result = [];
    for (const rule of this.rules) {
      if (rule.isSupport(filters, node) && rule?.getNodeBackgroundColor) {
        result.push(rule.getNodeBackgroundColor(filters, node, result));
      }
    }
    return result.pop();
  }
  getBorderColor(filters, node) {
    const result = [];
    for (const rule of this.rules) {
      if (rule.isSupport(filters, node) && rule?.getBorderColor) {
        result.push(rule.getBorderColor(filters, node, result));
      }
    }
    return result.pop();
  }
  getOpacity(filters, node) {
    const result = [];
    for (const rule of this.rules) {
      if (rule.isSupport(filters, node) && rule?.getOpacity) {
        result.push(rule.getOpacity(filters, node, result));
      }
    }
    return result.pop();
  }
}
