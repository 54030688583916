import { LeftOutlined } from '@ant-design/icons';
import { Typography, Divider, Spin, Flex, theme } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import moment from 'moment';
const { Text } = Typography

import {
  setRegionsList,
  setWebsitePropertiesListFilters,
} from '../../../actions/diagnose_dashboard_actions';
import { getAppSummary,setMetricsList,setAvailableMetrics } from "../../../actions/app_dashboard_actions";
import { getPropertiesData, getRegionsData } from '../../../api/diagnose_dashboard/diagnose_dashboard';
import { accountFeaturesToAvailableAppDiagnoseMetrics } from '../../../helpers/diagnose/metrics';
import DashboardFilters from '../../diagnose/dashboard/dashboard_filters/DashboardFilters';
import RecommendationsWindow from '../../diagnose/dashboard/recommendations-window/RecommendationsWindow';
import { getFromLocalStorage } from '../../../helpers/diagnose/utils';
import useSearchParams from '../../diagnose/dashboard/shared/useSearchParams';

const DiagnoseDashboardLayout = ({ children, location, summaryPending, getAppSummary, setRegionsList, selectedMetrics, selectedFilters, setWebsitePropertiesListFilters, setAvailableMetrics, setMetricsList, regionsList, propertiesList }) => {
  const accountState = useSelector((state) => state.accountState);
  const currentUser = accountState?.getIn(['userDetails', 'value']);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoadingFiltersData, setLoadingFiltersData] = useState(false);
  const searchParams = useSearchParams();
  const metrics = useMemo(() => {
    const path = location.pathname.split('app_metrics')[1];

    if (!path) {
      return selectedMetrics;
    }
    return {
      '/vendors_triggered_prior_to_consent': ['Vendors triggered prior to consent'],
      '/vendors_after_opt_out': ['Vendors after opt out'],
      '/vendors_after_reject_all': ['Vendors after reject all'],
      '/all_vendors_found': ['All vendors found'],
    }[path];
  }, [location.pathname, selectedMetrics]);

  // Apply Local Storage Data for same AccountId
  const storedFilters = useMemo(() => {
    const defaultDatesToApply = [moment().subtract(1, 'week'), moment()];
    const filters = {
      properties: [],
      dates: defaultDatesToApply,
      regions: [],
      benchmarks: [],
    };
    if (localStorage.getItem('spAccountId') !== null && localStorage.getItem('spAccountId') === currentUser.accountId.toString()) {
      filters.properties = getFromLocalStorage('diagnoseSelectedPropertiesApp', []);
      filters.dates = getFromLocalStorage('diagnoseSelectedDatesApp', defaultDatesToApply);

      const storedRegions = getFromLocalStorage('diagnoseSelectedRegionsApp', []);
      const isRegionWithZone = storedRegions.some((storedRegion) => storedRegion.includes(':'));
      filters.regions = isRegionWithZone ? storedRegions : [];

      if (!isRegionWithZone) {
        localStorage.setItem('diagnoseSelectedRegionsApp', JSON.stringify([]));
        filters.regions = [];
        filters.benchmarks = [];
      }
    } else {
      localStorage.setItem('diagnoseSelectedPropertiesApp', JSON.stringify([]));
      localStorage.setItem('diagnoseSelectedRegionsApp', JSON.stringify([]));
      localStorage.setItem('diagnoseSelectedDatesApp', JSON.stringify(defaultDatesToApply));
      localStorage.setItem('spAccountId', currentUser.accountId);
    }

    return filters;
  }, []);

  const getMetricsAccordingToRegions = useCallback((regionsCheck) => {
    let result = [...metrics];
    if (regionsCheck.every((region) => !region.includes('US:')) && metrics.length > 1) {
      const selectedWithOptOut = metrics.includes('Vendors after opt out');
      if (selectedWithOptOut) {
        const noOptOut = metrics.filter((metric) => metric !== 'Vendors after opt out');
        result = noOptOut;
      }
    }
    return result;
  }, [metrics]);

  const listenToScroll = () => {
    const heightToShowFrom = 110;
    const winScroll = document.querySelector('.spt-content').scrollTop;
    if (winScroll > heightToShowFrom) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.querySelector('.spt-content').addEventListener('scroll', listenToScroll);
    return () => document.querySelector('.spt-content').removeEventListener('scroll', listenToScroll);
  }, []);

  const getFiltersData = async () => {
    setLoadingFiltersData(true);
    const [propertiesData, regionsData] = await Promise.all([getPropertiesData(), getRegionsData()]);
    propertiesData && setWebsitePropertiesListFilters(propertiesData);
    regionsData && setRegionsList(regionsData);
    setLoadingFiltersData(false);
  };

  useEffect(() => {
    const availableMetrics = accountFeaturesToAvailableAppDiagnoseMetrics(currentUser.accountFeatures, currentUser.allFeatureAccess || currentUser.featureAccess);
    setAvailableMetrics(availableMetrics);
    setMetricsList(availableMetrics);
    if (currentUser.accountFeatures.includes('app_dashboard') && ((currentUser.featureAccess && currentUser.featureAccess.includes('App Dashboard')) || currentUser.allFeatureAccess)) {
      getFiltersData();
    } else {
      browserHistory.push('/');
    }
  }, [currentUser]);

  const getSummary = (filters, filteredMetrics = null) => {
    getAppSummary({
      ...filters,
      metrics: filteredMetrics,
      accountId: currentUser?.accountId
    });
  };

  const handleApply = (filters) => {
    const metricsFiltered = getMetricsAccordingToRegions(filters.regions);
    getSummary(filters, metricsFiltered);
  };

  useEffect(() => {
    if (!Object.keys(searchParams).length
      && !summaryPending
      && metrics?.length
      && Object.keys(selectedFilters).length
      && selectedFilters?.websites?.length
      && selectedFilters?.regions?.length
      && selectedFilters?.end
      && selectedFilters?.start) {
      const metricsFiltered = getMetricsAccordingToRegions(selectedFilters.regions);
      getSummary(selectedFilters, metricsFiltered);
    }
  }, [metrics]);

  const isMetricNameHighlighted = metrics.length === 1 && location?.query?.metric?.toLowerCase() === metrics[0].toLowerCase();
  const pathName = '/app_metrics'
  const wrapperClass = location.pathname !== pathName ? 'diagnose-dashboard-content-wrapper' : null
  const { token } = theme.useToken()

  return (
    <div className={'diagnose_container'}>
      <div className={'diagnose_dashboard'}>
        <div className={'diagnose_dashboard-back-and-page-name-block'}>
          {location.pathname !== pathName &&
            <Link className={classNames('link_back', { small: isVisible })} to={pathName}>
              <LeftOutlined /> Back to Summary
                        </Link>
          }
          {isVisible && <div>
            <Typography.Title level={2} className={`diagnose_dashboard-page-name-block ${isMetricNameHighlighted && 'diagnose-notification-highlight highlight'}`}>
              {location.pathname === pathName ? 'Diagnose Dashboard' : metrics?.[0]}
            </Typography.Title>
          </div>}
        </div>
        <div>
          <Typography.Title level={2} className={`headers ${isMetricNameHighlighted && 'diagnose-notification-highlight highlight'}`}>
            {location.pathname === pathName ? 'Diagnose Dashboard' : metrics?.[0]}
          </Typography.Title>
        </div>
        <Spin spinning={isLoadingFiltersData && !!Object.keys(searchParams).length} size="large">
          {!!Object.keys(regionsList)?.length && !!propertiesList?.length || !Object.keys(searchParams).length ? (
            <>
              <Text style={{ margin: token.marginXXS}}>Use the filters below to narrow down the selection:</Text>
              <div className="diagnose_dashboard-main-filters-block">
                <DashboardFilters
                  regionsList={regionsList}
                  propertiesList={propertiesList}
                  selectedFilters={selectedFilters}
                  storedFilters={storedFilters}
                  searchParams={searchParams}
                  onApply={handleApply}
                  isApp={true}
                  isDiagnoseDashboard={false}
                />
              </div>
            </>
          ) : null}
          <div className={wrapperClass}>
            {children && React.cloneElement(children, {
              location,
            })}
          </div>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryDiagnose: store.appDashboardState.getIn(['summaryAppDiagnose', 'value']),
    summaryPending: store.appDashboardState.get('summaryAppPending'),
    selectedFilters: store.appDashboardState.getIn(['selectedAppFilters', 'value']),
    selectedMetrics: store.appDashboardState.getIn(['selectedMetrics', 'value']),
    regionsList: store.diagnoseDashboardState.getIn(['regionsList', 'value']),
    propertiesList: store.diagnoseDashboardState.getIn(['websitePropertiesList', 'value']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAppSummary,
    setRegionsList,
    setWebsitePropertiesListFilters,
    setAvailableMetrics,
    setMetricsList,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatePropsEqual: (next, prev) => _.isEqual(next, prev),
})(DiagnoseDashboardLayout);