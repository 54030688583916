/* eslint-disable camelcase */
/* eslint-disable no-alert */
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, { retries: 2, retryDelay: 500 });

import { checkStatus, buildUrlWithVersion, DIAGNOSE_DASHBOARD_BASEURL, exportCSVResponse, getVersion } from '../helper.js';
import { fetchRetryWrapper } from './helpers.js';

const prepareTableFiltersToParams = (tableFiltersData) => Object.entries(tableFiltersData || {}).reduce((acc, [filterKey, filterValue]) => {
    if (!filterValue || filterKey === 'vendor_status') {
        return acc;
    }
    if (filterKey === 'information_storage' && filterValue) {
        // eslint-disable-next-line no-unused-expressions
        filterValue?.forEach((v) => {
            acc[v] = true;
        });
        return acc;
    }
    if (filterKey === 'flagged' && filterValue) {
        // eslint-disable-next-line no-unused-expressions
        filterValue?.forEach((v) => {
            if (v === 'cookie_secure') {
                acc[v] = false;
            } else if (v === 'cookie_size') {
                acc[v] = '100';
            } else {
                acc[v] = true;
            }
        });
        return acc;
    }
    if (filterKey === 'prevalence' && filterValue) {
        acc.lowest_risk = filterValue?.[0];
        acc.highest_risk = filterValue?.[1];
        return acc;
    }
    if (filterKey === 'prevalence_benchmark' && filterValue) {
        acc.lowest_bench_risk = filterValue?.[0];
        acc.highest_bench_risk = filterValue?.[1];
        return acc;
    }
    if (filterKey === 'cookie_prevalence' && filterValue) {
        acc.cookie_prevalence_lowest_risk = filterValue?.[0];
        acc.cookie_prevalence_highest_risk = filterValue?.[1];
        return acc;
    }
    if (filterKey === 'server_location' && filterValue) {
        acc[filterKey] = filterValue?.map((f) => (f === null ? 'null' : f));
        return acc;
    }
    if (filterValue) {
        acc[filterKey] = filterValue;
        return acc;
    }
    return acc;
}, {});


export function getPropertiesData() {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/websites/`,
            {},
            'summary',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => resp)
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Properties List' data: status ${err.response.status}`) : err);
        });
}

export function getBenchmarksData({ regions } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/benchmarks/`,
            { regions },
            'summary',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => resp)
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Benchmarks List' data: status ${err.response.status}`) : err);
        });
}

export function getLatestVersionDate({ websites } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/latest-version-date/`,
            { website_ids: websites },
            'summary',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            // if (err.response) {
            //     err.response.status !== 400 && alert(`Error on get 'Latest Version Date': status ${err.response.status}`);
            // } else {
            //     alert(err);
            // }
        });
}

export function getSummaryData({
    metrics,
    websites,
    benchmark_websites,
    start,
    end,
    regions,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/summary/`,
            {},
            'summary',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                metrics,
                websites,
                start,
                end,
                regions: regions.join(';') || '',
                ...(benchmark_websites && benchmark_websites.length ? { benchmark_websites } : {})
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => resp)
        // .then((resp) => summaryData)
        // .then((resp) => summaryData)
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Summary' data: status ${err.response.status}`) : err);
        });
}

export function getRegionsData() {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/regions/`,
            {},
            'summary',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => resp)
        .catch((err) => {
            console.log(err);
            alert(err);
        });
}

export function getVendorDetailsData({ vendorId, dateFrom, dateTo, metric, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/vendors`,
            {
                vendor_id: vendorId,
                date_from: dateFrom,
                date_to: dateTo,
                metric,
                region: region || '',
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err);
        });
}

export function getVendorWebsiteDetailsData({ vendorId, dateFrom, dateTo, metric, region, website } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/vendors/website`,
            {
                vendor_id: vendorId,
                date_from: dateFrom,
                date_to: dateTo,
                metric,
                website,
                region: region || '',
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err);
        });
}

export function getVendorsNotOnVendorListData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/not-on-vendors-list/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Non disclosed vendors observed' data: status ${err.response.status}` : err);
        });
}

export function getVendorsNotOnVendorListFilters({ dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/not-on-vendors-list/filters/`,
            {},
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                region: region.split(';'),
                properties: properties.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getVendorsPriorToConsentFilters({ dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/prior-to-consent/filters/`,
            {},
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
            }),
        },
    )
         .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getVendorsPriorToConsentListData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/prior-to-consent/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Vendors triggered prior to consent' data: status ${err.response.status}` : err);
        });
}

export function getDisclosedVendorsData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    currentPrevalenceTab,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/disclosed-vendors/${currentPrevalenceTab}/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Disclosed Vendors' data: status ${err.response.status}` : err);
        });
}

export function getDisclosedVendorsFilters({ currentPrevalenceTab, dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/disclosed-vendors/${currentPrevalenceTab}/filters/`,
            {},
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getPossibleFingerprintingData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId } = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/possible-fingerprinting/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Possible Fingerprinting' data: status: ${err.response.status}` : err);
        });
}

export function getPossibleFingerprintingFiltersData({ dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/possible-fingerprinting/filters/`,
            {},
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getDataLeavingEeaData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/data-leaving-eea/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Data Leaving the EEA' data: status ${err.response.status}` : err);
        });
}

export function getDataLeavingEeaFiltersData({ dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/data-leaving-eea/filters/`,
            {},
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getCookiesWithLongLifespansFiltersData({ dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/cookies-with-long-lifespans/filters/`,
            {},
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getCookiesWithLongLifespansData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/cookies-with-long-lifespans/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Cookies With Long Lifespans' data: status ${err.response.status}` : err);
        });
}

export function getAllCookiesFoundData({
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/total-distinct-cookies/`,
        {
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties.split(';'),
                region: region.split(';'),
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'All cookies found' data: status ${err.response.status}` : err);
        });
}

export function getCookieDetailsData({
    vendorId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    cookieNames,
    isCookieFlagged,
    partyCookie,
    cookieCategories,
    isCookiePersistent,
    isCookieSecure,
    cookieSameSite,
    cookieHost,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/total-distinct-cookies/vendor`,
            {
                vendor_id: vendorId,
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties || '',
                region: region || '',
                cookie_name: cookieNames || '',
                cookie_party_cookie: partyCookie || '',
                cookie_flagged: isCookieFlagged,
                cookie_category: cookieCategories || '',
                cookie_same_site: cookieSameSite || '',
                cookie_host: cookieHost || '',
                cookie_persistent: isCookiePersistent,
                cookie_secure: isCookieSecure,
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get 'Cookie details' data: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getAllCookiesFoundListFiltersData({ dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/total-distinct-cookies/filters`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getCookieDetailsListFiltersData({ vendorId, dateFrom, dateTo, benchmarks, properties, region } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/total-distinct-cookies/vendor/filters`,
            {
                vendor_id: vendorId,
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

// export requests
export function exportCsvVendorNotOnVendorListData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/not-on-vendors-list/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`non_disclosed_vendors_observed(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvVendorsPriorToConsentData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/prior-to-consent/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`vendors_triggered_prior_to_consent(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvDisclosedVendorsData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
    currentPrevalenceTab,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/disclosed-vendors/${currentPrevalenceTab}/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`disclosed_vendors(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvPossibleFingerprintingData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/possible-fingerprinting/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`possible_fingerprinting(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvDataLeavingEeaData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/data-leaving-eea/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`data_leaving_eaa(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvCookiesWithLongLifespansData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/cookies-with-long-lifespans/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`cookies-with-long-lifespans(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvVendorDetailsData({ vendorId, metric, dateFrom, dateTo, region, website } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/vendors/export`,
            {
                vendor_id: vendorId,
                metric,
                date_from: dateFrom,
                date_to: dateTo,
                region: region || '',
                website: website || '',
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`vendor_details_(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The data selected is too large to download to csv. ' +
                        'Try selecting a smaller date range or reach out to your customer success manager.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on export data: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
        });
}

export function exportCsvAllCookiesFoundData({
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/total-distinct-cookies/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`total-distinct-cookies(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function exportCsvCookieDetailsData({
    vendorId,
    dateFrom,
    dateTo,
    benchmarks,
    properties,
    region,
    vendorName,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/total-distinct-cookies/vendor/export`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                properties: properties || '',
                region: region || '',
                vendor_id: vendorId,
                ...(benchmarks ? { benchmarks: benchmarks || '' } : {}),
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((response) => response.blob())
        .then(exportCSVResponse(`${vendorName}_${dateFrom}-${dateTo}`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Export Error: status ${err.response.status}`) : err);
        });
}

export function getVendorWebsiteActivitiesListData({ vendorId, website, metric } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-management/activities/`,
            {
                vendor_id: vendorId,
                website_id: website,
                metric,
            },
            'vendor-management',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err);
        });
}

export function addVendorManagementActivities(updatedActivities) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-management/activities/add/`,
            {},
            'vendor-management',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify(updatedActivities),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp.detail) {
                throw resp.detail;
            }
            return resp;
        })
        .catch((err) => {
            console.log(err);
            alert(err);
        });
}

export function getVendorManagementStatuses(data = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-management/statuses/`,
            {},
            'vendor-management',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify(data),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err);
        });
}


export function getDiagnoseDetailsReport({ dateFrom, dateTo, properties, region, email, metric, accountId } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/report/`,
            {
                async_: true,
            },
            'details',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                region,
                properties,
                metric,
                email,
                async_: true,
            }),
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            if (err.response) {
                if (err.response.status === 502 || err.response.status === 504) {
                    alert(`${'The query has timed out. Please ' +
                        'revisit the criteria you have chosen and choose ' +
                        'a smaller date range and/or less websites in your selection.' +
                        ' (error status '}${err.response.status})`);
                } else {
                    alert(`Error on get filters: status ${err.response.status}`);
                }
            } else {
                alert(err);
            }
            throw err;
        });
}

export function getTrackingTechDetectedListData({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...prepareTableFiltersToParams(tableFiltersData),
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: false,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Tracking tech detected' data: status ${err.response.status}` : err);
        });
}

export function getTrackingTechDetectedFiltersData({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/filters/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                ...prepareTableFiltersToParams(tableFiltersData),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Filters - Tracking tech detected' data: status ${err.response.status}` : err);
        });
}

export function getVendorDetailsForCookielessTech({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/cookieless/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...prepareTableFiltersToParams(tableFiltersData),
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: false,
            }),
        })
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => resp)
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Cookieless - Tech Detected' data: status ${err.response.status}` : err);
        });
}

export function getVendorDetailsForPixelsTech({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/pixels/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...prepareTableFiltersToParams(tableFiltersData),
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: false,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Pixels - Tech Detected' data: status ${err.response.status}` : err);
        });
}


export function getVendorDetailsForPixelsParams({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
    pixelId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/pixels/params/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...prepareTableFiltersToParams(tableFiltersData),
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                pixel_id: pixelId,
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Pixels Params - Tech Detected' data: status ${err.response.status}` : err);
        });
}

export function getVendorDetailsForChatbotsTech({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/chatbots/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...prepareTableFiltersToParams(tableFiltersData),
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Chatbots - Tech Detected' data: status ${err.response.status}` : err);
        });
}

export function getVendorDetailsForSessionReplaysTech({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    tableFiltersData,
    vendorId,
} = {}) {
    return fetchRetryWrapper(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/session-replays/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'post',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...prepareTableFiltersToParams(tableFiltersData),
                accountId,
                _version: getVersion('details'),
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                ...(vendorId?.length ? { vendor_id: vendorId } : {}),
                async_: true,
            }),
        })
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? `Error on get 'Session Replays - Tech Detected' data: status ${err.response.status}` : err);
        });
}

export function exportPixelsHarFile({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    vendorId,
    techId,
    scanUrl,
} = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/pixels/har-file/`,
            {
                accId: accountId,
                async_: true,
            },
            'details'
        ),
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId,
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                vendor_id: vendorId || null,
                pixels_id: techId || null,
                async_: true,
                scan_url: scanUrl,
            }),
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
            return response.blob();
        })
        .then(exportCSVResponse(`${scanUrl}_${dateFrom}-${dateTo}`))
        .catch((err) => {
            console.error(err);
            alert(
                err.response
                    ? `Error: ${err.response.status} - ${err.response.statusText}`
                    : `Error: ${err.message}`
            );
        });
}


export function exportCookielessHarFile({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    vendorId,
    techId,
    scanUrl,
} = {}) {
    debugger;
    return fetch(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/cookieless/har-file/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId,
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                vendor_id: vendorId || null,
                cookieless_id: techId || null,
                async_: true,
                scan_url: scanUrl,
            }),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
            return response.blob();
        })
        .then(exportCSVResponse(`${scanUrl}_${dateFrom}-${dateTo}`))
        .catch((err) => {
            console.error(err);
            alert(
                err.response
                    ? `Error: ${err.response.status} - ${err.response.statusText}`
                    : `Error: ${err.message}`
            );
        });
}

export function exportChatbotHarFile({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    vendorId,
    techId,
    scanUrl,
} = {}) {
    debugger;
    return fetch(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/chatbots/har-file/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId,
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                vendor_id: vendorId || null,
                chatbots_id: techId || null,
                async_: true,
                scan_url: scanUrl,
            }),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
            return response.blob();
        })
        .then(exportCSVResponse(`${scanUrl}_${dateFrom}-${dateTo}`))
        .catch((err) => {
            console.error(err);
            alert(
                err.response
                    ? `Error: ${err.response.status} - ${err.response.statusText}`
                    : `Error: ${err.message}`
            );
        });
}

export function exportSessionReplayHarFile({
    accountId,
    dateFrom,
    dateTo,
    page,
    limit,
    orderBy,
    benchmarks,
    properties,
    region,
    vendorId,
    techId,
    scanUrl,
} = {}) {
    return fetch(buildUrlWithVersion(
        `${DIAGNOSE_DASHBOARD_BASEURL}/details/tracking-tech/session-replays/har-file/`,
        {
            accId: accountId,
            async_: true,
        },
        'details',
    ),
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId,
                date_from: dateFrom,
                date_to: dateTo,
                page,
                limit,
                order_by: orderBy,
                properties: properties ? properties.split(';') : [],
                region: region ? region.split(';') : [],
                ...(benchmarks ? { benchmarks: benchmarks.split(';') } : {}),
                vendor_id: vendorId || null,
                session_replay_id: techId || null,
                async_: true,
                scan_url: scanUrl,
            }),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Server error: ${response.status}`);
            }
            return response.blob();
        })
        .then(exportCSVResponse(`${scanUrl}_${dateFrom}-${dateTo}`))
        .catch((err) => {
            console.error(err);
            alert(
                err.response
                    ? `Error: ${err.response.status} - ${err.response.statusText}`
                    : `Error: ${err.message}`
            );
        });
}
