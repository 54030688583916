import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Table, message } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from "@ant-design/icons"
import { getMessageDetailsById } from '../../../api/dialogue/messages'
import MessagePreviewModal from '../../../components/dialogue/scenariosNew/MessagePreviewModal';

const TransactionTable = forwardRef((props) => {

    const { data, selectedRowKeys, tableContainerRef, sites } = props;
    const [showMessagePreview, setShowMessagePreview] = useState(false)
    const [viewMsg, setViewMsg] = useState({})
    const [transactionData, setTransactionData] = useState([]);

    const getColumnFilterValues = (key) => {
        const columnFilterValues = [
            ...new Set(transactionData.map((item) => item[key])),
        ].map(v => ({
            text: v,
            value: v,
        }));

        return columnFilterValues
    }

    const getUniqueTransactions = (data) => {
        return data.reduce((acc, current) => {
            if (!acc.some(item => item.transactionId === current.transactionId)) {
                acc.push(current);
            }
            return acc;
        }, []);
    };

    useEffect(() => {
        // Create the data with unique keys whenever data changes
        const updatedData = getUniqueTransactions(data).map((item, index) => ({
            ...item,
            key: item.transactionId, // Ensure transactionId is unique
        }));

        if (JSON.stringify(updatedData) !== JSON.stringify(transactionData)) {
            setTransactionData(updatedData);
        }
    }, [data, transactionData]);

    const viewMessage = async (id) => {
        getMessageDetailsById(
           id
          ).then((resp) => setViewMsg(resp), setShowMessagePreview(true));
    };

    const getSiteName = (siteId) => {
        const siteName = sites.find(s => s.id === siteId)?.domain;
        return siteName || ""
    }

    const convertToCSV = (data) => {
        const keys = Object.keys(data).filter(item => item !== "detailsJSON" && item !== 'key' && item !== 'vendorListId');
        const headers_ucpm = [
            "Transaction ID",
            "Transaction Type",
            "Transaction System",
            "Message ID",
            "Property",
            "Timestamp",
            "Category Name",
            "Category Type",
            "Category SubType",
            "IAB ID",
            "System ID",
            "Legal Document Name",
            "Go Live Date",
        ]
        const headers_vendors = [
            "Transaction ID",
            "Transaction Type",
            "Message ID",
            "Property",
            "Timestamp",
            "Vendor Name",
            "Vendor Type",
            "Google ID",
        ]
        const rows = [];
        // UCP Transactions: Filter and include only changed records

        rows.push(['Categories']);
        rows.push(headers_ucpm);
        const propertyName = getSiteName(data.property)

        if (data.type === 'UCPM') {
            const changedCategories = data.detailsJSON?.categories
            changedCategories.forEach(category => {
                rows.push([
                    data.transactionId,
                    data.type,
                    data.system,
                    data.messageId,
                    propertyName,
                    data?.timestamp? new Date(data?.timestamp).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): "N/A",
                    category?.name || "N/A",
                    category?.type || "N/A",
                    category?.subType || "N/A",
                    category?.iabId || "N/A",
                    category?.systemId || "N/A",
                    category?.legalDoc?.name || "N/A",
                    category?.legalDoc?.goLiveDate? new Date(category?.legalDoc?.goLiveDate).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): "N/A",
                ]);
            });
        }

        // GDPR and USP Transactions: Group into Categories and Vendors
        if (data.type === 'GDPR' || data.type === 'USP') {
            // Add Categories
            const categories = data.detailsJSON?.categories || [];
            const propertyName = getSiteName(data.property)
            categories.forEach(category => {
                rows.push([
                    data?.transactionId,
                    data.type,
                    data?.system || "",
                    data.messageId,
                    propertyName,
                    data.timestamp? new Date(data.timestamp).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): "N/A",
                    category?.name || "N/A",
                    category?.type || "N/A",
                    category?.subType || "N/A",
                    category?.iabId || "N/A",
                    category?.systemId || "N/A",
                    category?.legalDoc?.name || "N/A",
                    category?.legalDoc?.goLiveDate? new Date(category?.legalDoc?.goLiveDate).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): "N/A",
                ]);
            })

            rows.push([]);
            // Add Vendors
            const vendors = data.detailsJSON?.vendors || [];
            if (vendors.length > 0) {
                rows.push(['Vendors']);
                rows.push(headers_vendors);
                vendors.forEach(vendor => {
                    rows.push([
                        data?.transactionId,
                        data.type,
                        data.messageId,
                        propertyName,
                        data.timestamp ? new Date(data.timestamp).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): "N/A",
                        vendor.name || "N/A",
                        vendor.type || "N/A",
                        vendor.googleId || "N/A",
                    ]);
                });
            }
        }

        // Convert rows to CSV format
        const csvContent = rows.map(row => row.map(value => `"${value}"`).join(',')).join('\n');
        return csvContent;

    };

    const getTransactionCSVData = (transaction) => {
        const csvContent = transaction ? convertToCSV(transaction) : "";
        return csvContent
    }

    const handleDownload = (e, transaction) => {
        const transactionData = getTransactionCSVData(transaction)
        console.log(transactionData)
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), transactionData], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${moment(new Date()).format("DD-MM-YYYY")}-${transaction?.type}-${transaction.property}-Transaction_receipt.csv`;
        a.click();
        URL.revokeObjectURL(url);
        message.success("Your document has been successfully downloaded. Please check my downloads.")
        e.stopPropagation()
    };

    // Table columns
    const columns = [
        {
            title: "Transaction ID",
            dataIndex: "transactionId",
            key: "transactionId",
            fixed: "left",
            width: 150,
        },
        {
            title: "Transaction Type",
            dataIndex: "type",
            key: "type",
            filters: getColumnFilterValues("type"),
            onFilter: (value, record) => record.type.indexOf(value) === 0,
        },
        {
            title: "Transaction System",
            dataIndex: "system",
            key: "system",
            width: 200,
            filters: getColumnFilterValues("system"),
            onFilter: (value, record) => record.system.indexOf(value) === 0,
        },
        {
            title: "Property",
            dataIndex: "property",
            key: "property",
            filters: getColumnFilterValues("property"),
            onFilter: (value, record) => String(record.property).indexOf(value) === 0,
            render: (value, record) => {
                const siteName = sites.find(s => s.id === value)?.domain;
                return siteName || ""
            }
        },
        {
            title: "Timestamp",
            dataIndex: "timestamp",
            key: "timestamp",
            width: 230,
            sorter: (a, b) => {
               return new Date(a.timestamp) - new Date(b.timestamp);
            },
            render: (timestamp) => moment(timestamp).format("MMMM D, YYYY, h:mm A"),
            sortDirections: ["ascend", "descend"],
            defaultSortOrder: "descend",
        },
        {
            title: "Message Preview",
            dataIndex: "messageId",
            key: "messageId",
            align: "center",
            render: (messageId) => !messageId ? "N/A" : <Button type='link' onClick={() => viewMessage(messageId)}>View Message</Button>,
        },
        {
            title: "Download Details",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (_, record) => {
                return <Button type='link'><DownloadOutlined onClick={(e) => handleDownload(e, record)} /></Button>
            }
        },
    ];

    // Row selection configuration
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
            props.handleSelectedRowKeys(selectedKeys);
        },

        getCheckboxProps: (record) => ({
            name: parseInt(record.transactionId),
        }),
    };

    const togglePreviewModal = () => { setShowMessagePreview(!showMessagePreview) };

    const messagePreview = showMessagePreview &&  Object.keys(viewMsg).length > 0 &&
        <MessagePreviewModal showMessagePreview={showMessagePreview} viewMsg={viewMsg} togglePreviewModal={togglePreviewModal} />;

    return (
        <>
            <Table
                rowSelection={{
                    type: "checkbox", // Multiple selection
                    ...rowSelection,
                }}
                ref={tableContainerRef}
                columns={columns}
                dataSource={transactionData}
                scroll={{ x: 1000 }}
                className='transaction-table'
                pagination={{
                    position: ['bottomCenter'],
                    showTotal: (total) => <div>Total :{' '}{total} items</div>,
                    size: 'small',
                    defaultPageSize: 7,
                    showSizeChanger: true,
                    pageSizeOptions: ['7', '10', '15', '20'],
                }}
            />
            {messagePreview}
        </>
    );
});

export default TransactionTable;