import React, { useEffect, useMemo, useState } from 'react';
import { Spin, Divider, Empty, TreeSelect, Checkbox, Flex } from 'antd';
import { browserHistory } from 'react-router';
import VendorTraceChart from './VendorTraceChart';
import useSearchParams from '../../dashboard/shared/useSearchParams';
import DashboardSelectDropdown from '../../dashboard/shared/DashboardSelect';
import { tagRenderer } from '../vendor_trace_filters/VendorTraceFilters';
import { singleOptionRenderer } from '../vendor_trace_filters/SelectOptionRenderers';
import { useVendorTraceContext } from './ChartFiltersContext';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

export const disclosureOption = ['disclosed', 'disclosed-triggering-non-disclosed', 'non-disclosed'];
export const trackingTechOption = [
  'is_cookie_present',
  'is_cookie_persistent',
  'is_local_storage',
  'is_fingerprinting',
  'is_pixel',
  'is_chatbot',
  'is_cookieless',
  'is_session_replay',
];

const VendorTraceOverview = ({
  isDataLoading,
  consentTypes,
  defaultConsentType,
  enableDisclosureFilter,
  onChangeConsentType,
}) => {
  const [highlightFilter, setHighlightFilter] = useState([]);
  const [disclosedFilter, setDisclosedFilter] = useState(disclosureOption);
  const [traceTracking, setTraceTracking] = useState(trackingTechOption);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const {
    vendorsNames,
    vendorsData: vendorTraces,
    vendorName: selectedVendorName,
    isBeforeActionConsentCategory,
    setVendorName,
    resetFilters,
  } = useVendorTraceContext();

  const filters = useMemo(() => {
    return [
      {
        title: <>Tracking tech detected</>,
        value: 'is_tracking_detected',
        key: 'is_tracking_detected',
        children: [
          {
            value: 'is_chatbot',
            key: 'is_chatbot',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#8D6E63',
                    display: 'inline-block',
                  }}
                />
                <span>Chatbots</span>
              </Flex>
            ),
          },
          {
            value: 'is_cookie_present',
            key: 'is_cookie_present',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#03A233',
                    display: 'inline-block',
                  }}
                />
                <span>Cookies</span>
              </Flex>
            ),
          },
          {
            value: 'is_cookieless',
            key: 'is_cookieless',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#613400',
                    display: 'inline-block',
                  }}
                />
                <span>Cookieless IDs</span>
              </Flex>
            ),
          },
          {
            value: 'is_fingerprinting',
            key: 'is_fingerprinting',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#1677FF',
                    display: 'inline-block',
                  }}
                />
                <span>Fingerprinting</span>
              </Flex>
            ),
          },
          {
            value: 'is_local_storage',
            key: 'is_local_storage',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#08979C',
                    display: 'inline-block',
                  }}
                />
                <span>Local Storage</span>
              </Flex>
            ),
          },
          {
            value: 'is_cookie_persistent',
            key: 'is_cookie_persistent',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#E77408',
                    display: 'inline-block',
                  }}
                />
                <span>Persistent Cookies</span>
              </Flex>
            ),
          },
          {
            value: 'is_pixel',
            key: 'is_pixel',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#C41D7F',
                    display: 'inline-block',
                  }}
                />
                <span>Pixels</span>
              </Flex>
            ),
          },
          {
            value: 'is_session_replay',
            key: 'is_session_replay',
            title: (
              <Flex align="center" justify="flex-start" gap={8}>
                <span
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '5px',
                    backgroundColor: '#004C3F',
                    display: 'inline-block',
                  }}
                />
                <span>Session Replay Technologies</span>
              </Flex>
            ),
          },
        ],
      },
      ...(enableDisclosureFilter
        ? [
            {
              title: <>Vendor disclosure</>,
              value: 'is_disclosed',
              key: 'is_disclosed',
              children: [
                {
                  value: 'disclosed',
                  title: (
                    <Flex align="center" justify="flex-start" gap={8}>
                      <span
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: '5px',
                          backgroundColor: '#5B8C00',
                          display: 'inline-block',
                        }}
                      />
                      <span>Disclosed vendors</span>
                    </Flex>
                  ),
                },
                {
                  value: 'disclosed-triggering-non-disclosed',
                  key: 'disclosed-triggering-non-disclosed',
                  title: (
                    <Flex align="center" justify="flex-start" gap={8}>
                      <span
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: '5px',
                          backgroundColor: '#E77408',
                          display: 'inline-block',
                        }}
                      />
                      <span>Disclosed triggering non-disclosed</span>
                    </Flex>
                  ),
                },
                {
                  value: 'non-disclosed',
                  key: 'non-disclosed',
                  title: (
                    <Flex align="center" justify="flex-start" gap={8}>
                      <span
                        style={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#F5222D',
                          display: 'inline-block',
                          borderRadius: '5px',
                        }}
                      />
                      <span>Non-disclosed</span>
                    </Flex>
                  ),
                },
              ],
            },
          ]
        : []),
    ];
  }, [enableDisclosureFilter]);

  useEffect(() => {
    if (isDataLoading) {
      setSelectedKeys([]);
      setHighlightFilter([]);
      setDisclosedFilter([]);
      setTraceTracking([]);
    }
  }, [isDataLoading]);

  const selectedConsentType = useMemo(() => {
    return consentTypes.find(({ value }) => defaultConsentType.includes(value));
  }, [consentTypes, defaultConsentType]);

  const searchParams = useSearchParams();

  const handleChangeConsentType = (key) => {
    if (key && key !== defaultConsentType) {
      if (Object.keys(searchParams).length) {
        browserHistory.replace(location.pathname);
      }
      resetFilters();
      setHighlightFilter([]);
      onChangeConsentType(key);
    }
  };
  const onFilterChange = (value, label, extra) => {
    let checkedKeys = value;

    if (!extra.checked) {
      setDisclosedFilter(checkedKeys.filter((key) => disclosureOption?.includes(key)));
      setTraceTracking(checkedKeys.filter((key) => trackingTechOption?.includes(key)));
      setSelectedKeys(checkedKeys);

      if (checkedKeys.length === 0) {
        setHighlightFilter([]);
      }

      return;
    }

    const isFromDisclosure = extra.triggerValue === 'is_disclosed' || disclosureOption?.includes(extra.triggerValue);
    const isFromTrackingDetected =
      extra.triggerValue === 'is_tracking_detected' || trackingTechOption?.includes(extra.triggerValue);

    if (extra.checked) {
      if (isFromDisclosure) {
        checkedKeys = checkedKeys.filter((key) => !trackingTechOption?.includes(key));
        setDisclosedFilter(checkedKeys);
        setHighlightFilter('is_disclosed');
        setTraceTracking([]);
      } else if (isFromTrackingDetected) {
        checkedKeys = checkedKeys.filter((key) => !disclosureOption?.includes(key));
        setTraceTracking(checkedKeys);
        setDisclosedFilter([]);
        setHighlightFilter('is_tracking_detected');
      }
      setSelectedKeys(checkedKeys);
    } else {
      setSelectedKeys(value);
      setDisclosedFilter(value);
      setTraceTracking(value);
      if (checkedKeys.length === 0) {
        setHighlightFilter([]);
      }
    }
  };

  /* select particular vendor to filter and launch filtering */
  const onSelectVendorName = (
    vendorName,
    isNewName = true /* select dropdown returns object same as true in that case */,
  ) => {
    const newVendorName = vendorName && selectedVendorName !== vendorName ? vendorName : null;
    if (isNewName) {
      setVendorName(newVendorName, isBeforeActionConsentCategory);
    }
    if (Object.keys(searchParams).length) {
      browserHistory.replace(location.pathname);
    }
  };

  return (
    <div className={'vendor_trace_container'} id={'wrapper'}>
      {consentTypes?.length ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 20px',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                columnGap: '12px',
              }}
            >
              <span>Consent Status:</span>
              <DashboardSelectDropdown
                showSearch={false}
                showAll={false}
                allowClear={false}
                options={consentTypes}
                selected={defaultConsentType ? [defaultConsentType] : []}
                onSelect={handleChangeConsentType}
                placeholder={'Consent Type'}
                tagRender={tagRenderer}
                singleOptionRender={singleOptionRenderer}
              />
            </div>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                columnGap: '20px',
              }}
            >
              <DashboardSelectDropdown
                showAll={false}
                options={vendorsNames}
                selected={selectedVendorName || []}
                onSelect={onSelectVendorName}
                onClear={onSelectVendorName}
                placeholder={'Search Vendor'}
                tagRender={tagRenderer}
                singleOptionRender={singleOptionRenderer}
                loading={isDataLoading}
              />
              <TreeSelect
                switcherIcon={({ isLeaf, expanded }) =>
                  isLeaf ? null : expanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                }
                treeLine={true}
                style={{
                  width: 400,
                }}
                treeCheckable
                treeData={filters}
                placeholder={'Select a filter'}
                onChange={onFilterChange}
                value={selectedKeys}
                allowClear
                onClear={() => {
                  setHighlightFilter([]);
                  setSelectedKeys([]);
                }}
              />
            </div>
          </div>
          <Divider style={{ margin: '0', borderColor: '#d3d9dd' }} />
          <Spin spinning={isDataLoading} size="large" wrapperClassName={'vendor-trace-data-loading'}>
            <div style={{ padding: '20px' }}>
              {!isDataLoading && Object.keys(vendorTraces).length ? (
                <VendorTraceChart
                  consentTypeDescription={selectedConsentType?.htmlTitle}
                  highlightFilter={highlightFilter}
                  selectedVendorName={selectedVendorName}
                  disclosedFilter={disclosedFilter}
                  trackingTechFilter={traceTracking}
                />
              ) : null}
              {!isDataLoading && !Object.keys(vendorTraces).length ? (
                <Empty description={<span>No data for the selected consent status.</span>} />
              ) : null}
            </div>
          </Spin>
        </>
      ) : (
        <Empty description={<span>Select property to view data</span>} />
      )}
    </div>
  );
};

export default VendorTraceOverview;
