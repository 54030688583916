import { checkStatus, DIAGNOSE_SDK_BASEURL } from '../helper.js';

const getBaseUrl = () => {
    return `${DIAGNOSE_SDK_BASEURL}/api/`;
};

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;


export function getTransactionHistoryAjax(searchBy,authId) {
    const url = searchBy === 'client-id'?`/users/history?clientId=`: '/users/history?uuid=';
    //return Promise.resolve(sampleData.transactions)
    return fetch(permissionsSvsBase + url+authId,
        {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp?.json())
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 403) {
                    return ({ status: err.response.status, message:  "Access Denied"})
                } else {
                    alert(`Error retrieving transaction history: Status ${err.response.status}`);
                }
            } else {
                console.log(err);
                alert("An unexpected error occurred. Please try again later.");
            }
        });
}