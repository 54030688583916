import React, { Component } from 'react';
import { Slider, Select } from '../../../../../../../styleguide';
import ColorPickerInput from '../../../../../../common/ColorPickerInput.js.jsx';
import { filterElements } from '../../../../../../../util.js';

const { Option } = Select;

class FontRem extends Component {
  updateFont = (type, value) => {
    const font = JSON.parse(JSON.stringify(this.props.value));
    font[type] = type === 'fontSizeRem' ? value + 'rem' : value;
    font.fontSize = this.calculatePx(Number(font.fontSizeRem.slice(0, -3)));
    this.props.updateSetting(this.props.settingKey, font);
  };

  calculatePx = (rem) => {
    let baseFontSize;
  
    const screenWidth = window.innerWidth;
  
    if (screenWidth <= 960) {
      baseFontSize = 11;  // Amazon Fire Stick / Android TV
    } else if (screenWidth >= 961 && screenWidth <= 1919) {
      baseFontSize = 14;  // HD
    } else if (screenWidth >= 1920 && screenWidth <= 3839) {
      baseFontSize = 21;  // Full HD
    } else {
      baseFontSize = 42;  // 4K
    }
  
    const pxValue = rem * baseFontSize;
    return pxValue;
  }

  render() {
    const { value, messageSubcategory, isNativeButton } = this.props;
    const filter = filterElements(messageSubcategory);

    return (
      <div className="four-options">
        {this.props.settingsTitle && (
          <h4>{this.props.settingsTitle}</h4>
        )}
        <div className="option-inputs">
          <div>
            <Select value={value.fontFamily} onChange={val => this.updateFont('fontFamily', val)} style={{ width: '100%' }}>
              <Option value='arial, helvetica, sans-serif' style={{ fontFamily: 'arial, helvetica, sans-serif' }}>Arial</Option>
              <Option value='comic sans ms,cursive' style={{ fontFamily: 'comic sans ms,cursive' }}>Comic Sans</Option>
              <Option value='courier new,courier,monospace' style={{ fontFamily: 'courier new,courier,monospace' }}>Courier New</Option>
              <Option value='georgia, serif' style={{ fontFamily: 'georgia, serif' }}>Georgia</Option>
              <Option value='lucida sans unicode,lucida grande,sans-serif' style={{ fontFamily: 'lucida sans unicode,lucida grande,sans-serif' }}>Lucida Sans</Option>
              <Option value='tahoma,geneva,sans-serif' style={{ fontFamily: 'tahoma,geneva,sans-serif' }}>Tahoma</Option>
              <Option value='times new roman,times,serif' style={{ fontFamily: 'times new roman,times,serif' }}>Times New Roman</Option>
              <Option value='trebuchet ms,helvetica,sans-serif' style={{ fontFamily: 'trebuchet ms,helvetica,sans-serif' }}>Trebuchet</Option>
              <Option value='verdana,geneva,sans-serif' style={{ fontFamily: 'verdana,geneva,sans-serif' }}>Verdana</Option>
            </Select>
            <h5>Family</h5>
          </div>
          <div>
            <Select value={value.fontWeight} onChange={val => this.updateFont('fontWeight', val)} style={{ width: '100%' }}>
              <Option value="300" style={{ fontWeight: '300' }}>Light</Option>
              <Option value="400" style={{ fontWeight: '400' }}>Normal</Option>
              <Option value="500" style={{ fontWeight: '500' }}>Medium</Option>
              <Option value="600" style={{ fontWeight: '600' }}>Semi-bold</Option>
              <Option value="700" style={{ fontWeight: '700' }}>Bold</Option>
              <Option value="800" style={{ fontWeight: '800' }}>Heavy</Option>
            </Select>
            <h5>Weight</h5>
          </div>
        </div>
        <div className="option-inputs">
          <div style={{ paddingLeft: '3px' }}>
            <Slider min={0} max={10} step={0.01} tipFormatter={l => l + 'rem'}
              onChange={val => this.updateFont('fontSizeRem', val)}
              value={typeof value.fontSizeRem === 'string' ? parseFloat(value.fontSizeRem) : 0}
            />
            <h5>Size</h5>
          </div>
          {!isNativeButton && (
            <div>
              <ColorPickerInput
                value={value.color}
                onUpdate={(value) => this.updateFont('color', value)}
                editing={true}
              />
              <h5>Color</h5>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FontRem;