import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import {
    getTransactionHistory,
} from '../../../actions/transaction_actions';
import { Button, Flex, Typography, Input, Space, theme, Divider, Select, Empty, Spin, message } from "antd";
import { DownloadOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import TransactionTable from './TransactionTable';
import { getAllMessagesAjax } from '../../../api/dialogue/messages';
import { wrapInInfotip } from "../helper";
import { BACKEND_STATUS_TYPES } from '../../../constants';

const { useToken } = theme;

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;

const SCREENS = {
    HOME: "home",
    TABLE: "TABLE",
};

const headers_ucpm = [
    "Transaction ID",
    "Type",
    "System",
    "Message ID",
    "Property",
    "Timestamp",
    "Category Name",
    "Category Type",
    "Category SubType",
    "IAB ID",
    "System ID",
    "Legal Document Name",
    "Go Live Date",
]


const TransactionReceipt = ({
    getTransactionHistory,
    pending,
    sites,
}) => {

    const [searchBy, setSearchBy] = useState('client-id');
    const [inputValue, setInputValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [receiptList, setReceiptList] = useState([])
    const [currentScreen, setCurrentScreen] = useState(SCREENS.HOME)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const tableContainerRef = useRef(null);
    const [isLeftDisabled, setIsLeftDisabled] = useState(true);
    const [isRightDisabled, setIsRightDisabled] = useState(true);
    const [messages, setMessages] = useState([])
    const { token } = useToken();



    const getMessages = async (type = 'draft') => {
        try {
            const siteId = localStorage.getItem('spt_site_id');
            const messages = await getAllMessagesAjax(siteId, BACKEND_STATUS_TYPES[type])
            setMessages(messages?.toJS());
        } catch (err) {

        }
    }

    const getSiteName = (siteId) => {
        const siteName = sites.find(s => s.id === siteId)?.domain;
        return siteName || ""
    }

    useEffect(() => {
        getMessages()
    }, [])

    const onChange = (value) => {
        setSearchBy(value);
        setInputValue(null)
    };

    const onSearch = (value, _e, info) => {
        if (value?.trim() !== "") {
            getTransactionHistory(searchBy, value).then((res) => {
                if (res?.status !== 403) {
                    const transactions = res?.transactions.map((item, index) => ({
                        ...item,
                        key: item.transactionId || `row-${index}`,
                    }));
                    setReceiptList(transactions)
                } else {
                    message.error(res?.message)
                    setReceiptList([])
                }
            })
            setSearchValue(value)
        }
    }

    const handleSelectedRowKeys = (keys) => {
        setSelectedRowKeys(keys)
    }

    const convertToCSV = (transactions) => {
        const rows = [];

        let hasCategories = transactions.some((data) => data.detailsJSON?.categories?.length > 0);

        // Add Categories Section Header
        if (hasCategories) {
            rows.push(["Categories Section"]);
            rows.push(headers_ucpm);
        }

        // Process Categories for All Transactions
        transactions.forEach((data) => {
            const categories = data.detailsJSON?.categories || [];
            const propertyName = getSiteName(data.property)
            categories.forEach((category) => {
                // For UCPM, only include changed categories

                rows.push([
                    data.transactionId || "",
                    data.type,
                    data?.system || "",
                    data.messageId,
                    propertyName,
                    data.timestamp ? new Date(data.timestamp).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): '',
                    category.name || "N/A",
                    category?.type || "N/A",
                    category?.subType || "N/A",
                    category?.iabId || "N/A",
                    category?.systemId || "N/A",
                    category?.legalDoc?.name || "N/A",
                    category?.legalDoc?.goLiveDate ? new Date(category?.legalDoc?.goLiveDate).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }) :  "N/A",
                ]);
            });
        });

        let hasVendors = transactions.some((data) => data.detailsJSON?.vendors?.length > 0);

        if (hasVendors) {
            rows.push([]); // Add a blank line to separate sections
            rows.push(["Vendors Section"]);
            rows.push([
                "Transaction ID",
                "Transaction Type",
                "Message ID",
                "Property",
                "Timestamp",
                "Vendor Name",
                "Vendor Type",
                "Google ID",
            ]);
        }

        // Process Vendors for GDPR Transactions Only
        transactions.forEach((data) => {
            const vendors = data.detailsJSON?.vendors || [];
            const propertyName = getSiteName(data.property)
            if (data.type !== "UCPM") { // Vendors only for non-UCPM transactions
                vendors.forEach((vendor) => {
                    rows.push([
                        data.transactionId || "",
                        data.type,
                        data.messageId,
                        propertyName,
                        data?.timestamp? new Date(data?.timestamp).toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" }): "",
                        vendor.name || "N/A",
                        vendor.type || "N/A",
                        vendor.googleId || "N/A",
                    ]);
                });
            }
        });

        // Convert rows to CSV format
        const csvContent = rows
            .map((row) => row.map((value) => `"${value}"`).join(",")) // Add quotes around each value
            .join("\n");

        return csvContent;
    };

    const handleBulkDownload = (e) => {
        const selectedTransactions = receiptList.filter(t => selectedRowKeys.includes(t.transactionId || t.key))
        const csvContent = selectedTransactions.length > 0 ? convertToCSV(selectedTransactions) : "";
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvContent], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${moment(new Date()).format("DD-MM-YYYY")}-Transaction_Receipt.csv`;
        a.click();
        URL.revokeObjectURL(url);
        message.success("Your document has been successfully downloaded. Please check my downloads.")
        e.stopPropagation()
    }

    const disableBulkActions = selectedRowKeys?.length === 0 ? 'disabled' : null

    const scrollTable = (scrollBy) => {
        const tableBody = document.querySelector('.transaction-table .ant-table-content');
        if (tableBody) {
            tableBody.scrollTo({
                left: tableBody.scrollLeft + scrollBy,
                behavior: 'smooth' // Enables smooth scrolling
            });
        }
    };

    const updateButtonState = () => {
        const tableBody = document.querySelector('.transaction-table .ant-table-content');
        if (tableBody) {
            const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

            setIsLeftDisabled(tableBody.scrollLeft <= 0);
            setIsRightDisabled(tableBody.scrollLeft >= maxScrollLeft - 0);
        }
    };

    useEffect(() => {
        const tableBody = document.querySelector('.transaction-table .ant-table-content');
        if (tableBody) {
            tableBody.addEventListener('scroll', updateButtonState);
            // Initial check to set button states
            updateButtonState();
        }
        return () => {
            if (tableBody) {
                tableBody.removeEventListener('scroll', updateButtonState);
            }
        };
    }, [searchValue && receiptList?.length > 0]);

    useEffect(() => {
        // Add resize event listener
        if (receiptList?.length > 0) {
            window.addEventListener('resize', updateButtonState);

            // Cleanup event listener
            return () => {
                window.removeEventListener('resize', updateButtonState);
            };
        } else {
            setIsLeftDisabled(true);
            setIsRightDisabled(true);
        }
    }, [searchValue]);


    const selectBefore = (
        <Select value={searchBy} onChange={onChange} className="selection-select" defaultValue="sourcepoint-id">
            <Option value="sourcepoint-id">Sourcepoint UUID</Option>
            <Option value="client-id">Client User ID</Option>
        </Select>
    );

    return (
        <>
            {currentScreen === SCREENS.HOME && (<Flex vertical gap={token.marginXS} className="transaction-receipt-container">
                <Title level={2}>Transaction Receipts </Title>
                <Flex vertical gap={token.marginMD} className="transaction-container">
                    <Flex justify="space-between" align="baseline">
                        <Search
                            onChange={(e) => setInputValue(e.target.value)}
                            value={inputValue}
                            addonBefore={selectBefore}
                            placeholder={searchBy === "sourcepoint-id" ? "Anonymous identifiers" : "Email, phone, or  authenticated ID"}
                            allowClear
                            onClear={(value) => setSearchValue(value)}
                            onSearch={onSearch}
                            style={{
                                width: '45%',
                            }}
                            enterButton
                        />
                        <Flex align="center">
                            <Typography.Text disabled={selectedRowKeys?.length === 0} style={{ marginRight: `var(--ant-margin-xs)` }}>Bulk actions:</Typography.Text>
                            {wrapInInfotip(<Button type='link' icon={<DownloadOutlined />} disabled={selectedRowKeys?.length === 0} onClick={(e) => handleBulkDownload(e)} />, "Download Transactions")}
                            <Divider type="vertical" />
                            <Space size={token.margin}>
                                <Button disabled={isLeftDisabled} icon={<LeftOutlined />} onClick={() => scrollTable(-1000)}></Button>
                                <Button disabled={isRightDisabled} icon={<RightOutlined />} onClick={() => scrollTable(1000)}></Button>
                            </Space>
                        </Flex>
                    </Flex>
                    {!searchValue && (<Flex flex={1} vertical justify="center" align="center" className="search-container">
                        <Flex flex={1} justify="center" align="center" vertical gap={token.marginXXS}>
                            <Flex justify="center" align="center" vertical gap={token.marginSM}>
                                <Empty description={"Select and Enter ID to View the Transaction Details"} className="empty-image" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Flex>
                        </Flex>
                    </Flex>)}
                    {searchValue && receiptList?.length === 0 && (<Flex flex={1} vertical justify="center" align="center" className="search-container">
                        <Flex flex={1} justify="center" align="center" vertical gap={token.marginXXS}>
                            <Flex justify="center" align="center" vertical gap={token.marginSM}>
                                <Empty description={"No records found. Try with a different ID"} />
                            </Flex>
                        </Flex>
                    </Flex>)}
                    {pending ? <Spin /> : (receiptList?.length > 0 && searchValue) && (<TransactionTable
                        handleSelectedRowKeys={handleSelectedRowKeys}
                        selectedRowKeys={selectedRowKeys}
                        data={receiptList}
                        messages={messages}
                        tableContainerRef={tableContainerRef}
                        sites={sites}
                    />)}
                </Flex>
            </Flex >)
            }
        </>
    )

}

const mapStateToProps = function (store) {
    return {
        currentUser: store.accountState.getIn(['userDetails', 'value']),
        transactionHistory: store.transactionReceiptState.get(['transactionHistory', 'value']),
        pending: store.transactionReceiptState.getIn(['transactionHistory', 'pending']),
        sites: store.siteState.getIn(['sites', 'value']),
        //pending: store.siteState.getIn(['sites', 'pending']),
        error: store.transactionReceiptState.get(['transactionHistory', 'error']),
    };
};

export default connect(mapStateToProps, { getTransactionHistory })(TransactionReceipt);